import { instance } from "./axiosInstance";

export async function findManyTrainerSchedules(trainer_id, params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/schedule/" + trainer_id, config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneTrainerSchedule(trainer_id, id) {
    return await instance
        .get(`/schedule/${trainer_id}/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createTrainerSchedule(trainer_id, id, data) {
    return await instance
        .post("/schedule/" + trainer_id + "/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateTrainerSchedule(trainer_id, id, data) {
    return await instance
        .patch("/schedule/" + trainer_id + "/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteTrainerSchedule(trainer_id, id) {
    const config = {
        data: {
            id: id
        }
    };
    return await instance
        .delete("/schedule/" + trainer_id, config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}