import { userRegister } from "@/utils/Auth";
import { useToasterContext } from "@/utils/ToasterContext";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, FormHelperText, FormLabel, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { t } from "i18next";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import validate from "validate.js";

export default function SignIn() {

    const [formError, setFormError] = useState(false);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            email: data.get("email"),
            first_name: data.get("first_name"),
            last_name: data.get("last_name"),
            date_of_birth: dayjs(data.get("date_of_birth"), "DD/MM/YYYY").format("YYYY-MM-DD"),
            password: data.get("password"),
            password_confirmation: data.get("password_confirmation"),
        };

        const error = validate(dataForm, constraints);
        if (!error) {
            try {
                startProgress();
                setIsLoading(true);
                const response = await userRegister(dataForm);
                console.log("response", response);
                if (response && !response.error) {
                    showMessage("success", "Account registration successful");
                    navigate("/login");
                } else {
                    showMessage("error", "Your account has been taken");
                }
            } catch (error) {
                showMessage("error", error);
            } finally {
                completeProgress();
                setIsLoading(false);
            }
        } else {
            showMessage("error", "One or more input fields are not valid");
        }

        setFormError(error);
    };

    const constraints = {
        first_name: {
            presence: {
                allowEmpty: false,
                message: t("^Your first name cannot be blank")
            },
        },
        last_name: {
            presence: {
                allowEmpty: false,
                message: t("^Your last name cannot be blank")
            },
        },
        date_of_birth: {
            presence: {
                allowEmpty: false,
                message: t("^Your birthday cannot be blank")
            },
        },
        email: {
            presence: {
                allowEmpty: false,
                message: t("^Email cannot be blank")
            },
            email: {
                message: t("^Invalid email")
            }
        },
        password: {
            presence: {
                allowEmpty: false,
                message: t("^Password cannot be blank")
            },
            length: {
                minimum: 6,
                message: t("^Password must have at least 6 characters")
            }
        },
        password_confirmation: {
            presence: {
                allowEmpty: false,
                message: t("^Password cannot be blank")
            },
            length: {
                minimum: 6,
                message: t("^Password must have at least 6 characters")
            },
            equality: {
                attribute: "password",
                message: t("^The confirmation password must match the entered password")
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >

                <Box sx={{ p: 3 }} component="form" onSubmit={handleSubmit} noValidate>
                    <Typography sx={{ fontSize: 28, fontWeight: 600, mb: 2 }}>
                        {t("Sign up")}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <FormControl sx={{ width: "100%", mb: 2 }} error={formError?.first_name} required>
                                <FormLabel htmlFor="outlined-adornment-password">{t("First name")}</FormLabel>
                                <TextField
                                    name="first_name"
                                    required
                                    error={formError?.first_name}
                                />
                                {formError?.first_name ? <FormHelperText>{formError?.first_name[0]}</FormHelperText> : ""}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl sx={{ width: "100%", mb: 2 }} error={formError?.last_name} required>
                                <FormLabel htmlFor="outlined-adornment-password">{t("Last name")}</FormLabel>
                                <TextField
                                    name="last_name"
                                    label={t("Last name")}
                                    required
                                    error={formError?.last_name}
                                />
                                {formError?.last_name ? <FormHelperText>{formError?.last_name[0]}</FormHelperText> : ""}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <FormControl sx={{ width: "100%", mb: 2 }} error={formError?.date_of_birth} required>
                        <FormLabel htmlFor="outlined-adornment-password">{t("Date of birth")}</FormLabel>
                        <DatePicker
                            name="date_of_birth"
                            required
                            value={dayjs()}
                        />
                        {formError?.date_of_birth ? <FormHelperText>{formError?.date_of_birth[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControl error={formError?.email} sx={{ mb: 2, width: "100%" }}>
                        <FormLabel htmlFor="outlined-adornment-password">{t("Email Address")}</FormLabel>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            error={formError?.email}
                        />
                        {formError?.email ? <FormHelperText>{formError?.email[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControl error={formError?.password} sx={{mb: 3,  width: "100%" }}>
                        <FormLabel htmlFor="outlined-adornment-password">{t("Password")}</FormLabel>
                        <OutlinedInput
                            required
                            fullWidth
                            name="password"
                            id="password"
                            autoComplete="current-password"
                            type={showPassword ? "text" : "password"}
                            error={formError?.password}
                        />
                        {formError?.password ? <FormHelperText>{formError?.password[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControl error={formError?.password} sx={{ width: "100%" }}>
                        <FormLabel htmlFor="outlined-adornment-password">{t("Repeat password")}</FormLabel>
                        <OutlinedInput
                            required
                            fullWidth
                            name="password_confirmation"
                            id="password_confirmation"
                            autoComplete="password_confirmation"
                            type={showPassword ? "text" : "password"}
                            error={formError?.password_confirmation}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        onMouseUp={handleMouseUpPassword}
                                        size='small'
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {formError?.password_confirmation ? <FormHelperText>{formError?.password_confirmation[0]}</FormHelperText> : ""}
                    </FormControl>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        loading={isLoading}
                        color='secondary'
                    >
                        {t("Sign up")}
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/reset-password" variant="body2">
                               {t("Forgot password?")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/login" variant="body2">
                                {t("Have an account? Sign In")}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}