import { getInitials } from "@/utils/Hooks";
import { useToasterContext } from "@/utils/ToasterContext";
import { changeUserPoint, removeUserLoyalty } from "@/utils/UserInfomations";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, FormControl, FormHelperText, FormLabel, MenuItem, Modal, Select, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmDelete from "@/components/ConfirmDelete";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import validate from "validate.js";

dayjs.extend(customParseFormat);
dayjs.extend(require("dayjs/plugin/isBetween"));

const AddPoint = ({ selectedUser, setSelecedUser, refresh }) => {

    const { t } = useTranslation();

    const { showMessage } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState(false);
    const [type, setType] = useState("increase");
    const [formError, setFormError] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);

    async function handleSubmit() {
        const formData = {
            user_id: selectedUser?.id,
            reason: reason,
            type: type
        };
        const error = validate(formData, constraints);
        if (!error) {
            try {
                setIsLoading("update");
                const response = await changeUserPoint(formData);
                if (response && !response.error) {
                    showMessage("success", "Data updated successfully!");
                    setSelecedUser(false);
                    refresh();
                } else {
                    showMessage("error", response?.message || "An error occurred, please try later");
                }
            } catch (error) {
                showMessage("error", "An error occurred, please try later");
            } finally {
                setIsLoading(false);
            }
        } else {
            showMessage("error", "An error occurred, please try later");
        }
        setFormError(error);
    }

    async function handleRemoveLoyalty() {
        const formData = {
            user_id: selectedUser?.id
        };
        const error = validate(formData, constraintsRemove);
        if (!error) {
            try {
                setIsLoading("delete");
                const response = await removeUserLoyalty(formData);
                if (response && !response.error) {
                    showMessage("success", "Data updated successfully!");
                    setSelecedUser(false);
                    refresh();
                } else {
                    showMessage("error", response?.message || "An error occurred, please try later");
                }
            } catch (error) {
                showMessage("error", "An error occurred, please try later");
            } finally {
                setIsLoading(false);
                setDeleteItem(false);
            }
        } else {
            showMessage("error", "An error occurred, please try later");
        }
        setFormError(error);
    }

    const constraints = {
        user_id: {
            presence: true,
        },
        reason: {
            presence: true,
        },
        type: {
            presence: true,
        },
    };

    const constraintsRemove = {
        user_id: {
            presence: true,
        }
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 540,
        bgcolor: "background.paper",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };


    return (
        <div>
            <Modal
                open={selectedUser ? true: false}
                onClose={() => setSelecedUser(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{
                    ...style,
                    width: 680,
                    maxWidth: "90vw"
                }}>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 24,
                        mb: 2,
                        textTransform: "uppercase"
                    }}>
                    {t("Add point to user")}
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{mb: 3}}
                        alignItems="center">
                            <Stack
                            direction="row"
                            spacing={2}
                            sx={{mb: 3, flex: 1}}
                            alignItems="center">
                            <Avatar
                                src={selectedUser?.avatar} 
                                sx={{
                                    bgcolor: "#000",
                                }} 
                            >
                                {getInitials(selectedUser?.display_name || selectedUser?.email)}
                            </Avatar>
                            <Box>
                                <p className='font-bold'>
                                    {selectedUser?.display_name || "-"}
                                </p>
                                <p style={{ fontSize: 12}}>
                                    {selectedUser?.email}
                                </p>
                                <span className='bg-black text-white inline-block px-2 text-xs'>{selectedUser?.user_information?.loyalty?.title || "-"}</span>
                            </Box>
                        </Stack>
                        <Box>
                            <LoadingButton onClick={() => setDeleteItem(true)} variant='contained' color='secondary' sx={{ background: "red"}} >
                                {t("Reset ranking")}
                            </LoadingButton>
                        </Box>
                    </Stack>
                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.type} required>
                        <FormLabel>{t("Type")}</FormLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            value={type}
                            fullWidth
                            onChange={(e) => setType(e.target.value)}
                        >
                            <MenuItem value={"increase"}>{t("Increase")}</MenuItem>
                            <MenuItem value={"decrease"}>{t("Decrease")}</MenuItem>
                        </Select>
                        {formError?.type ? <FormHelperText>{formError?.type[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.reason} required>
                        <FormLabel>{t("Reason")}</FormLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={reason}
                            fullWidth
                            onChange={(e) => setReason(e.target.value)}
                        >
                            <MenuItem value={"friend_referral"}>{t("Friend referral")} ({type == "increase" ? "+" : "-"} 3)</MenuItem>
                            <MenuItem value={"challenge"}>{t("Challenge")} ({type == "increase" ? "+" : "-"} 15)</MenuItem>
                            <MenuItem value={"social_share"}>{t("Social shared")} ({type == "increase" ? "+" : "-"} 2)</MenuItem>
                        </Select>
                        {formError?.reason ? <FormHelperText>{formError?.reason[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControl sx={{ width: "100%", mb: 3 }}>
                        <LoadingButton loading={isLoading == "update"} onClick={handleSubmit} variant='contained'>
                            {t("Submit")}
                        </LoadingButton>
                    </FormControl>
                </Box>
            </Modal>
            <ConfirmDelete open={deleteItem} setOpen={setDeleteItem} isLoading={isLoading == "delete"} onSubmit={handleRemoveLoyalty}/>
        </div>
    );
};

export default AddPoint;