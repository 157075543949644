export default function filterEmptyValuesObject(object) {
    // Kiểm tra nếu không phải object hoặc null
    if (typeof object !== "object" || object === null) {
        return {};
    }

    const filterParams = {};

    for (const [key, value] of Object.entries(object)) {
        // Bỏ qua nếu giá trị là null, undefined, chuỗi rỗng, "null" hoặc "false"
        if (value == null || // null hoặc undefined
            (typeof value === "string" && (value === "" || value === "null" || value === "false"))) {
            continue;
        }

        // Ép kiểu thành số cho 'paged' và 'per_page'
        if (key === "paged" || key === "per_page") {
            const numValue = Number(value);
            if (!isNaN(numValue)) {
                filterParams[key] = numValue;
            }
        } else {
            filterParams[key] = value;
        }
    }

    return filterParams;
}