import NavHeader from "@/components/NavHeader";
import { getSetings, updateSettings } from "@/utils/Settings";
import { useToasterContext } from "@/utils/ToasterContext";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, FormControl, FormLabel, IconButton, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import Banks from "./banks";

import PageLoading from "@/components/PageLoading";
import { objectToQueryString } from "@/utils/Hooks";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

const Index = () => {

    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const { t } = useTranslation(); 
    const [data, setData] = useState({
        bank_id: "",
        bank_account_name: "",
        bank_account_number: "",
        bank_account_description: "",
    });
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bank, setBank] = useState(false);

    const handleClear = () => {
        setBank(null);
    };

    useEffect(() => {
        handleChangeData("bank_id", bank?.bin);
    }, [bank]);

    useEffect(() => {
        handleGetSettings();
    }, []);

    async function handleGetSettings() {
        try {
            startProgress();
            const response = await getSetings({
                settings: [
                    "bank_id",
                    "bank_account_name",
                    "bank_account_number",
                    "bank_account_description"
                ],
            });
            if (Array.isArray(response) && response.length) {
                let updateData = {};
                response.map((val) => {
                    updateData[val.setting_key] = val.setting_value;
                });
                if (updateData?.bank_id) {
                    const SelectedBank = Banks.find((val) => val.bin == updateData.bank_id);
                    setBank(SelectedBank);
                }
                setData((data) => ({
                    ...data,
                    ...updateData
                }));
            }
        } catch (error) {
            console.error("error", error);
        } finally {
            completeProgress();
            setIsLoaded(true);
        }
    }

    async function handleUpdateSettings(e) {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await updateSettings(data);
            if (response && !response.error) {
                showMessage("success", t("Data updated successfully!"));
            } else {
                showMessage("error", t("An error has occurred!"));
            }
        } catch (error) {
            console.error("error", error);
            showMessage("error", t("An error has occurred!"));
        } finally {
            setIsLoading(false);
        }
    }

    function handleChangeData(key, value) {
        setData((data) => ({
            ...data,
            [key]: value
        }));
    }

    if (!isLoaded) {
        return (
            <PageLoading />
        );
    }

    return (
        <div>
            <NavHeader
                title={t("VietQr")}
                backLink="/admin/integrations/"
                breadcrumb={[
                    {
                        title: t("VietQr payment information")
                    }
                ]}
            />
            <Grid container spacing={4} >
                <Grid xs={12} lg={8}>
                    <form onSubmit={handleUpdateSettings}>
                        <Typography sx={{ fontWeight: 600, mb: 2 }}>
                            {t("Set up payment information")}
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FormLabel>{t("Choose a bank")}</FormLabel>
                            <Autocomplete
                                options={Banks}
                                autoHighlight
                                name="bank_id"
                                getOptionLabel={(option) => `${option.name} (${option.code})`}
                                getOptionKey={(option) => option.code}
                                inputValue={bank ? `${bank.name} (${bank.code})` : ""}
                                onChange={(e, value) => setBank(value)}
                                disableClearable={true}
                                renderOption={(props, option) => (
                                    <Box
                                        key={option.code}
                                        component="li"
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        {...props}
                                        >
                                        <img loading="lazy" width="50" src={option.logo} alt="" />
                                        {option.name} ({option.code})
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="bank_id"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                                {bank && (
                                                <IconButton onClick={handleClear}>
                                                    <ClearIcon />
                                                </IconButton>
                                                )}
                                            </>
                                            ),
                                        }}
                                        />
                                    )}
                                />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FormLabel>{t("Bank account number")}</FormLabel>
                            <TextField
                                type="number"
                                value={data?.bank_account_number}
                                onChange={(e) => handleChangeData("bank_account_number", e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FormLabel>{t("Bank account name")}</FormLabel>
                            <TextField
                                type="text"
                                value={data?.bank_account_name}
                                onChange={(e) => handleChangeData("bank_account_name", e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FormLabel>{t("Payment description")}</FormLabel>
                            <TextField
                                type="text"
                                value={data?.bank_account_description}
                                onChange={(e) => handleChangeData("bank_account_description", e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Box>
                                <LoadingButton
                                    variant='contained'
                                    color='secondary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}>
                                    {t("Save")}
                                </LoadingButton>
                            </Box>
                        </FormControl>
                    </form>
                </Grid>
                <Grid xs={12} lg={4}>
                    <Box>
                        <Typography sx={{ fontWeight: 600, mb: 2 }}>
                            {t("Sample preview")}
                        </Typography>
                        <img
                            src={objectToQueryString({
                                ...data,
                                accountName: data.bank_account_name,
                                amount: 10000,
                                addInfo: data.bank_account_description
                            })}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default Index;
