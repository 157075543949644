import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import Logo from "@/assets/logo-light.svg";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";

import { useToasterContext } from "@/utils/ToasterContext";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: 9,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Header = () => {

    const { t } = useTranslation();
    const { changeLanguage, currentLanguage } = useToasterContext();

    return (
        <AppBar sx={{
            background: "rgb(0 0 0 / 85%)",
            backdropFilter: "blur(10px)",
            pr: "24px",
        }} open={open}>
            <div className="container mx-auto">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            marginRight: "36px",
                            ...(open && { display: "none" }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Stack direction="row" spacing={3} sx={{ flexGrow: 1 }}>
                        <Link to="/">
                            <img src={Logo} width={120} alt="" />
                        </Link>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={3}>
                        <div>
                            <div className='bg-slate-700 rounded-full flex gap-1 items-center px-1 py-1 h-auto' spacing={1}>
                                <button onClick={() => changeLanguage("en")} className={classNames("uppercase text-[16px] leading-[16px] rounded-full !px-3 !py-1", {"bg-black": currentLanguage == "en"})}>
                                    {t("EN")}
                                </button>
                                <button onClick={() => changeLanguage("vi")} className={classNames("uppercase text-[16px] leading-[16px] rounded-full !px-3 !py-1", {"bg-black": currentLanguage == "vi"})}>
                                    {t("VN")}
                                </button>
                            </div>
                        </div>
                        {/* {
                            user ? (
                                <IconButton as={Link} to="/cart" color="inherit">
                                    <Badge badgeContent={cart?.length} color="success">
                                        <ShoppingCartIcon />
                                    </Badge>
                                </IconButton>
                            )  : ""
                        } */}
                    </Stack>
                </Toolbar>
            </div>
        </AppBar>
    );
};

export default Header;
