import { instance } from "./axiosInstance";

export async function getSetings(params) {
    const config = {
        params: params
    };
    return await instance
        .get("/settings", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function updateSettings(data) {
    return await instance
        .post("/settings", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}