import { useEffect, useState } from "react";

import NavHeader from "@/components/NavHeaderHome";
import PageLoading from "@/components/PageLoading";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { findManyClasses } from "@/utils/Classes";
import { findManySessions } from "@/utils/TrainerSessions";

import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";

import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localizedFormat from "dayjs/plugin/localizedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekday from "dayjs/plugin/weekday";

import { findManyTrainerSchedules } from "@/utils/TrainerSchedule";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DayView from "./Components/DayView";
import WeekView from "./Components/WeekView";

// Extend dayjs with plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);

export default function EnhancedTable() {

    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [step, setStep] = useState(0);
    const [view, setView] = useState("week");
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [classes, setClasses] = useState(false);
    const [calendar, setCalendar] = useState(false);

    const ViewComponent = {
        day: <DayView data={data} step={step} calendar={calendar} />,
        week: <WeekView data={data} step={step} calendar={calendar} />,
    };

    const [filter, setFilter] = useState({
        trainer_id: null,
        class_id: null,
        start_time: null,
        end_time: null
    });

    useEffect(() => {
        if (filter?.start_time) {
            handleFindManyData(filter);
        }
    }, [filter]);

    useEffect(() => {
        const start_time = dayjs().add(step, view).startOf(view).format("YYYY-MM-DD HH:mm:ss");
        const end_time = dayjs().add(step, view).endOf(view).format("YYYY-MM-DD HH:mm:ss");
        setFilter({
            ...filter,
            start_time: start_time,
            end_time: end_time
        });
    }, [step, view]);

    const handleFindManyData = async (params) => {
        try {
            setIsLoading(true);
            const response = await findManySessions(params);
            if (response && !response.error) {
                const { data } = response;
                setData(data);
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            setIsLoading(false);
        }
    };

    async function handleFindManyClasses() {
        try {
            const response = await findManyClasses({});
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    setClasses(data);
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    async function handleFindCalendarData(trainer_id) {
        try {
            const monthYear = dayjs().add(step, "month").format("YYYY-MM");
            const filter = {
                month: monthYear
            };
            const response = await findManyTrainerSchedules(trainer_id, filter);
            if (response && !response.error) {
                setCalendar(response.data);
            } else {
                setCalendar([]);
            }
        } catch (error) {
            console.error("error", error);
        }
    }

    useEffect(() => {
        console.log("user", user);
        if (user?.trainer_information?.id) {
            const trainer_id = user.trainer_information.id;
            handleFindCalendarData(trainer_id);
        }
    }, [user, step]);

    useEffect(() => {
        handleFindManyClasses();
    }, []);

    return (
        <Box sx={{ width: "100%" }}>
            <NavHeader
                title="Sessions"
                breadcrumb={[
                    {
                        title: "Instructor"
                    },
                    {
                        title: "Sessions"
                    }
                ]}
                // endAction={(
                //     <Link to="/trainer/sessions/create">
                //         <Button
                //             startIcon={<AddIcon />}
                //             variant='contained'
                //             color='primary'>
                //             Create Session
                //         </Button>
                //     </Link>
                // )}
            />
            {/* <div className="grid grid-cols-3 gap-3 mb-10">
                <div className='p-3 border border-black'>
                    <Link to="/trainer/sessions">
                        {t("Sessions")}
                    </Link>
                </div>
                <div className='p-3 border border-black'>
                    <Link to="/trainer/sessions">
                        {t("Information")}
                    </Link>
                </div>
                <div className='p-3 border border-black'>
                    <Link to="/trainer/sessions">
                        {t("Information")}
                    </Link>
                </div>
            </div> */}
            <Box>
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ mb: 3 }}>
                    <FormControl sx={{ width: 220 }}>
                        <InputLabel>Select view</InputLabel>
                        <Select value={view} onChange={(e) => setView(e.target.value)} label="Select view" placeholder='View'>
                            <MenuItem value="day">Today</MenuItem>
                            <MenuItem value="week">This Week</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: 220 }}>
                        <InputLabel>Select Class</InputLabel>
                        <Select
                            onChange={(e) => setFilter({ ...filter, class_id: e.target.value })}
                            label="Select Class"
                            placeholder='View'>
                            <MenuItem>All</MenuItem>
                            {
                                Array.isArray(classes) && classes.length ?
                                    classes.map((val) => {
                                        return (
                                            <MenuItem value={val.id} key={val.id}>
                                                {val.title}
                                            </MenuItem>
                                        );
                                    })
                                    : ""
                            }
                        </Select>
                    </FormControl>
                    <Button onClick={() => setStep((step) => step - 1)} variant='outlined'>
                        {t("Prev")}
                    </Button>
                    <Button onClick={() => setStep((step) => step + 1)} variant='outlined'>
                        {t("Next")}
                    </Button>
                </Stack>
                {isLoading ? <PageLoading height={320} /> : ViewComponent[view]}
            </Box>
        </Box>
    );
}