import { numberWithCommas } from "@/utils/Hooks";
import { deleteMembership, deleteMemberships, findManyMemberships } from "@/utils/Memberships";
import { useToasterContext } from "@/utils/ToasterContext";
import { alpha } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import ActionCell from "@/components/ActionCell";
import ConfirmDelete from "@/components/ConfirmDelete";
import DateCell from "@/components/DateCell";
import FilterTable from "@/components/FilterTable";
import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

import filterEmptyValuesObject from "@/utils/filterEmptyValuesObject";
import DoneIcon from "@mui/icons-material/Done";

const headCells = [
    {
        id: "id",
        label: "ID",
    },
    {
        id: "title",
        label: "Title",
    },
    {
        id: "academic_classes",
        label: "Classes",
    },
    {
        id: "regular_price",
        label: "Price",
    },
    {
        id: "is_private",
        label: "Private",
    },
    {
        id: "duration_days",
        label: "Duration days",
    },
    {
        id: "sessions",
        label: "Sessions",
    },
    {
        id: "category",
        label: "Category",
    },
    {
        id: "created_at",
        label: "Created at",
    },
    {
        id: "actions",
        label: "Actions",
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <p className="font-bold">
                            {t(headCell.label)}
                        </p>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function EnhancedTable() {

    const { t } = useTranslation();
    
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [deleteItem, setDeleteItem] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageStatus, setPageStatus] = useState("idle");

    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_date: null,
        end_date: null
    });

    const handleFindManyData = async (filter) => {
        try {
            startProgress();
            setSearchParams(filter);
            const response = await findManyMemberships(filter);
            if (response && !response.error) {
                const { data, meta } = response;
                if (Array.isArray(data)) {
                    setData(data);
                    setTotal(meta?.total);
                } else {
                    setData([]);
                }
            }
            setPageStatus("complete");
        } catch (error) {
            console.error("error: ", error);
        } finally {
            completeProgress();
        }
    };

    async function handleDeleteData(id) {
        try {
            setDeleteLoading(true);
            const response = await deleteMembership(id);
            if (response && !response.error) {
                const { id } = response;
                if (id) {
                    handleFindManyData(filter);
                    showMessage("success", t("Delete item successully!"));
                }
            } else {
                showMessage("error", t("An error occurred during delete item!"));
            }
        } catch (error) {
            console.error("error: ", error);
            showMessage("error", t("An error occurred during delete item!"));
        } finally {
            setDeleteLoading(false);
            setDeleteItem(false);
        }
    }

    async function handleDeleteMultipeData(selected) {
        try {
            setDeleteLoading(true);
            const response = await deleteMemberships({
                ids: selected
            });
            if (response && !response.error) {
                handleFindManyData(filter);
                showMessage("success", t("Delete item successully!"));
            } else {
                showMessage("error", t("An error occurred during delete item!"));
            }
        } catch (error) {
            console.error("error", error);
            showMessage("error", t("An error occurred during delete item!"));
        } finally {
            setDeleteLoading(false);
            setDeleteItem(false);
        }
    }

    useEffect(() => {
        const initFilter = {
            search: searchParams.get("search"),
            order: searchParams.get("order"),
            start_date: searchParams.get("start_date"),
            end_date: searchParams.get("end_date"),
            paged: searchParams.get("paged") || 1,
            per_page: searchParams.get("per_page") || 12,
        };
        let filterParams = {
            ...filter,
            ...initFilter
        };
        setFilter(filterParams);
        handleFindManyData(filterEmptyValuesObject(filterParams));
    }, []);

    useEffect(() => {
        if (pageStatus == "complete") {
            handleFindManyData(filterEmptyValuesObject(filter));
        }
    }, [filter]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setFilter({
            ...filter,
            paged: newPage ? (newPage + 1) : 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        });
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;

    if (pageStatus != "complete") {
        return (
            <PageLoading />
        );
    }

    function EnhancedTableToolbar(props) {
        const { numSelected, onSubmit } = props;
        const { t }  = useTranslation();
        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <>
                        <p>
                            {numSelected} {t("selected")}
                        </p>
                        <Tooltip title="Delete">
                            <IconButton onClick={onSubmit}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : ""
                }
            </Toolbar>
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <NavHeader
                title={t("Memberships")}
                breadcrumb={[
                    {
                        title: t("Memberships")
                    }
                ]}
                endAction={(
                    <Link to="/admin/memberships/create">
                        <Button
                            startIcon={<AddIcon />}
                            variant='contained'
                            size='small'
                            color='primary'>
                            {t("Create")}
                        </Button>
                    </Link>
                )}
            />
            <Box>
                <FilterTable filter={filter} setFilter={setFilter} />
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                        <Table
                            stickyHeader aria-label="sticky table"
                            size='large'>
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                            />
                            <TableBody>
                                {data.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row?.id}
                                            selected={isItemSelected}
                                            sx={{ cursor: "pointer" }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={(event) => handleClick(event, row?.id)}
                                                    inputProps={{
                                                        "aria-labelledby": labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <p>
                                                    {row?.id}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <Link to={"/admin/memberships/" + row?.id}>
                                                    <p className='font-bold'>
                                                        {row?.title}
                                                    </p>
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <p>
                                                    {row?.academic_classes?.length}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <p>
                                                    {row?.regular_price ? numberWithCommas(row.regular_price) + "₫" : "-"}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <p>
                                                    {row?.is_private ? <DoneIcon color='success'/> : "-"}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <p>
                                                    {row?.duration_days}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <p className='capitalize'>
                                                    {row?.sessions == "-1" ? "unlimited" : row?.sessions}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    Array.isArray(row?.membership_categories) && row?.membership_categories?.length ? 
                                                        row?.membership_categories.map((val) => {
                                                            return (<p key={val.id}>{val.title}</p>);
                                                        }) : ""
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <DateCell time={row?.created_at}/>
                                            </TableCell>
                                            <TableCell sx={{ width: 120}}>
                                                <ActionCell
                                                    rowData={row}
                                                    editUrl="/admin/memberships/"
                                                    setDeleteItem={setDeleteItem}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {selected.length ? (
                            <EnhancedTableToolbar
                                numSelected={selected.length}
                                isLoading={deleteLoading}
                                onSubmit={() => handleDeleteMultipeData(selected)} />
                        ) : ""}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[12, 24, 36]}
                        component="div"
                        count={total || 0}
                        rowsPerPage={parseInt(filter?.per_page) || 12}
                        page={parseInt(filter?.paged - 1)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
            <ConfirmDelete
                open={deleteItem ? true : false}
                setOpen={setDeleteItem}
                isLoading={deleteLoading}
                onSubmit={() => handleDeleteData(deleteItem)}
            />
        </Box>
    );
}