
import { updateSubscription } from "@/utils/Subscriptions";
import { useToasterContext } from "@/utils/ToasterContext";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import validate from "validate.js";

import FilterTable from "@/components/FilterTable";
import { findManySessionsBySubscription } from "@/utils/Subscriptions";
import { Button, TablePagination } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const headCells = [
    {
        id: "transaction_ref",
        numeric: false,
        disablePadding: false,
        label: "Class",
    },
    {
        id: "transaction_type",
        numeric: false,
        disablePadding: false,
        label: "Trainer",
    },
    {
        id: "share_with",
        numeric: false,
        disablePadding: false,
        label: "Share with",
    },
    {
        id: "transaction_amount",
        numeric: false,
        disablePadding: false,
        label: "Start in",
    },
    {
        id: "duration",
        numeric: false,
        disablePadding: false,
        label: "Duration",
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
    },
    {
        id: "no_show",
        numeric: false,
        disablePadding: false,
        label: "No show",
    },
    {
        id: "transaction_status",
        numeric: false,
        disablePadding: false,
        label: "Checked in",
    },
    {
        id: "created_at",
        numeric: false,
        disablePadding: false,
        label: "Checked out",
    },
    {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Action",
    }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Typography sx={{ fontWeight: 600 }}>
                            {headCell.label}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const SubscriptionDetailed = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [user, setUser] = useState(null);
    const [membership, setMembership] = useState(null);
    const [isTriggerActive, setIsTriggerActive] = useState(0);
    const [pageInit, setPageInit] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [total, setTotal] = useState(0);


    const [filter, setFilter] = useState({
        paged: 1,
        per_page: 12,
    });

    const handleChangePage = (event, newPage) => {
        setFilter({
            ...filter,
            paged: newPage ? (newPage + 1) : 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        });
    };

    async function handleFindData() {
        try {
            startProgress();
            setSearchParams(filter);
            const response = await findManySessionsBySubscription(id, filter);
            if (response && !response.error) {
                const { data, meta } = response;
                if (data) {
                    setData(data);
                    setTotal(meta?.total);
                }
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            setPageInit("complete");
            completeProgress();
        }
    }

    async function handleUpdate(id, data) {
        try {
            setIsLoading(true);
            startProgress();
            const notify = data?.sent_notify;
            const response = await updateSubscription(id, data);
            if (response && !response.error) {
                const { data } = response;
                if (data?.id) {
                    showMessage("success", "Data updated successfully!");
                    if(notify){
                        showMessage("success", "Registration information has been sent to the user's email");
                    }
                    navigate("/admin/subscriptions/" + data.id);
                } else {
                    showMessage("error", "An error occurred, please try again later!");
                }
            }
        } catch (error) {
            showMessage("error", "An error occurred, please try again later!");
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const notify = data.get("sent_notify");

        let dataForm = {
            user_id: user?.id,
            membership_id: membership?.id,
            max_sessions: data.get("max_sessions"),
            status: data.get("status"),
            trigger_active: isTriggerActive ? 1 : 0,
            sent_notify: notify == "on" ? 1 : 0
        };

        if(!isTriggerActive) {
            dataForm = {
                ...dataForm,
                start_date: dayjs(data.get("start_date"), "DD/MM/YYYY").format("YYYY-MM-DD"),
                end_date: dayjs(data.get("end_date"), "DD/MM/YYYY").format("YYYY-MM-DD"),
            };
        }

        const error = validate(dataForm, constraints);

        if (!error) {
            handleUpdate(id, dataForm);
        } else {
            console.log("error", error);
            showMessage("error", "One or more input fields are invalid!");
        }

        setFormError(error);
    }

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startDate = dayjs(attributes.start_date, "YYYY-MM-DD");
        const endDate = dayjs(value, "YYYY-MM-DD");
        if (endDate.isBefore(startDate)) {
            return options.message || "must be after start time";
        }
    };

    let constraints = {
        user_id: {
            presence: true,
        },
        membership_id: {
            presence: true,
        },
        max_sessions: {
            presence: true,
        },
        status: {
            presence: true,
        }
    };

    if(!isTriggerActive){
        constraints = {
            ...constraints,
            start_date: {
                presence: { allowEmpty: false, message: "is required" },
            },
            end_date: {
                presence: { allowEmpty: false, message: "is required" },
                timeAfter: {
                    message: "must be after start time"
                }
            },
        };
    }

    useEffect(() => {
        handleFindData(filter);
    }, [filter]);

    useEffect(() => {
        console.log("data", data);
    }, [data]);


    const Row = ({ rowData }) => {

        const {
            id,
            transaction_ref,
            no_show,
            name_shared,
            check_in_time,
            check_out_time,
            status,
            session
        } = rowData;


        const academicClass = rowData?.class;
        const [open, setOpen] = useState(false);
        const { trainer, class_types, start_time, duration_minutes } = session;

        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={transaction_ref}
                    onClick={() => setOpen(!open)}
                    sx={{ cursor: "pointer" }}>
                    <TableCell>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center">    
                            <Link to={"/admin/sessions/" + id}>
                                <strong className="uppercase">
                                    {class_types ? class_types : academicClass?.title} 
                                </strong>
                            </Link>
                        </Stack>
                    </TableCell>
                    <TableCell>
                       <Link to={`/admin/trainers/${trainer?.id}`}>
                            <Typography>
                                {trainer?.full_name}
                            </Typography>
                       </Link>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {name_shared || "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            { start_time ? dayjs(start_time).format("HH:mm, DD/MM/YYYY") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {duration_minutes} minutes
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {
                            status ? (
                                <span className={classNames("border px-1 capitalize text-sm", {
                                    "border-green-200 bg-green-100 text-green-600": status == "active",
                                    "border-red-200 bg-red-100 text-red-600": status == "canceled",
                                    "border-yellow-200 bg-yellow-100 text-yellow-600": status == "pending"
                                })}>
                                    {{
                                        active: t("Booked"),
                                        pending: t("Pending"),
                                        canceled: t("canceled"),
                                    }[status]}
                                </span>
                            ) : "-"
                        }
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {no_show ? t("No show") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {check_in_time ? dayjs(check_in_time).format("HH:mm, DD/MM/YYYY") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {check_out_time ? dayjs(check_out_time).format("HH:mm, DD/MM/YYYY") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{ width: 120}}>
                        <Link to={"/admin/sessions/" + session?.id}>
                            <Button variant='contained' size='small'>
                                {t("View")}
                            </Button>
                        </Link>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    };


    if (pageInit != "complete") {
        return (
            <PageLoading />
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <NavHeader
                title="Booking History"
                breadcrumb={[
                    {
                        title: "Subscriptions",
                        link: "/admin/subscriptions/" + id
                    },
                    {
                        title: "Booking History"
                    }
                ]}
                backLink={"/admin/subscriptions/" + id}
            />
           <FilterTable filter={filter} setFilter={setFilter} />
           <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", overflow: "hidden", mb: 3, borderRadius: 0 }}>
                <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                    <Table
                        stickyHeader aria-label="sticky table"
                        size='medium'>
                            <EnhancedTableHead
                                rowCount={data?.length}
                            />
                            <TableBody>
                                {data.map((row) => {
                                    return (
                                        <Row key={row.id} rowData={row} />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        labelRowsPerPage={t("Rows per page:")}
                        rowsPerPageOptions={[12, 24, 36]}
                        component="div"
                        count={total}
                        rowsPerPage={filter?.per_page}
                        page={filter?.paged - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </Box>
    );
};

export default SubscriptionDetailed;
