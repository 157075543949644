import { instance } from "./axiosInstance";

export async function findManyUserWaitingLists(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/user/waiting-lists", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneUserWaitingList(id) {
    return await instance
        .get(`/user/waiting-lists/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function applySessionFromWaitingList(data) {
    return await instance
        .post("/waiting-lists", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function findOneUserWaitingListCheckin(token) {
    return await instance
        .get(`/waiting-lists/apply-token/${token}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function TrainerNoShowUser(id) {
    return await instance
        .post(`/user/waiting-lists/no-show/${id}`, {})
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function TrainerCheckInUser(id) {
    return await instance
        .post(`/user/waiting-lists/checkin/${id}`, {})
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function TrainerCheckOutUser(id) {
    return await instance
        .post(`/user/waiting-lists/checkout/${id}`, {})
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createUserWaitingList(data) {
    return await instance
        .post("/user/waiting-lists", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateUserWaitingList(id, data) {
    return await instance
        .put("/user/waiting-lists/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteUserWaitingList(id) {
    const config = {
        data: {
            id: id
        }
    };
    return await instance
        .delete("/user/waiting-lists", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}