import { instance } from "./axiosInstance";

export async function findManyOrders(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/orders", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneOrder(id) {
    return await instance
        .get(`/orders/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateOrder(id, data) {
    return await instance
        .put("/orders/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createOrder(data) {
    return await instance
        .post("/orders", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteOrder(id) {
    const config = {
        data: {
            id: id
        }
    };
    return await instance
        .delete("/orders/" + id, config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}


export async function applySubscription(id) {
    return await instance
        .post("/orders/" + id + "/apply-subscriptions", {})
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}


export async function deleteOrders(data) {
    const config = {
        data: data
    };
    return await instance
        .delete("/orders", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function createTransaction(order_id, data) {
    return await instance
        .post("/orders/" + order_id + "/transaction", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}