import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { findManyClasses } from "@/utils/Classes";
import { findManySessions } from "@/utils/Sessions";
import { findManyTrainers } from "@/utils/Trainers";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localizedFormat from "dayjs/plugin/localizedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekday from "dayjs/plugin/weekday";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import DayView from "./Components/DayView";
import WeekView from "./Components/WeekView";

// Extend dayjs with plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);

export default function EnhancedTable() {

    const [data, setData] = useState([]);
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState( isNumeric(searchParams.get("step")) ? parseInt(searchParams.get("step")) :  0);
    const [trainers, setTrainers] = useState(false);
    const [classes, setClasses] = useState(false);
    const [pageInit, setPageInit] = useState(false);
    const [view, setView] = useState("week");

    function isNumeric(str) {
        return !isNaN(str) && !isNaN(parseFloat(str));
    }

    const ViewComponent = {
        day: <DayView data={data} step={step} reload={() => handleFindManyData(filter)}/>,
        week: <WeekView data={data} step={step} reload={() => handleFindManyData(filter)}/>,
    };

    const [filter, setFilter] = useState({
        trainer_id: null,
        class_id: null,
        start_time: null,
        end_time: null,
        step: step
    });

    useEffect(() => {
        let trainer_id = searchParams.get("trainer_id");
        let class_id = searchParams.get("class_id");
        setFilter({
            trainer_id: trainer_id,
            class_id: class_id
        });
    }, []);

    useEffect(() => {
        if (filter?.start_time) {
            handleFindManyData(filter);
        }
    }, [filter]);

    useEffect(() => {

        let start_time, end_time;

        if(view == "week") {
            start_time = dayjs().add(step, "week").startOf("week").format("YYYY-MM-DD HH:mm:ss");
            end_time = dayjs().add(step, "week").endOf("week").format("YYYY-MM-DD HH:mm:ss");
        } else {
            start_time = dayjs().add(step, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss");
            end_time = dayjs().add(step, "day").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        }

        setSearchParams({
            trainer_id: filter?.trainer_id,
            class_id: filter?.class_id,
            step: step
        });
        setFilter({
            ...filter,
            start_time: start_time,
            end_time: end_time
        });
    }, [step, view]);

    const handleFindManyData = async (params) => {
        try {
            setIsLoading(true);
            const response = await findManySessions(params);
            if (response && !response.error) {
                const { data } = response;
                setData(data);
            }
        } catch (error) {
            console.error("error", error);
        } finally {
            setIsLoading(false);
            setPageInit(true);
        }
    };

    async function handleFindManyTrainers(filter) {
        try {
            const response = await findManyTrainers(filter);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    setTrainers(data);
                }
            }
        } catch (error) {
            console.error("error", error);
        }
    }

    async function handleFindManyClasses() {
        try {
            const response = await findManyClasses({});
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    setClasses(data);
                }
            }
        } catch (error) {
            console.error("error", error);
        }
    }

    useEffect(() => {
        handleFindManyClasses();
        handleFindManyTrainers({
            per_page: 999
        });
    }, []);

    if (!pageInit) {
        return (
            <PageLoading />
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <NavHeader
                title={t("Sessions")}
                breadcrumb={[
                    {
                        title: t("Sessions")
                    }
                ]}
            />
            <Box>
                <div className='grid grid-cols-2 lg:grid-cols-4 mb-5 gap-3'>
                    <FormControl>
                        <InputLabel>{t("Select Trainer")}</InputLabel>
                        <Select
                            onChange={(e) => setFilter({ ...filter, trainer_id: e.target.value })}
                            label={t("Select Trainer")}>
                            <MenuItem>{t("All")}</MenuItem>
                            {
                                Array.isArray(trainers) && trainers.length ?
                                    trainers.map((val) => {
                                        return (
                                            <MenuItem value={val.id} key={val.id}>
                                                {val.full_name}
                                            </MenuItem>
                                        );
                                    })
                                    : ""
                            }
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>{t("Select Class")}</InputLabel>
                        <Select
                            onChange={(e) => setFilter({ ...filter, class_id: e.target.value })}
                            label={t("Select Class")}>
                            <MenuItem>{t("All")}</MenuItem>
                            {
                                Array.isArray(classes) && classes.length ?
                                    classes.map((val) => {
                                        return (
                                            <MenuItem value={val.id} key={val.id}>
                                                {val.title}
                                            </MenuItem>
                                        );
                                    })
                                    : ""
                            }
                        </Select>
                    </FormControl>
                        <FormControl>
                        <InputLabel>{t("Select view")}</InputLabel>
                        <Select
                            onChange={(e) => setView(e.target.value)}
                            value={view}
                            label={t("Select view")}>
                            <MenuItem value="week">
                                {t("Week view")}
                            </MenuItem>
                            <MenuItem value="day">
                                {t("Day view")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <div className='flex gap-3'>
                        <Button sx={{borderRadius: 0}} onClick={() => setStep((step) => step - 1)} variant='outlined'>
                            {t("Prev")}
                        </Button>
                        <Button sx={{borderRadius: 0}} onClick={() => setStep((step) => step + 1)} variant='outlined'>
                            {t("Next")}
                        </Button>
                    </div>
                </div>
                {isLoading ? <PageLoading height={320} /> : ViewComponent[view]}
            </Box>
        </Box>
    );
}