import { Link } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import classNames from "classnames";
import dayjs from "dayjs";

const headCells = [
    {
        id: "membership",
        disablePadding: false,
        label: "Membership",
    },
    {
        id: "start_date",
        disablePadding: false,
        label: "Start date",
    },
    {
        id: "end_date",
        disablePadding: false,
        label: "End date",
    },
    {
        id: "max_sessions",
        disablePadding: false,
        label: "Max sessions",
    },
    {
        id: "class_session_user_count",
        disablePadding: false,
        label: "Sessions used",
    },
    {
        id: "status",
        disablePadding: false,
        label: "Status",
    }
];

function EnhancedTableHead(props) {
    const { order, orderBy } = props;
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <p>
                            {headCell.label}
                        </p>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export default function EnhancedTable({ data }) {

    const Row = ({ rowData }) => {

        const { id, user, membership, status, max_sessions, class_session_user_count, start_date, end_date } = rowData;

        return (
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                sx={{ cursor: "pointer", mb: 3 }}
            >
                <TableCell>
                    <Link to={"/admin/subscriptions/" + id}>
                        <p>
                            {membership?.title}
                        </p>
                    </Link>
                </TableCell>
                <TableCell>
                    <p>
                        {start_date ? dayjs(start_date, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"}
                    </p>
                </TableCell>
                <TableCell>
                    <p>
                        {end_date ? dayjs(end_date, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"}
                    </p>
                </TableCell>
                <TableCell>
                    <p>
                        {max_sessions == "-1" ? "unlimited" : max_sessions}
                    </p>
                </TableCell>
                <TableCell>
                    <p>
                        {class_session_user_count || 0}
                    </p>
                </TableCell>
                <TableCell>
                    <div
                        className={classNames("inline-block text-white px-1 text-sm capitalize", {
                            "bg-red-600": status == "inactive",
                            "bg-yellow-600": status == "freeze",
                            "bg-green-600": status == "active",
                        })}>
                        {status}
                    </div>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden", mb: 3, borderRadius: 0 }}>
            <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                <Table
                    stickyHeader aria-label="sticky table"
                    size='medium'>
                    <EnhancedTableHead /> 
                    <TableBody>
                        {
                            Array.isArray(data) && data?.length ?
                                data.map((row) => {
                                    return (
                                        <Row rowData={row} key={row.id} />
                                    );
                                }) : 
                        ""}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}