import { instance } from "./axiosInstance";

export async function refundTransaction(id) {
    return await instance
        .post("/transactions/" + id + "/refund", {})
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteTransaction(id) {
    return await instance
        .delete("/transactions/" + id)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}