import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from "./Routes";
import { ToasterProvider } from "./utils/ToasterContext";

import "dayjs/locale/en-gb";

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#2c3e50",
            contrastText: "#fff",
        },
        secondary: {
            main: "#000000",
            contrastText: "#fff",
        },
        success: {
            main: "#27ae60",
            contrastText: "#fff",
        },
        info: {
            main: "#2980b9",
            contrastText: "#000",
        },
        orange: {
            main: "#f39c12",
            contrastText: "#000",
        },
        warning: {
            main: "#f1c40f",
            contrastText: "#000",
        },
        error: {
            main: "#d35400",
            contrastText: "#000",
        },
        grey: {
            main: "#7f8c8d",
            contrastText: "#000",
        },
    },
    typography: {
        fontFamily: "Khmer MN",
        fontSize: 15,
    },
    components: {
        MuiButton: {
            borderRadius: 3,
        },
        MuiCssBaseline: {
        },
        MuiOutlinedInput: {
            defaultProps: {
                size: "small",
              }
        },
        MuiSelect: {
            defaultProps: {
              size: "small",
            }
        },
        MuiTextField: {
            defaultProps: {
              size: "small"
            }
        }
    }
});

const router = createBrowserRouter(routes);

const App = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <ToasterProvider>
                    <RouterProvider router={router} />
                </ToasterProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default App;
