import { findManyMemberships } from "@/utils/Memberships";
import { findManyUsers } from "@/utils/Users";
import { Box, FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PostPicker from "./PostPicker";

const FilterOrder = ({ filter, setFilter }) => {

  const { t } = useTranslation();
  
  const [membership, setMembership] = useState(false);
  const [user, setUser] = useState(false);
  
  function handleChangeMemberShip(value){
    setMembership(value);
    setFilter((filter) => ({...filter, membership_id: value?.id}));
  }

  function handleChangeUser(value){
    setUser(value);
    setFilter((filter) => ({...filter, user_id: value?.id}));
  }

  return (
    <Box>
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-3 mb-5'>
            <PostPicker 
              label={t("User")}
              getApi={findManyUsers}
              values={[user]}
              optionKey={"email"}
              previewKey={"display_name"}
              labelStyle={{mb: 1, fontSize: 14}}
              onChange={(value) => handleChangeUser(value)}
            />
            <PostPicker 
              label={t("Membership")}
              getApi={findManyMemberships}
              values={[membership]}
              optionKey={"title"}
              labelStyle={{mb: 1, fontSize: 14}}
              onChange={(value) => handleChangeMemberShip(value)}
            />
            <FormControl>
              <FormLabel sx={{mb: 1, fontSize: 14}}>{t("Payment method")}</FormLabel>
              <Select 
                  fullWidth
                  value={filter?.payment_method}
                  onChange={(e) => setFilter((filter) => {
                    let filterOptions = {};
                    switch (e.target.value) {
                      case "vietqr":
                        filterOptions = {
                          payment_method: "vietqr",
                          payment_type: false
                        };
                      break;
                      case "vnpay_dosmetic":
                        filterOptions = {
                          payment_method: "vnpay",
                          payment_type: "VNPAYQR"
                        };
                      break;
                      case "":
                        filterOptions = {
                          payment_method: "vnpay",
                          payment_type: "INTCARD"
                        };
                      break;
                    }
                    return ({
                      ...filter, 
                      ...filterOptions,
                      paged: 1
                    });
                  })}
                  size='small'>
                  <MenuItem value="vietqr">{t("Bank transfer")}</MenuItem>
                  <MenuItem value="vnpay_qr">{t("Qr-scan (VNPAY)")}</MenuItem>
                  <MenuItem value="vnpay_dosmetic">{t("Domestic card and bank account (VNPAY)")}</MenuItem>
                  <MenuItem value="vnpay_internal">{t("International payment card (VNPAY)")}</MenuItem>
                  <MenuItem value="all">{t("All")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
              <FormLabel sx={{mb: 1, fontSize: 14}}>{t("Payment status")}</FormLabel>
              <Select 
                  fullWidth
                  value={filter?.payment_status}
                  onChange={(e) => setFilter((filter) => ({
                    ...filter, 
                    payment_status: e.target.value,
                    paged: 1
                  }))}
                  size='small'>
                  <MenuItem value="success">{t("Payment Successful")}</MenuItem>
                  <MenuItem value="pending">{t("Paynent pending")}</MenuItem>
                  <MenuItem value="failed">{t("Payment failed")}</MenuItem>
                  <MenuItem value="all">{t("All")}</MenuItem>
                </Select>
            </FormControl>
        </div>
    </Box>
  );
};

export default FilterOrder;