import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
    IoCalendarOutline,
    IoHomeOutline,
    IoImagesOutline,
    IoPersonOutline
} from "react-icons/io5";

const ListItems = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    return (
        <Fragment>
            <Link to="/">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname == "/trainer"})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoHomeOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Home")} />
                </ListItemButton>
            </Link>
            <Link to="/trainer/media">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/media")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoImagesOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Media")} />
                </ListItemButton>
            </Link>
            <Link to="/trainer/sessions">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname == "/trainer/sessions"})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoCalendarOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Sessions")} />
                </ListItemButton>
            </Link>
            <Link to="/trainer/information">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname == "/trainer/information"})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoPersonOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Information")} />
                </ListItemButton>
            </Link>
        </Fragment>
    );
};

export default ListItems;