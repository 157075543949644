import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaRegCalendarCheck } from "react-icons/fa";

const ApplyWaitingListConfirmed = () => {
    const { t } = useTranslation();
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignItems: "center",
                }}>
                <FaRegCalendarCheck size={55} style={{marginBottom: 35}}/>
                <Typography sx={{ fontSize: 24, marginBottom: 3 }}>
                    {t("You have successfully booked your appointment, please check your email to receive appointment information")}
                </Typography>
                <Button as={Link} to="/account" variant='contained'>
                    {t("View your account")}
                </Button>
            </Box>
        </Container>
    );
};

export default ApplyWaitingListConfirmed;