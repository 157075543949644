import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notify: [],
    status: "idle" || "loading" || "complete"
};

export const notifySlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addNotify: (state, action) => {
            state.notify = [action.payload, ...state.notify];
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    addNotify
} = notifySlice.actions;

export default notifySlice.reducer;