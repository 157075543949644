import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

const ActionCell = ({ rowData, editUrl, setDeleteItem, children }) => {
    const { t } = useTranslation(); 
    return (
        <PopupState variant="popover" popupId="edit-popup-menu">
            {(popupState) => (
                <Fragment>
                    <IconButton variant="outlined" {...bindTrigger(popupState)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                        <Link to={editUrl + rowData?.id}>
                            <MenuItem>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>{t("Edit")}</ListItemText>
                            </MenuItem>
                        </Link>
                        {
                            setDeleteItem ? (
                                <MenuItem onClick={() => setDeleteItem(rowData?.id)}>
                                    <ListItemIcon>
                                        <DeleteIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{t("Delete")}</ListItemText>
                                </MenuItem>
                            ): ""
                        }
                        {children ? children : ""}
                    </Menu>
                </Fragment>
            )}
        </PopupState>
    );
};

export default ActionCell;
