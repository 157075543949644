import { instance } from "./axiosInstance";

export async function userLogin(data) {
    return await instance
        .post("/auth/login", data)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function refreshToken() {
    return await instance
        .post("/auth/refresh", {})
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function sentPasswordReset(data) {
    return await instance
        .post("/auth/reset-password", data)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });

}

export async function changeUserPassword(data) {
    return await instance
        .post("/auth/change-password", data)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function userRegister(data) {
    return await instance
        .post("/auth/register", data)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function getUserLoggedin() {
    return await instance
        .get("/auth/me")
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}


export async function userLogout() {
    return await instance
        .post("/auth/logout", {})
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}