import HomeIcon from "@mui/icons-material/Home";
import { Box, Breadcrumbs, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const NavHeader = ({ title, breadcrumb, backLink, endAction }) => {

    const { t } = useTranslation();

    return (
        <Box sx={{
            py: 1,
            mb: 2
        }}>
            <Stack direction="row" justifyContent={"space-between"} flexWrap="wrap">
                <Box sx={{ flex: 1}}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}>
                        <Typography
                            sx={{
                                fontWeight: 900,
                                mb: 2,
                                textTransform: "uppercase",
                                fontSize: 28,
                                letterSpacing: 2
                            }}
                            variant="h3"
                            id="tableTitle"
                            component="h3"
                        >
                            {title}
                        </Typography>
                        {
                            backLink ?
                                (
                                    <Link to={backLink}>
                                        <IconButton
                                            variant='plain'
                                            size='small'
                                            color='secondary'>
                                        <IoArrowBackCircleOutline />
                                        </IconButton>
                                    </Link>
                                )
                                : ""
                        }
                    </Stack>
                    <Breadcrumbs sx={{ fontSize: 12 }} aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            <Stack direction="row" spacing={1}>
                                <HomeIcon sx={{ fontSize: 16 }} />
                                <Typography
                                    sx={{ fontSize: 12 }}
                                    color="text.blue">
                                    {t("Dashboard")}
                                </Typography>
                            </Stack>
                        </Link>
                        {
                            Array.isArray(breadcrumb) && breadcrumb.length ?
                                breadcrumb.map((val, index) => {
                                    const { title, url } = val;
                                    if (!url) {
                                        return (
                                            <Typography
                                                key={index}
                                                sx={{ fontSize: 12 }}
                                                color="text.primary">
                                                {title}
                                            </Typography>
                                        );
                                    } else {
                                        return (
                                            <Link
                                                key={index}
                                                underline="hover"
                                                color="inherit"
                                                to={url}
                                            >
                                                {title}
                                            </Link>
                                        );
                                    }
                                })
                                : ""
                        }
                    </Breadcrumbs>
                </Box>
                <Stack direction="row" alignItems="center" spacing={2} sx={{
                        mt: {
                            lg: 0,
                            xs: 3
                        }
                    }}>
                        {endAction}
                </Stack>
            </Stack >
        </Box>
    );
};

export default NavHeader;
