import IndexLayout from "@/components/layout/IndexLayout";

import DashboardLayout from "@/components/layout/DashboardLayout";
import HOILayout from "@/components/layout/HOILayout";

// import ReceptionLayout from "@/components/layout/ReceptionLayout";

import Dashboard from "@/Admin/Dashboard";

import Classes from "@/Admin/Classes";
import CreateClass from "@/Admin/Classes/CreateClass";
import UpdateClass from "@/Admin/Classes/UpdateClass";

import Trainers from "@/Admin/Trainers";
import CreateTrainer from "@/Admin/Trainers/CreateTrainer";
import UpdateTrainer from "@/Admin/Trainers/UpdateTrainer";

import Sessions from "@/Admin/Sessions";
import CreateSession from "@/Admin/Sessions/CreateSession";
import CheckinSession from "@/Admin/Sessions/SessionCheckIn";
import UpdateSession from "@/Admin/Sessions/UpdateSession";

import Orders from "@/Admin/Orders";
import CreateOrder from "@/Admin/Orders/CreateOrder";
import UpdateOrder from "@/Admin/Orders/UpdateOrder";

import Users from "@/Admin/Users";
import CreateUser from "@/Admin/Users/CreateUser";
import UpdateUser from "@/Admin/Users/UpdateUser";

import Loyalty from "@/Admin/Loyalty";
import CreateLoyalty from "@/Admin/Loyalty/CreateLoyalty";
import TableLoyalty from "@/Admin/Loyalty/TableLoyalty";
import UpdateLoyalty from "@/Admin/Loyalty/UpdateLoyalty";

import MemberShips from "@/Admin/Memberships";
import CreateMemberShip from "@/Admin/Memberships/CreateMemberShip";
import UpdateMemberShip from "@/Admin/Memberships/UpdateMemberShip";

import Subscriptions from "@/Admin/Subscriptions";
import CreateSubscription from "@/Admin/Subscriptions/CreateSubscription";
import UpdateSubscription from "@/Admin/Subscriptions/UpdateSubscription";

import Promotions from "@/Admin/Promotions";
import CreatePromotion from "@/Admin/Promotions/CreatePromotion";
import UpdatePromotion from "@/Admin/Promotions/UpdatePromotion";

import Translations from "@/Admin/Translations";
import CreateTranslation from "@/Admin/Translations/CreateTranslation";
import UpdateTranslation from "@/Admin/Translations/UpdateTranslation";

import Media from "@/Admin/Media";
import UploadMedia from "@/Admin/Media/UploadMedia";

import Integrations from "@/Admin/Integrations";
import OnePay from "@/Admin/Integrations/OnePay";
import VNPAY from "@/Admin/Integrations/VNPAY";
import VietQr from "@/Admin/Integrations/VietQr";

import Account from "@/pages/Account";
import UpdateAccountInfomation from "@/pages/Account/components/UpdateAccountInfomation";
import UserOrderDetail from "@/pages/Account/components/UserOrderDetail";
import UserOrders from "@/pages/Account/components/UserOrders";
import UserSessionInformation from "@/pages/Account/components/UserSessionInformation";
import UserSessions from "@/pages/Account/components/UserSessions";
import UserSubscriptions from "@/pages/Account/components/UserSubscriptions";

import ApplyWaitingList from "@/pages/ApplyWaitingList";
import ChangePassword from "@/pages/ChangePassword";
import ErrorPage from "@/pages/ErrorPage";
import Login from "@/pages/Login";
import Register from "@/pages/Register";
import ResetPassword from "@/pages/ResetPassword";
import ResetPasswordConfirmed from "@/pages/ResetPasswordConfirmed";

import Cart from "@/pages/Cart";
import Checkout from "@/pages/Checkout";
import Payment from "@/pages/Payment";
import Renew from "@/pages/Renew";
import ThankYou from "@/pages/ThankYou";
import PaymentComplete from "./pages/Payment/PaymentComplete";
import PaymentFail from "./pages/Payment/PaymentFail";

import MemberShipsPage from "@/pages/MemberShips";
import SessionCheckin from "@/pages/SessionCheckin";
import SessionsPage from "@/pages/Sessions";
import BookSession from "@/pages/Sessions/BookSession";
import Settings from "@/pages/Settings";

/* Trainer */
import TrainerMedia from "@/Trainer/Media";
import TrainerUploadMedia from "@/Trainer/Media/UploadMedia";
import TrainerSessions from "@/Trainer/Sessions";
import TrainerCreateSession from "@/Trainer/Sessions/CreateSession";
import TrainerUpdateSession from "@/Trainer/Sessions/UpdateSession";
import TrainerInformation from "@/Trainer/TrainerInformation";
import TrainerLayout from "@/components/layout/TrainerLayout";
import SubscriptionDetailed from "./Admin/Subscriptions/components/SubscriptionDetailed";
import TrainerAnalytic from "./Admin/Trainers/TrainerAnalytic";
import CancelSessionComplete from "./pages/Account/components/CancelSessionComplete";

/* Head of instructor */
import HOIMedia from "@/HOI/Media";
import HOIUploadMedia from "@/HOI/Media/UploadMedia";
import HOISessions from "@/HOI/Sessions";
import HOICreateSession from "@/HOI/Sessions/CreateSession";
import HOIUpdateSession from "@/HOI/Sessions/UpdateSession";
import HOITrainers from "@/HOI/Trainers";
import HOICreateTrainer from "@/HOI/Trainers/CreateTrainer";
import HOIUpdateTrainer from "@/HOI/Trainers/UpdateTrainer";

import ErrorBoundary from "@/components/ErrorBoundary";
import TableUserSessions from "./Admin/Users/TableUserSessions";
import UseWaitingListInformation from "./pages/Account/components/UseWaitingLIstInformation";
import UserSessionApplyWaitingList from "./pages/Account/components/UserSessionApplyWaitingList";
import UserWaitingList from "./pages/Account/components/UserWaitingList";
import ApplyWaitingListConfirmed from "./pages/ApplyWaitingList/ApplyWaitingListConfirmed";

export const routes = [
    {
        path: "/admin",
        element: <DashboardLayout/>,
        errorElement: <ErrorBoundary />,
        children: [
            { index: true, element: <Dashboard /> , errorElement: <ErrorBoundary />},
            { path: "orders", element: <Orders />, errorElement: <ErrorBoundary />},
            { path: "orders/create", element: <CreateOrder />, errorElement: <ErrorBoundary /> },
            { path: "orders/:id", element: <UpdateOrder />, errorElement: <ErrorBoundary /> },
            { path: "media", element: <Media />, errorElement: <ErrorBoundary /> },
            { path: "media/upload", element: <UploadMedia />, errorElement: <ErrorBoundary /> },
            { path: "classes", element: <Classes />, errorElement: <ErrorBoundary /> },
            { path: "classes/create", element: <CreateClass />, errorElement: <ErrorBoundary /> },
            { path: "classes/:id", element: <UpdateClass />, errorElement: <ErrorBoundary /> },
            { path: "trainers", element: <Trainers />, errorElement: <ErrorBoundary /> },
            { path: "trainers/create", element: <CreateTrainer />, errorElement: <ErrorBoundary /> },
            { path: "trainers/:id", element: <UpdateTrainer />, errorElement: <ErrorBoundary /> },
            { path: "trainers/:id/analytics", element: <TrainerAnalytic />, errorElement: <ErrorBoundary /> },
            { path: "users", element: <Users />, errorElement: <ErrorBoundary /> },
            { path: "users/create", element: <CreateUser />, errorElement: <ErrorBoundary /> },
            { path: "users/:id", element: <UpdateUser />, errorElement: <ErrorBoundary /> },
            { path: "users/:id/sessions", element: <TableUserSessions />, errorElement: <ErrorBoundary /> },
            { path: "loyalties", element: <Loyalty />, errorElement: <ErrorBoundary /> },
            { path: "loyalties/create", element: <CreateLoyalty />, errorElement: <ErrorBoundary /> },
            { path: "loyalties/:id", element: <UpdateLoyalty />, errorElement: <ErrorBoundary /> },
            { path: "loyalties/table-loyalty", element: <TableLoyalty />, errorElement: <ErrorBoundary /> },
            { path: "sessions", element: <Sessions />, errorElement: <ErrorBoundary /> },
            { path: "sessions/create", element: <CreateSession />, errorElement: <ErrorBoundary /> },
            { path: "sessions/:id", element: <UpdateSession />, errorElement: <ErrorBoundary /> },
            { path: "sessions/:id/checkin", element: <CheckinSession />, errorElement: <ErrorBoundary /> },
            { path: "memberships", element: <MemberShips />, errorElement: <ErrorBoundary /> },
            { path: "memberships/create", element: <CreateMemberShip />, errorElement: <ErrorBoundary /> },
            { path: "memberships/:id", element: <UpdateMemberShip />, errorElement: <ErrorBoundary /> },
            { path: "subscriptions", element: <Subscriptions />, errorElement: <ErrorBoundary /> },
            { path: "subscriptions/create", element: <CreateSubscription />, errorElement: <ErrorBoundary /> },
            { path: "subscriptions/:id", element: <UpdateSubscription />, errorElement: <ErrorBoundary /> },
            { path: "subscriptions/:id/detail", element: <SubscriptionDetailed />, errorElement: <ErrorBoundary /> },
            { path: "translations", element: <Translations />, errorElement: <ErrorBoundary /> },
            { path: "translations/create", element: <CreateTranslation />, errorElement: <ErrorBoundary /> },
            { path: "translations/:id", element: <UpdateTranslation />, errorElement: <ErrorBoundary /> },
            { path: "promotions", element: <Promotions />, errorElement: <ErrorBoundary /> },
            { path: "promotions/create", element: <CreatePromotion />, errorElement: <ErrorBoundary /> },
            { path: "promotions/:id", element: <UpdatePromotion />, errorElement: <ErrorBoundary /> },
            { path: "integrations", element: <Integrations />, errorElement: <ErrorBoundary /> },
            { path: "integrations/viet-qr", element: <VietQr />, errorElement: <ErrorBoundary /> },
            { path: "integrations/onepay", element: <OnePay />, errorElement: <ErrorBoundary /> },
            { path: "integrations/vnpay", element: <VNPAY />, errorElement: <ErrorBoundary /> },
        ],
    },
    { path: "/login", element: <Login />, errorElement: <ErrorBoundary /> },
    { path: "/register", element: <Register />, errorElement: <ErrorBoundary /> },
    { path: "/reset-password", element: <ResetPassword />, errorElement: <ErrorBoundary /> },
    { path: "/reset-password/confirmed", element: <ResetPasswordConfirmed />, errorElement: <ErrorBoundary /> },
    { path: "/change-password/:token", element: <ChangePassword />, errorElement: <ErrorBoundary /> },
    { path: "*", element: <ErrorPage />, errorElement: <ErrorBoundary /> },
    {
        path: "/",
        element: <IndexLayout />,
        children: [
            { index: true, element: <SessionsPage />, errorElement: <ErrorBoundary /> },
            { path: "sessions/:id", element: <BookSession />, errorElement: <ErrorBoundary /> },
            { path: "waiting-list/:id", element: <UserSessionApplyWaitingList />, errorElement: <ErrorBoundary /> },
            { path: "memberships", element: <MemberShipsPage />, errorElement: <ErrorBoundary /> },
            { path: "settings", element: <Settings />, errorElement: <ErrorBoundary /> },
            { path: "cart", element: <Cart />, errorElement: <ErrorBoundary /> },
            { path: "renew/subscription/:id", element: <Renew />, errorElement: <ErrorBoundary /> },
            { path: "checkout", element: <Checkout />, errorElement: <ErrorBoundary /> },
            { path: "thank-you/:id", element: <ThankYou />, errorElement: <ErrorBoundary /> },
            { path: "payment/:id", element: <Payment />, errorElement: <ErrorBoundary /> },
            { path: "payment/success", element: <PaymentComplete />, errorElement: <ErrorBoundary /> },
            { path: "payment/failed", element: <PaymentFail />, errorElement: <ErrorBoundary /> },
            { path: "session-checkin", element: <SessionCheckin />, errorElement: <ErrorBoundary /> },
            { path: "*", element: <ErrorPage />, errorElement: <ErrorBoundary /> },
            {
                path: "/account",
                element: <Account />,
                children: [
                    { index: true, element: <UserSessions />, errorElement: <ErrorBoundary /> },
                    { path: "waiting-list", element: <UserWaitingList />, errorElement: <ErrorBoundary /> },
                    { path: "user-information", element: <UpdateAccountInfomation />, errorElement: <ErrorBoundary /> },
                    { path: "orders", element: <UserOrders />, errorElement: <ErrorBoundary /> },
                    { path: "orders/:id", element: <UserOrderDetail />, errorElement: <ErrorBoundary /> },
                    { path: "subscriptions", element: <UserSubscriptions />, errorElement: <ErrorBoundary /> },
                ]
            },
            { path: "/account/waiting-list/:id", element: <UseWaitingListInformation />, errorElement: <ErrorBoundary /> },
            { path: "/account/sessions/:id", element: <UserSessionInformation />, errorElement: <ErrorBoundary /> },
            { path: "/account/sessions/:id/canceled", element: <CancelSessionComplete />, errorElement: <ErrorBoundary /> },
            
        ],
    },
    { path: "/login", element: <Login />, errorElement: <ErrorBoundary /> },
    { path: "/register", element: <Register />, errorElement: <ErrorBoundary /> },
    { path: "/reset-password", element: <ResetPassword />, errorElement: <ErrorBoundary /> },
    { path: "/reset-password/confirmed", element: <ResetPasswordConfirmed />, errorElement: <ErrorBoundary /> },
    { path: "/change-password/:token", element: <ChangePassword />, errorElement: <ErrorBoundary /> },
    { path: "/apply-waiting-list/:apply_token", element: <ApplyWaitingList />, errorElement: <ErrorBoundary /> },
    { path: "/apply-waiting-list/confirmed", element: <ApplyWaitingListConfirmed />, errorElement: <ErrorBoundary /> },
    {
        path: "/trainer",
        element: <TrainerLayout />,
        children: [
            { path: "media", element: <TrainerMedia />, errorElement: <ErrorBoundary /> },
            { path: "media/upload", element: <TrainerUploadMedia />, errorElement: <ErrorBoundary /> },
            { path: "sessions", element: <TrainerSessions />, errorElement: <ErrorBoundary /> },
            { path: "sessions/create", element: <TrainerCreateSession />, errorElement: <ErrorBoundary /> },
            { path: "sessions/:id", element: <TrainerUpdateSession />, errorElement: <ErrorBoundary /> },
            { path: "information", element: <TrainerInformation />, errorElement: <ErrorBoundary /> },
        ]
    },
    {
        path: "/hoi",
        element: <HOILayout />,
        children: [
            { path: "media", element: <HOIMedia />, errorElement: <ErrorBoundary /> },
            { path: "media/upload", element: <HOIUploadMedia />, errorElement: <ErrorBoundary /> },
            { path: "trainers", element: <HOITrainers />, errorElement: <ErrorBoundary /> },
            { path: "trainers/create", element: <HOICreateTrainer />, errorElement: <ErrorBoundary /> },
            { path: "trainers/:id", element: <HOIUpdateTrainer />, errorElement: <ErrorBoundary /> },
            { path: "sessions", element: <HOISessions />, errorElement: <ErrorBoundary /> },
            { path: "sessions/create", element: <HOICreateSession />, errorElement: <ErrorBoundary /> },
            { path: "sessions/:id", element: <HOIUpdateSession />, errorElement: <ErrorBoundary /> },
        ]
    },
];