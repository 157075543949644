import { instance } from "./axiosInstance";

export async function findManyPromotions(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/promotions", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOnePromotion(id) {
    return await instance
        .get(`/promotions/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function findOnePromotionByTitle(promotion_code) {
    return await instance
        .get(`/promotions/code/${promotion_code}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createPromotion(data) {
    return await instance
        .post("/promotions", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updatePromotion(id, data) {
    return await instance
        .patch("/promotions/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deletePromotion(id) {
    return await instance
        .delete("/promotions/" + id)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function deletePromotions(data) {
    const config = {
        data: {
            ids: data
        }
    };
    return await instance
        .delete("/promotions", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}