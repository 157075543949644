import { Autocomplete, Box, Grid } from "@mui/material";
import { useState } from "react";
import { findManyTrainers } from "@/utils/Trainers";
import { useToasterContext } from "@/utils/ToasterContext";

import NavHeader from "@/components/NavHeader";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import validate from "validate.js";

import { Countries } from "@/utils/Countries";
import { LoadingButton } from "@mui/lab";
import { createUser } from "@/utils/Users";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import PostPicker from "@/components/PostPicker";

const CreateClass = () => {

    const { t } = useTranslation();
    
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [role, setRole] = useState(false);
    const [trainer, setTrainer] = useState(false);

    const navigate = useNavigate();

    async function handleCreate(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await createUser(data);
            if (response && !response.error) {
                const { data } = response;
                showMessage("success", "Data created successfully!");
                if (data?.id) {
                    navigate("/admin/users/" + data.id);
                }
            } else {
                showMessage("error", "An error occurred, please try again later");
            }
        } catch (error) {
            showMessage("error", "An error occurred, please try again later");
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }


    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let dataForm = {
            first_name: data.get("first_name"),
            last_name: data.get("last_name"),
            email: data.get("email"),
            role: data.get("role"),
            password: data.get("password"),
            date_of_birth: dayjs(data.get("date_of_birth"), "DD/MM/YYYY").format("YYYY-MM-DD"),
        };
        if(role == "trainer"){
            dataForm = {
                ...dataForm,
                trainer_id: trainer?.id
            };
        }
        const error = validate(dataForm, constraints);
        
        if (!error) {
            handleCreate(dataForm);
        }
        setFormError(error);
    }

    var roles = [
        { "label": "Administrator", "value": "administrator" },
        { "label": "Receptionist", "value": "receptionist" },
        { "label": "Head of instructor", "value": "head_of_instructor" },
        { "label": "Instructor", "value": "instructor" },
        { "label": "User", "value": "user" },
    ];

    let constraints = {
        first_name: {
            presence: { allowEmpty: false },
        },
        last_name: {
            presence: { allowEmpty: false },
        },
        email: {
            email: true
        },
        role: {
            presence: { allowEmpty: false }
        },
        password: {
            presence: { allowEmpty: false }
        }
    };

    if(role == "trainer"){
        constraints = {
            ...constraints,
            trainer_id: {
                presence: { allowEmpty: false },
            },
        };
    }

    return (
            <Box sx={{ width: "100%" }}>
                <NavHeader
                    title="Create user"
                    breadcrumb={[
                        {
                            title: "Create User"
                        }
                    ]}
                    backLink="/admin/users"
                />
                <Box  component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                        <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.first_name} required>
                                        <FormLabel>{t("First name")}</FormLabel>
                                        <TextField
                                            name="first_name"
                                            error={formError?.first_name}
                                        />
                                        {formError?.first_name ? <FormHelperText>{formError?.first_name[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.last_name} required>
                                        <FormLabel>{t("Last name")}</FormLabel>
                                        <TextField
                                            name="last_name"
                                            error={formError?.last_name}
                                        />
                                        {formError?.last_name ? <FormHelperText>{formError?.last_name[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.date_of_birth} required>
                            <FormLabel>{t("Date of birth")}</FormLabel>
                                <DatePicker
                                    name="date_of_birth"
                                    required
                                    value={dayjs()}
                                />
                                {formError?.date_of_birth ? <FormHelperText>{formError?.date_of_birth[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ mb: 3, width: "100%" }} error={formError?.role} required>
                                <FormLabel id="demo-multiple-name-label">User role</FormLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="role"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    input={<OutlinedInput label="" />}>
                                    {roles.map((val, index) => (
                                        <MenuItem
                                            key={index}
                                            value={val.value}>
                                            {val.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formError?.role ? <FormHelperText>{formError?.role[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_phone} required>
                                        <FormLabel>{t("Phone number")}</FormLabel>
                                        <TextField
                                            name="billing_phone"
                                        />
                                        {formError?.billing_phone ? <FormHelperText>{formError?.billing_phone[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.email} required>
                                        <FormLabel>Email</FormLabel>
                                        <TextField
                                            name="email"
                                            type="email"
                                            error={formError?.email}
                                        />
                                        {formError?.email ? <FormHelperText>{formError?.email[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_country}>
                                <FormLabel>{t("Country")}</FormLabel>
                                <Autocomplete
                                    options={Countries}
                                    name="billing_country"
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                name="billing_country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                }}
                                            />
                                        );
                                    }}
                                />
                                {formError?.billing_country ? <FormHelperText>{formError?.billing_country[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_city}>
                                <FormLabel>{t("City")}</FormLabel>
                                <TextField
                                    name="billing_city"
                                    type='text'
                                />
                                {formError?.billing_city ? <FormHelperText>{formError?.billing_city[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_district}>
                                <FormLabel>{t("District")}</FormLabel>
                                <TextField
                                    name="billing_district"
                                    type='text'
                                />
                                {formError?.billing_district ? <FormHelperText>{formError?.billing_district[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_ward}>
                                <FormLabel>{t("Ward")}</FormLabel>
                                <TextField
                                    name="billing_ward"
                                    type='text'
                                />
                                {formError?.billing_ward ? <FormHelperText>{formError?.billing_ward[0]}</FormHelperText> : ""}
                            </FormControl>
  
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_address}>
                                <FormLabel>{t("Address")}</FormLabel>
                                <TextField
                                    name="billing_address"
                                    type='text'
                                />
                                {formError?.billing_address ? <FormHelperText>{formError?.billing_address[0]}</FormHelperText> : ""}
                            </FormControl>
                            {
                                role == "trainer" ? (
                                    <Box sx={{ mb: 1 }}>
                                        <PostPicker
                                            label={t("Select intructor")}
                                            name="user"
                                            defaultData={trainer}
                                            values={[trainer]}
                                            getApi={findManyTrainers}
                                            onChange={(value) => setTrainer((value))}
                                            error={formError?.trainer_id}
                                            optionKey="full_name"
                                            previewKey="email"
                                        />
                                    </Box>
                                ): ""
                            }
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.password} required>
                                <FormLabel>User password</FormLabel>
                                <TextField
                                    name="password"
                                    type="text"
                                    error={formError?.password}
                                />
                                {formError?.password ? <FormHelperText>{formError?.password[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                    {t("Create user")}
                                </LoadingButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    );
};

export default CreateClass;
