
import { useToasterContext } from "@/utils/ToasterContext";
import { findOneTranslation, updateTranslation } from "@/utils/Translations";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import validate from "validate.js";

import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

const UpdateTranslationPage = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [defaultValue, setDefaultValue] = useState(false);
    const [formError, setFormError] = useState(false);

    const [platforms, setPlatforms] = useState([]);

    const navigate = useNavigate();

    async function handleFindOne() {
        try {
            const response = await findOneTranslation(id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        text_value ,
                        text_translated,
                        platforms
                    } = data;
                    if(Array.isArray(platforms) && platforms?.length){
                        setPlatforms(platforms); 
                    }
                    setDefaultValue({
                        text_value,
                        text_translated,
                    });
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    async function handleUpdate(id, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updateTranslation(id, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage("success", "Data updated successfully!");
                if (data?.id) {
                    navigate("/admin/translations/" + data.id);
                }
            } else {
                showMessage("error", response?.data?.message || t("An error occurred, please try again later!"));
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try again later!"));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const dataForm = {
            text_value: data.get("text_value"),
            text_translated: data.get("text_translated"),
            platforms: platforms
        };

        const error = validate(dataForm, constraints);

        if (!error) {
            handleUpdate(id, dataForm);
        } else {
            console.log("error", error);
            showMessage("error", t("One or more input fields are invalid!"));
        }

        setFormError(error);
    }

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        setPlatforms((platforms) => {
          if (platforms.includes(value)) {
            return platforms.filter((item) => item !== value);
          } else {
            return [...platforms, value];
          }
        });
      };

    const constraints = {
        text_value: {
            presence: { allowEmpty: false }
        },
        text_translated: {
            presence: { allowEmpty: false }
        }
    };

    useEffect(() => {
        handleFindOne();
    }, []);


    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <NavHeader
                title={t("Update translation")}
                breadcrumb={[
                    {
                        title: t("Translations"),
                        link: "/admin/translations/"
                    },
                    {
                        title: t("Update Translation")
                    }
                ]}
                endAction={(
                    <Link to="/admin/translations/create">
                        <Button variant='contained'>
                           {t("Add translation")}
                        </Button>
                    </Link>
                )}
                backLink="/admin/translations"
            />
            <Box component="form" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.text_value} required>
                            <FormLabel>{t("Text value")}</FormLabel>
                            <TextField
                                name="text_value"
                                rows={5}
                                multiline
                                type="text"
                                defaultValue={defaultValue?.text_value}
                                error={formError?.text_value}
                            />
                            {formError?.text_value ? <FormHelperText>{formError?.text_value[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.text_translated} required>
                            <FormLabel>{t("Translated")}</FormLabel>
                            <TextField
                                name="text_translated"
                                rows={5}
                                multiline
                                type="text"
                                error={formError?.text_translated}
                                defaultValue={defaultValue?.text_translated}
                            />
                            {formError?.text_translated ? <FormHelperText>{formError?.text_translated[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.text_translated} required>
                            <FormLabel>{t("Platforms")}</FormLabel>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox value="website" defaultChecked={platforms.includes("website")} onChange={handleCheckboxChange} />} label="Website (Booking App & Dasboard)" />
                                <FormControlLabel control={<Checkbox value="mobile" defaultChecked={platforms.includes("mobile")} onChange={handleCheckboxChange} />} label="Mobile App (IOS, Android)" />
                            </FormGroup>
                        </FormControl>
                        <FormControl>
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                size='lg'
                                type='submit'
                                loading={isLoading}>
                                {t("Update translation")}
                            </LoadingButton>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default UpdateTranslationPage;
