import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import { store } from "./utils/store";

const container = document.getElementById("root");
const root = createRoot(container);

const Document = () => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    );
};

root.render(<Document />);
