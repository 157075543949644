import { numberWithCommas } from "@/utils/Hooks";
import { createOrder } from "@/utils/Orders";
import { useToasterContext } from "@/utils/ToasterContext";
import { Autocomplete, Box, Button, Card, CardContent, Grid, List, ListItem, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import NavHeader from "@/components/NavHeaderHome";
import PageLoading from "@/components/PageLoading";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import validate from "validate.js";

import { Countries } from "@/utils/Countries";
import { findOnePromotionByTitle } from "@/utils/Promotions";
import { updateCartData } from "@/utils/slice/cartSlice";
import { findUserInformation } from "@/utils/UserInfomations";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const Index = () => {

    
    const { cart } = useSelector((state) => state.cart);
    
    const dispatch = useDispatch();

    const { showMessage, startProgress, completeProgress, currentLanguage } = useToasterContext();
    const [cartTotal, setCartTotal] = useState(0);
    const [pageStatus, setPageStatus] = useState("idle");
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [defaultData, setDefaultData] = useState(false);
    const [paymentType, setPaymentType] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("vietqr");

    const [promotion, setPromotion] = useState(false);
    const [promotionCode, setPromotionCode] = useState("");
    const [promotionAmount, setPromotionAmount] = useState(0);
    const [loadingPromotion, setLoadingPromotion] = useState(false);

    const { t } = useTranslation();

    const navigate = useNavigate();

    async function handleCreate(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await createOrder(data);
            if (response && !response.error) {
                const { data } = response;
                if (data?.id) {
                    showMessage("success", "Order data created successfully");
                    dispatch(updateCartData([]));
                    navigate("/account/orders/" + data.id);
                }
            }
        } catch (error) {
            showMessage("error", "An error occurred, please try again later");
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleFindUserInfo() {
        try {
            const response = await findUserInformation();
            if(response && !response.error){
                const { data } = response;
                if (data) {
                    setDefaultData(data);
                }
            }
        } catch (error) {
            console.error("error", error);
        }
    }

    async function handleFindPromotion(promotion_code) {
        try {
            setLoadingPromotion(true);
            const response = await findOnePromotionByTitle(promotion_code);
            if(response && !response.error){
                const { data } = response;
                if (data) {
                    const { memberships } = data;
                    let validated = false;
                    if(Array.isArray(cart) && cart?.length) {
                        cart.map((val) => {
                            if(memberships.find((_v) => _v.id == val.membership_id)){
                                validated = true;
                            }
                        });
                        if(validated){
                            showMessage("success", t("Promotion code successfully applied!"));
                            setPromotion(data);
                        } else {
                            showMessage("error", t("This code is not valid."));
                            setPromotion(false);
                        }
                    } else {
                        showMessage("error", t("This code is not valid."));
                        setPromotion(false);
                    }
                } else {
                    showMessage("error", t("This code is not valid."));
                    setPromotion(false);
                }
            } else {
                showMessage("error", t("This code is not valid."));
                setPromotion(false);
            }
        } catch (error) {
            showMessage("error", t("This code is not valid."));
            setPromotion(false);
            console.error("error", error);
        } finally {
            setLoadingPromotion(false);
        }
    }


    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            billing_name: data.get("billing_name"),
            billing_phone: data.get("billing_phone"),
            billing_email: data.get("billing_email"),
            billing_country: data.get("billing_country"),
            billing_city: data.get("billing_city"),
            billing_district: data.get("billing_district"),
            billing_ward: data.get("billing_ward"),
            billing_portal_code: data.get("billing_portal_code"),
            billing_address: data.get("billing_address"),
            promotion_id: promotion?.id,
            promotion_amount: promotionAmount,
            payment_method: paymentMethod,
            payment_type: paymentType,
            language: currentLanguage,
            cart: cart,
        };
        const error = validate(dataForm, constraints);
        if (!error) {
            handleCreate(dataForm);
        }
        setFormError(error);
    }

    useEffect(() => {
        let totalPrice = 0;
        let promotionPrice = 0;
        
        if (Array.isArray(cart) && cart?.length) {
            cart.map((data) => {
                let validatePromotion = false;
                let promotionAmount = 0;

                if(promotion){
                    const { for_all_memberships, promotion_amount, memberships } = promotion;
                    promotionAmount = promotion_amount;
                    if(!for_all_memberships){
                        if(Array.isArray(memberships) && memberships?.length) {
                            validatePromotion = memberships.find((val) => val.id == data?.memberships?.id);
                        }
                    } else {
                        validatePromotion = true;
                    }
                }
            
                const { memberships, quantity } = data; 
                const { regular_price, loyalties } = memberships;
                
                let loyalty = null;

                if(Array.isArray(loyalties) && loyalties?.length){
                    loyalty = loyalties[0];
                }

                let LoyaltyDiscount = 0;
                let PromotionDiscount = 0;

                let Subtotal = regular_price * quantity;

                if(loyalty) {
                    const discount_percent = loyalty?.pivot?.discount_percent;
                    LoyaltyDiscount = Subtotal * parseInt(discount_percent) / 100;
                    Subtotal -= LoyaltyDiscount;
                }

                if(validatePromotion){
                    PromotionDiscount = Subtotal * parseInt(promotionAmount) / 100;
                    Subtotal -= PromotionDiscount;
                }

                promotionPrice += PromotionDiscount;
                totalPrice += Subtotal;
            });
        }
        setCartTotal(totalPrice);
        setPromotionAmount(promotionPrice);
    }, [cart, promotion]);

    useEffect(() => {
        async function initData() {
            await handleFindUserInfo();
            setPageStatus("complete");
        }
        initData();
    }, []);

    const constraints = {
        billing_name: {
            presence: { 
                allowEmpty: false,
                message: t("^Your name can't be blank")
            },
        },
        billing_phone: {
            presence: { 
                allowEmpty: false,
                message: t("^Phone number can't be blank")
            },
        },
        billing_email: {
            presence: { 
                allowEmpty: false,
                message: t("^Your Email can't be blank")
             },
            email: true
        }
    };

    const CartItem = ({ data, promotion }) => {
        {

            let validatePromotion = false;
            let promotionAmount = 0;

            if(promotion){
                const { for_all_memberships, promotion_amount, memberships } = promotion;
                promotionAmount = promotion_amount;
                if(!for_all_memberships){
                    if(Array.isArray(memberships) && memberships?.length) {
                        validatePromotion = memberships.find((val) => val.id == data?.memberships?.id);
                    }
                } else {
                    validatePromotion = true;
                }
            }
        
            const { memberships, quantity } = data; 
            const { id, title, regular_price, loyalties } = memberships;
            
            let loyalty = null;

            if(Array.isArray(loyalties) && loyalties?.length){
                loyalty = loyalties[0];
            }

            let LoyaltyDiscount = 0;
            let PromotionDiscount = 0;

            let Subtotal = regular_price * quantity;

            if(loyalty) {
                const discount_percent = loyalty?.pivot?.discount_percent;
                LoyaltyDiscount = Subtotal * parseInt(discount_percent) / 100;
                Subtotal -= LoyaltyDiscount;
            }

            if(validatePromotion){
                PromotionDiscount = Subtotal * parseInt(promotionAmount) / 100;
                Subtotal -= PromotionDiscount;
            }

            return (
                <ListItem
                    key={id}
                    className={"border-b border-dashed border-slate-200 !py-5"}>
                    <Box sx={{ flex: 1 }}>
                        <h3 className='font-bold text-lg'>
                            {t(title)} x {quantity}
                        </h3>
                        <div className="text-sm">
                            <p>
                                {t("Subtotal")}: {regular_price ? numberWithCommas(regular_price) + "₫" : ""} 
                            </p>
                            {
                                loyalty ? (
                                    <div className="text-sm text-red-500">
                                        -{numberWithCommas(LoyaltyDiscount) + "₫"} <span className="text-gray-600">({loyalty?.title})</span>
                                    </div>
                                ) : ""
                            }
                            {
                                validatePromotion ? (
                                    <div className="text-sm text-red-500">
                                        -{numberWithCommas(PromotionDiscount) + "₫"} <span className="text-gray-600">({promotion?.promotion_code})</span>
                                    </div>
                                ) : ""
                            }
                            <p>
                                <strong className='mr-2'>{t("Total")}: {numberWithCommas(Subtotal)}₫</strong>
                            </p>
                        </div>
                    </Box>
                </ListItem>
            );

        }
    };

    if (pageStatus != "complete") {
        return (
            <PageLoading />
        );
    }

    return (
            <Box sx={{ width: "100%" }}>
                <NavHeader
                    title={t("Checkout")}
                    breadcrumb={[
                        {
                            title: t("Checkout")
                        }
                    ]}
                    backLink="/cart"
                />
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} lg={8}>
                            <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                {t("Billing information")}
                            </Typography>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_name} required>
                                <FormLabel>{t("Your full name")}</FormLabel>
                                <TextField
                                    name="billing_name"
                                    error={formError?.billing_name}
                                    defaultValue={defaultData?.billing_name}
                                />
                                {formError?.billing_name ? <FormHelperText>{formError?.billing_name[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_phone} required>
                                        <FormLabel>{t("Phone number")}</FormLabel>
                                        <TextField
                                            name="billing_phone"
                                            defaultValue={defaultData?.billing_phone}
                                        />
                                        {formError?.billing_phone ? <FormHelperText>{formError?.billing_phone[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_email} required>
                                        <FormLabel>{t("Email address")}</FormLabel>
                                        <TextField
                                            name="billing_email"
                                            defaultValue={defaultData?.billing_email}
                                        />
                                        {formError?.billing_email ? <FormHelperText>{formError?.billing_email[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_country}>
                                <FormLabel>{t("Country")}</FormLabel>
                                <Autocomplete
                                    options={Countries}
                                    name="billing_country"
                                    defaultValue={defaultData?.billing_country}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                name="billing_country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                }}
                                            />
                                        );
                                    }}
                                />
                                {formError?.billing_country ? <FormHelperText>{formError?.billing_country[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_city}>
                                <FormLabel>{t("City")}</FormLabel>
                                <TextField
                                    name="billing_city"
                                    defaultValue={defaultData?.billing_city}
                                />
                                {formError?.billing_city ? <FormHelperText>{formError?.billing_city[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_district}>
                                <FormLabel>{t("District")}</FormLabel>
                                <TextField
                                    name="billing_district"
                                    defaultValue={defaultData?.billing_district}
                                />
                                {formError?.billing_district ? <FormHelperText>{formError?.billing_district[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_district}>
                                <FormLabel>{t("Ward")}</FormLabel>
                                <TextField
                                    name="billing_ward"
                                    defaultValue={defaultData?.billing_ward}
                                />
                                {formError?.billing_ward ? <FormHelperText>{formError?.billing_ward[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_address}>
                                <FormLabel>{t("Address")}</FormLabel>
                                <TextField
                                    name="billing_address"
                                    defaultValue={defaultData?.billing_address}
                                />
                                {formError?.billing_address ? <FormHelperText>{formError?.billing_address[0]}</FormHelperText> : ""}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                {t("Cart")}
                            </Typography>
                            <Card variant='outlined' sx={{ mb: 3, borderColor: "#000", borderRadius: 0 }}>
                                <CardContent>
                                    {
                                        Array.isArray(cart) && cart?.length ? (
                                            <Fragment>
                                                <Box>
                                                    <List>
                                                        {
                                                            cart.map((val, index) => (
                                                                <CartItem key={index} data={val} promotion={promotion}/>
                                                            ))
                                                        }
                                                    </List>
                                                </Box>
                                                {
                                                    promotion ? (
                                                        <Fragment>
                                                            <Typography sx={{ mt: 2 }}>
                                                                {t("Promotion")}:
                                                                <strong className='ml-2'>{promotion?.promotion_code}</strong>
                                                            </Typography>
                                                            
                                                        </Fragment>
                                                    ) : ""
                                                }
                                                <p className='text-lg text-center font-bold'>
                                                    {t("Subtotal")}:
                                                    <span className='ml-2'>{numberWithCommas(cartTotal)}₫</span>
                                                </p>
                                            </Fragment>
                                        ) : (
                                            <Box sx={{ textAlign: "center", py: 3 }}>
                                                <AddShoppingCartIcon sx={{ width: 80, height: 80, mb: 1 }} />
                                                <Typography sx={{ mb: 3 }}>{t("Cart empty")}</Typography>
                                                <Link to="/memberships/">
                                                    <Button variant='contained' color="secondary">
                                                        {t("Return to memberships")}
                                                    </Button>
                                                </Link>
                                            </Box>
                                        )
                                    }
                                </CardContent>
                            </Card>
                            <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                {t("Promotion")}
                            </Typography>
                            <div className="flex gap-1">
                                <TextField
                                    name="promotion"
                                    value={promotionCode}
                                    onChange={(e) => setPromotionCode(e.target.value)}
                                    placeholder={t("Your promotion code")}
                                    sx={{flex: 1}}
                                />
                                <LoadingButton 
                                    variant='outlined' 
                                    type="button"
                                    loading={loadingPromotion}
                                    onClick={() => handleFindPromotion(promotionCode)}
                                    sx={{ borderRadius: 0}}>
                                        {t("Apply")}
                                </LoadingButton>
                            </div>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='secondary'
                                    size='lg'
                                    type='submit'
                                    sx={{
                                        padding: "10px 24px",
                                        borderRadius: 0,
                                        display: "block",
                                        mt: 2,
                                        mb: 2,
                                        textAlign: "center",
                                        letterSpacing: 2
                                    }}
                                    loading={isLoading}>
                                    {t("Proceed to payment")}
                                </LoadingButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    );
};

export default Index;
