import { instance } from "./axiosInstance";

export async function findManyUsers(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/users", config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}


export async function findOneUser(id) {
    return await instance
        .get(`/users/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function findManyUserSessions(id, params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/users/" + id + "/sessions", config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createUser(data) {
    return await instance
        .post("/users", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateUser(id, data) {
    return await instance
        .patch("/users/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteUser(id) {
    return await instance
        .delete("/users/" + id)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return {
                error: true,
                data: err
            };
        });
}


export async function deleteUsers(data) {
    const config = {
        data: data
    };
    return await instance
        .delete("/users", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return {
                error: true,
                data: err
            };
        });
}
