import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const ErrorPage = () => {
    return (
        <Box sx={{ textAlign: "center", py: 3 }}>
            <Typography sx={{ fontSize: 88, fontWeight: 600 }}>404 ERROR</Typography>
            <Typography sx={{ mb: 3 }}>Page not found!</Typography>
            <Link to="/">
                <Button variant='contained' color="secondary">
                    Return home
                </Button>
            </Link>
        </Box>
    );
};

export default ErrorPage;
