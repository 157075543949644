import { instance } from "./axiosInstance";

export async function findManySessions(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/trainer/sessions", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneSession(id) {
    return await instance
        .get(`/trainer/sessions/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function findOneSessionCheckin(id) {
    return await instance
        .get(`/trainer/sessions/checkin/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function TrainerNoShowUser(id) {
    return await instance
        .post(`/trainer/sessions/no-show/${id}`, {})
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function TrainerCheckInUser(id) {
    return await instance
        .post(`/trainer/sessions/checkin/${id}`, {})
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function TrainerCheckOutUser(id) {
    return await instance
        .post(`/trainer/sessions/checkout/${id}`, {})
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createSession(data) {
    return await instance
        .post("/trainer/sessions", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateSession(id, data) {
    return await instance
        .put("/trainer/sessions/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteSession(id) {
    const config = {
        data: {
            id: id
        }
    };
    return await instance
        .delete("/trainer/sessions", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}