import { instance } from "./axiosInstance";

export async function findManyCarts() {
    return await instance
        .get("/cart")
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneCart(id) {
    return await instance
        .get(`/cart/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function findOneCartBySubscription(id) {
    return await instance
        .get(`/cart/renew/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createCart(data) {
    return await instance
        .post("/cart", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteCart(id) {
    return await instance
        .delete("/cart/" + id)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function deleteCarts(data) {
    const config = {
        data: {
            ids: data
        }
    };
    return await instance
        .delete("/cart", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}