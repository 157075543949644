import { getClassSessionAnalytics, getOrderAnalytics, getSessionAnalytics, getTrainerAnalytics } from "@/utils/Analytics";
import { LoadingButton } from "@mui/lab";
import { Box, FormControl, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import NavHeader from "@/components/NavHeader";
import Grid from "@mui/material/Grid";
import BillingTotal from "./BillingTotal";
import ClasssSession from "./ClassSession";
import Orders from "./Orders";
import OrderTotal from "./OrderTotal";
import SessionChart from "./SessionChart";
import TrainerTable from "./TrainerTable";

import dayjs from "dayjs";

export default function Dashboard() {

    const [dataOrder, setDataOrder] = useState(false);
    const [sessionClassChart, setSessionClassChart] = useState(false);
    const [trainerChart, setTrainerChart] = useState(false);
    const [sessionChartData, setSessionChartData] = useState(false);

    const { t } = useTranslation();
    
    const [filter, setFilter] = useState({
        start_date: dayjs().startOf("year").format("DD/MM/YYYY"),
        end_date: dayjs().endOf("year").format("DD/MM/YYYY"),
    });

    async function handleGetOrderChart(filter) {
        try {
            const response = await getOrderAnalytics(filter);
            if(response && !response.error){
                setDataOrder(response);
            }
        } catch (error) {
            console.error("error", error);
        }
    } 

    async function handleGetTrainerChart(filter) {
        try {
            const response = await getTrainerAnalytics({ ...filter });
            if(response && !response.error){
                setTrainerChart(response);
            }
        } catch (error) {
            console.error("error", error);
        }
    }

    async function handleGetClassSessionChart(filter) {
        try {
            const response = await getClassSessionAnalytics(filter);
            if(response && !response.error){
                setSessionClassChart(response);
            }
        } catch (error) {
            console.error("error", error);
        }
    }

    async function handleGetSessionChart(filter) {
        try {
            const response = await getSessionAnalytics(filter);
            if(response && !response.error){
                setSessionChartData(response);
            }
        } catch (error) {
            console.error("error", error);
        }
    }

    function handleFilter(){
        handleGetSessionChart(filter);
        handleGetOrderChart(filter);
        handleGetClassSessionChart(filter);
        handleGetTrainerChart(filter);
    }

    useEffect(() => {
        handleGetSessionChart(filter);
        handleGetOrderChart(filter);
        handleGetClassSessionChart(filter);
        handleGetTrainerChart(filter);
    }, []);

    const handleChangeDate = (name, value) => {
        setFilter((filter) => (
            {
                ...filter,
                [name]: value ? dayjs(value).format("DD/MM/YYYY") : null
            }
        ));
    };

    return (
        <Fragment>
            <NavHeader
                title={"Dashboard"}
                breadcrumb={[
                    {
                        title: t("Home")
                    }
                ]}
            />
            <Stack direction="row" justifyContent="flex-end" sx={{ display: "flex", columnGap: 3, mb: 3 }}>
                <FormControl>
                    <DatePicker
                        label={t("Start date")}
                        value={filter?.start_date ? dayjs(filter.start_date, "DD/MM/YYYY") : null}
                        onChange={(value) => handleChangeDate("start_date", value)}
                        sx={{ width: 220,  background: "#fff", }}
                    />
                </FormControl>
                <FormControl>
                    <DatePicker
                        label={t("End date")}
                        value={filter?.end_date ? dayjs(filter.end_date, "DD/MM/YYYY") : null}
                        onChange={(value) => handleChangeDate("end_date", value)}
                        sx={{ width: 220,  background: "#fff" }}
                    />
                </FormControl>
                <FormControl>
                    <LoadingButton 
                        variant='contained' 
                        sx={{borderRadius: 0, height: "100%"}} 
                        onClick={() => handleFilter()}>
                        {t("Filter")}
                    </LoadingButton>
                </FormControl>
            </Stack>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <div className='bg-white p-3 rounded border border-slate-200 shadow-0'>
                            <BillingTotal chart={dataOrder}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <div className='bg-white p-3 rounded border border-slate-200 shadow-0'>
                            <OrderTotal chart={dataOrder}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <div className='bg-white p-3 rounded border border-slate-200 shadow-0'>
                            <SessionChart chart={sessionChartData}/>
                        </div>
                    </Grid>
                    {/* Chart */}
                    <Grid item xs={12} lg={4}>
                        <div className='bg-white p-3 rounded border border-slate-200 shadow-0 h-full'>
                            <ClasssSession chart={sessionClassChart}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                         <div className='bg-white p-3 rounded border border-slate-200 shadow-0 h-full'>
                            <h3 className='font-[900] px-3'>{t("Trainer analytics")}</h3>
                            <TrainerTable data={trainerChart}/>
                        </div>
                    </Grid>
                    {/* Recent Orders */}
                    <Grid item xs={12} md={8} lg={12}>
                        <div className='bg-white p-3 rounded border border-slate-200 shadow-0'>
                            <h3 className='font-[900] px-3'>{t("Lastest orders")}</h3>
                            <Orders />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
}