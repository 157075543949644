import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./slice/AuthSlice";
import CartReducer from "./slice/cartSlice";
import NotifyReducer from "./slice/NotifySlice";

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        cart: CartReducer,
        notify: NotifyReducer
    },
});