import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Chip, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { formatBytes } from "../utils/Hooks";

const APP_URL = process.env.REACT_APP_APP_URL;

export default function MediaCard({ data, setDeleteMedia }) {

    const { id, file_name, mime_type, file_size, file_path } = data;

    return (
        <Card className='relative' sx={{ maxWidth: 345 }}>
            <div className='absolute top-1 right-1 z-10'>
                <IconButton variant="contained" size='sm' onClick={() => setDeleteMedia(id)}>
                    <DeleteOutlineIcon />
                </IconButton>
            </div>
            <CardMedia
                sx={{
                    width: "100%",
                    height: 160,
                    objectFit: "cover"
                }}
                image={APP_URL + "/" + file_path}
                title={file_name}
            />
            <CardContent>
                <Typography className='line-clamp-1' gutterBottom variant="p" component="div">
                    {file_name}
                </Typography>
            </CardContent>
            <CardActions>
                <Chip size='sm' label={formatBytes(file_size)} />
                <Chip size='sm' label={mime_type} />
            </CardActions>
        </Card>
    );
}