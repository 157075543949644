import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import { getSetings, updateSettings } from "@/utils/Settings";
import { useToasterContext } from "@/utils/ToasterContext";
import { LoadingButton } from "@mui/lab";
import { Box, FormControl, FormControlLabel, FormLabel, Switch, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";

const Index = () => {
    
    const { showMessage, startProgress, completeProgress } = useToasterContext();

    const [data, setData] = useState({
        onepay_merchant_id: "TESTONEPAY",
        onepay_merchant_access_code: "6BEB2546",
        onepay_merchant_hash_code: "42C4DAE89DBD08E3267076C1F2873EE15AC891BFEDBA306CF113947B66426750",
        onepay_development_mode: "on",
    });
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bank, setBank] = useState(false);

    useEffect(() => {
        handleChangeData("bank_id", bank?.bin);
    }, [bank]);

    useEffect(() => {
        handleGetSettings();
    }, []);

    async function handleGetSettings() {
        try {
            startProgress();
            const response = await getSetings({
                settings: [
                    "onepay_merchant_id",
                    "onepay_merchant_access_code",
                    "onepay_merchant_hash_code",
                    "onepay_development_mode"
                ],
            });
            if (Array.isArray(response) && response.length) {
                let updateData = {};
                response.map((val) => {
                    updateData[val.setting_key] = val.setting_value;
                });
                setData((data) => ({
                    ...data,
                    ...updateData
                }));
            }
        } catch (error) {
            console.error("error", error);
        } finally {
            completeProgress();
            setIsLoaded(true);
        }
    }

    async function handleUpdateSettings(e) {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await updateSettings(data);
            if (response && !response.error) {
                showMessage("success", "Data updated successfully!");
            } else {
                showMessage("error", "An error has occurred!");
            }
        } catch (error) {
            console.error("error", error);
            showMessage("error", "An error has occurred!");
        } finally {
            setIsLoading(false);
        }
    }

    function handleChangeData(key, value) {
        setData((data) => ({
            ...data,
            [key]: value
        }));
    }

    if (!isLoaded) {
        return (
            <PageLoading />
        );
    }

    return (
        <div>
            <NavHeader
                title="OnePay"
                backLink="/admin/integrations/"
                breadcrumb={[
                    {
                        title: "OnePay"
                    }
                ]}
            />
            <Grid container spacing={4} >
                <Grid xs={12} lg={8}>
                    <form onSubmit={handleUpdateSettings}>
                        <Typography sx={{ fontWeight: 600, mb: 2 }}>
                            Set up payment information
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FormLabel>Merchant ID</FormLabel>
                            <TextField
                                type="text"
                                value={data?.onepay_merchant_id}
                                onChange={(e) => handleChangeData("onepay_merchant_id", e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FormLabel>Access code</FormLabel>
                            <TextField
                                type="text"
                                value={data?.onepay_merchant_access_code}
                                onChange={(e) => handleChangeData("onepay_merchant_access_code", e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FormLabel>Security code</FormLabel>
                            <TextField
                                type="text"
                                value={data?.onepay_merchant_hash_code}
                                onChange={(e) => handleChangeData("onepay_merchant_hash_code", e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FormControlLabel
                                control={<Switch />}
                                label="Development mode"
                                checked={data?.onepay_development_mode}
                                onChange={(e) => handleChangeData("onepay_development_mode", e.target.checked)} />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Box>
                                <LoadingButton
                                    variant='contained'
                                    color='secondary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}>
                                    Save
                                </LoadingButton>
                            </Box>
                        </FormControl>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
};

export default Index;