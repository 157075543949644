import NavHeader from "@/components/NavHeader";
import { findOneSession } from "@/utils/Sessions";
import { useToasterContext } from "@/utils/ToasterContext";
import { Box, Chip, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Fragment, useEffect, useRef, useState } from "react";

import { styled } from "@mui/material/styles";

import DateCell from "@/components/DateCell";
import Info from "@/components/Info";
import PageLoading from "@/components/PageLoading";
import { pusher } from "@/utils/Pusher";
import classNames from "classnames";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import validate from "validate.js";

dayjs.extend(customParseFormat);

const UpdateSession = () => {

    const { t } = useTranslation();
    
    const { id } = useParams();
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultValue, setDefaultValue] = useState(false);
    const [pageInit, setPageInit] = useState(false);
    const [trainer, setTrainer] = useState(false);
    const [AcademicClass, setClass] = useState([]);
    const [users, setUsers] = useState([]);
    const [classTypes, setClassTypes] = useState([]);
    const [trainerOptions, setTrainerOptions] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState(false);
    const [step, setStep] = useState(0);
    const [selectedClassType, setSelectedClassType] = useState(false);

    const formRef = useRef();
    
    const navigate = useNavigate();

    useEffect(() => {
        var channel = pusher.subscribe("session_" + id);
        channel.bind("App\\Events\\ClassSessionUserChanged", function(listened) {
            const data = listened?.data;
            if(data){
                handleChangeClassSessionData(data);
            }
        });
        channel.bind("App\\Events\\ClassSessionChanged", function(listened) {
            const data = listened?.data;
            if(data){
                handleChangeSessionData(data);
            }
        });
        channel.bind("App\\Events\\ClassSessionDeleted", function() {
            showMessage("error", t("Sorry, this session has expired!"));
            navigate("/");
        });
    }, []);

    function handleChangeSessionData(data) {
        setDefaultValue(data);
    }

    function handleChangeClassSessionData(data) {
        setDefaultValue((prev) => {
            const currentClassSession = prev?.class_sessions ?? [];
    
            const updatedSessions = currentClassSession.some((val) => val.id === data.id)
                ? currentClassSession.map((val) => (val.id === data.id ? data : val))
                : [...currentClassSession, data];
    
            return { ...prev, class_sessions: updatedSessions };
        });
    }

    useEffect(() => {
        const step = searchParams.get("step");
        if(step){
            setStep(step);
        }
    }, []);

    useEffect(() => {
        const class_types = AcademicClass?.class_types;
        let classTypeInfo = class_types ? JSON.parse(class_types) : null;
        if(Array.isArray(classTypeInfo) && classTypeInfo.length && defaultValue?.class_types){
            let selectedClass = classTypeInfo.find((val) => val?.title == defaultValue?.class_types);
            setSelectedClassType(selectedClass);
        }
    }, [defaultValue, AcademicClass]);

    async function handleFindOne() {
        try {
            const response = await findOneSession(id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        trainer,
                        users,
                        trainer_id
                    } = data;
                    const academicClass = data?.class;
                    if(Array.isArray(academicClass?.trainers) && academicClass?.trainers?.length){
                        setTrainerOptions(academicClass?.trainers || []);
                        setSelectedTrainer(trainer_id);
                    }
                    setClassTypes(JSON.parse(academicClass?.class_types) || []);
                    setClass(academicClass);
                    setTrainer(trainer);
                    setUsers(users);
                    setDefaultValue(data);
                }
            } else {
                showMessage("error", response?.data?.message || t("An error occurred, please try again later"));
            }
        } catch (error) {
            console.error("error", error);
        } finally {
            setPageInit(true);
        }
    }
    
    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startTime = parseTime(attributes.start_time);
        const endTime = parseTime(value);
        if (startTime && endTime && endTime <= startTime) {
            return options.message || t("must be after start time");
        }
    };

    // Helper function to parse time in hh:mm AM/PM format to Date object
    function parseTime(timeString) {
        const [time, modifier] = timeString.split(" ");
        let [hours, minutes] = time.split(":").map(Number);

        if (modifier === "PM" && hours < 12) {
            hours += 12;
        }
        if (modifier === "AM" && hours === 12) {
            hours = 0;
        }

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }


    useEffect(() => {
        if(AcademicClass){
            const { is_private, class_types, capacity } = AcademicClass;
            setClassTypes(class_types ? JSON.parse(class_types) : []);
            setDefaultValue((defaultValue) => ({
                ...defaultValue,
                is_private: is_private,
                capacity: capacity}));
        }
    }, [AcademicClass]);

    useEffect(() => {
        handleFindOne();
    }, []);

    const constraints = {
        // trainer_id: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // academic_class_id: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // schedule: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // capacity: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // start_time: {
        //     presence: { allowEmpty: false, message: "is required" },
        //     format: {
        //         pattern: /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
        //         message: "must be in the format hh:mm AM/PM"
        //     }
        // },
        // end_time: {
        //     presence: { allowEmpty: false, message: "is required" },
        //     format: {
        //         pattern: /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
        //         message: "must be in the format hh:mm AM/PM"
        //     },
        //     timeAfter: {
        //         message: "must be after start time"
        //     }
        // }
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    if (!pageInit) {
        return (
            <PageLoading />
        );
    }

    const start = dayjs(defaultValue.start_time);
    const end = dayjs(defaultValue.end_time);
    // Tính khoảng thời gian giữa hai thời điểm trong phút
    const durationInMinutes = end.diff(start, "minute");

    const currentDayString = dayjs().format("MM/DD");
    const currentDay = dayjs(currentDayString, "MM/DD");

    const sessionDateCompare = currentDay.isAfter(start);

    return (
        <Fragment>
            <Box sx={{ width: "100%" }}>
                <NavHeader
                    title={t("Trainer session")}
                    breadcrumb={[
                        {
                            title: t("Sessions"),
                            slug: "/trainer/sessions"
                        },
                        {
                            title: t("Trainer session")
                        }
                    ]}
                    backLink={"/trainer/sessions?step=" + step}
                />
                       <div className="max-w-[640px] mx-auto">
                    <Box>
                            <Divider sx={{ mb: 2 }}>
                                <Chip label={t("Session information")} className='uppercase' sx={{borderRadius: 0}}/>
                            </Divider>
                            <Box sx={{ mb: 3 }}>
                                {
                                    selectedClassType ? (
                                        <Fragment>
                                            <Typography>
                                                <span className='text-2xl font-bold uppercase'>{selectedClassType?.title}</span>
                                            </Typography>
                                            <Typography className="text-sm" sx={{mb: 3}}>
                                                {t(selectedClassType?.description)}
                                            </Typography>
                                        </Fragment>
                                    ) : (
                                        AcademicClass ?
                                            <Fragment>
                                                <Typography>
                                                    <span className='text-2xl font-bold upe'>{AcademicClass?.title}</span>
                                                </Typography>
                                                <Typography className="text-sm" sx={{mb: 3}}>
                                                    {AcademicClass?.description}
                                                </Typography>
                                            </Fragment>
                                            : ""
                                    )
                                }
                                {
                                    start ?
                                        <Fragment>
                                            <Typography>
                                                {t("on")} <span className='font-bold uppercase'> {start.format("ddd, DD/MM")}</span>
                                            </Typography>
                                            <Typography>
                                                {t("at")} <span className='font-bold uppercase'>{start.format("HH:mm")}</span>
                                            </Typography>
                                        </Fragment>
                                        : ""
                                }
                                {
                                    durationInMinutes ?
                                        <Typography>
                                            {t("Duration")}: <span className="font-bold">{durationInMinutes} {t("minutes")}</span>
                                        </Typography>
                                        : ""
                                }
                                <Typography>
                                    {t("Instructor")}: <strong>{trainer?.full_name || "-"}</strong> 
                                </Typography>

                                {    defaultValue?.is_free_session ? (
                                        <Typography sx={{ mb: 2 }}>
                                            {t("Free to join")} <Info title={t("You can register for this session without having to sign up for any membership plans")}/>
                                        </Typography>
                                    ) : ""
                                }
                                
                            </Box>
                        </Box>

                        <Paper sx={{ width: "100%", overflow: "hidden", mb: 3, borderRadius: 0 }}>
                            <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                                <Table
                                    stickyHeader aria-label="sticky table"
                                    size='medium'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: 60 }}>{t("No")}</TableCell>
                                                <TableCell>{t("Users / Shared users")}</TableCell>
                                                <TableCell>{t("Status")}</TableCell>
                                                <TableCell>{t("Created at")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(defaultValue?.class_sessions) && defaultValue?.class_sessions.length ?
                                                defaultValue?.class_sessions.map((row, index) => {
                                                    const { is_shared, name_shared, user, subscription, status, created_at, id, apply_bonus } = row;
                                                    return (
                                                        <StyledTableRow
                                                                    key={row.id}
                                                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                                    <TableCell component="th" scope="row">
                                                                        {(index + 1)}
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        {
                                                                            !is_shared ? (
                                                                                <div>
                                                                                    <p className='text-sm font-bold'>
                                                                                        { user?.display_name || user?.email}
                                                                                    </p>
                                                                                    <span className="text-xs">{row?.email}</span>
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <p className='text-sm font-bold'>
                                                                                        {name_shared}
                                                                                    </p>
                                                                                    <span className="text-slate-2 bg-slate-200 px-1 text-xs">
                                                                                        {t("Shared by:")} {user?.display_name || user?.email}
                                                                                    </span>
                                                                                    <span className="text-xs">{row?.email}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        <p className='text-xs'>{user?.email}</p>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        {
                                                                            status ? (
                                                                                <span className={classNames("border px-1 capitalize text-sm", {
                                                                                    "border-green-200 bg-green-100 text-green-600": status == "active",
                                                                                    "border-red-200 bg-red-100 text-red-600": status == "canceled",
                                                                                    "border-yellow-200 bg-yellow-100 text-yellow-600": status == "pending"
                                                                                })}>
                                                                                    {{
                                                                                        active: t("Booked"),
                                                                                        pending: t("Pending"),
                                                                                        canceled: t("canceled"),
                                                                                    }[status]}
                                                                                </span>
                                                                            ) : "-"
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        <DateCell time={created_at}/>
                                                                    </TableCell>
                                                                </StyledTableRow>
                                                    );
                                                }) : ""}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        
            </div>
        </Box>
    </Fragment>
    );
};

export default UpdateSession;
