import { instance } from "./axiosInstance";

export async function findManyTrainers(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/trainers", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneTrainer(id) {
    return await instance
        .get(`/trainers/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function AnalyticTrainer(id, params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get(`/trainers/${id}/analytics`, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createTrainer(data) {
    return await instance
        .post("/trainers", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateTrainer(id, data) {
    return await instance
        .put("/trainers/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteTrainer(id) {
    const config = {
        data: {
            id: id
        }
    };
    return await instance
        .delete("/trainers/" + id, config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function deleteTrainers(data) {
    const config = {
        data: {
            ids: data
        }
    };
    return await instance
        .delete("/trainers", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}