import { Box, Button, Container, Typography} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IoInformationCircle } from "react-icons/io5";
import { Link } from "react-router-dom";

const CancelSessionComplete = () => {

    const  { t } = useTranslation();
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignItems: "center",
                }}
            >
                <IoInformationCircle size={55} style={{marginBottom: 35}}/>
                <Typography sx={{ fontSize: 24, marginBottom: 3 }}>
                    {t("Your session has been canceled, please check notification from an email sent to your email box.")}
                </Typography>
                <Button as={Link} to="/" variant='contained'>
                    {t("Return home")}
                </Button>
            </Box>
        </Container>
    );
};

export default CancelSessionComplete;