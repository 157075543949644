import dayjs from "dayjs";
import React from "react";


var utc = require("dayjs/plugin/utc");

dayjs.extend(utc);

const DateCell = ({ time }) => {
  return (
    <div>
      <p>{time ? dayjs(time).format("hh:mm A, DD/MM/YYYY") : "-"}</p>
    </div>
  );
};

export default DateCell;