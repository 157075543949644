import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import MediaCard from "@/components/MediaCard";
import NavHeader from "@/components/NavHeader";
import AddIcon from "@mui/icons-material/Add";

import ConfirmDelete from "@/components/ConfirmDelete";
import FilterTable from "@/components/FilterTable";
import filterEmptyValuesObject from "@/utils/filterEmptyValuesObject";
import { deleteMedia, findManyMedia } from "@/utils/Media";
import { useToasterContext } from "@/utils/ToasterContext";
import { Grid, TablePagination } from "@mui/material";
import { useTranslation } from "react-i18next";

const Index = () => {

    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [deleteFile, setDeleteMedia] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [pageStatus, setPageStatus] = useState("idle");
    
    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_date: null,
        end_date: null
    });

    const handleFindManyData = async (filter) => {
        try {
            startProgress();
            setSearchParams(filter);
            const response = await findManyMedia(filter);
            if (response && !response.error) {
                const { data, meta } = response;
                setData(data);
                setTotal(meta?.total);
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            completeProgress();
            setPageStatus("complete");
        }
    };

    async function handleDeleteFile(fileId) {
        try {
            setIsLoading("delete");
            const response = await deleteMedia(fileId);
            if (response && !response.error) {
                showMessage("success", response?.message || "File deleted successfully");
            } else {
                showMessage("error", deleteFile?.message || "An error occurred. Please try again later!");
            }
        } catch (error) {
            showMessage("error", "An error occurred. Please try again later!");
        } finally {
            setDeleteMedia(false);
            setIsLoading(false);
            handleFindManyData(filter);
        }
    }

    const handleChangePage = (event, newPage) => {
        setFilter({
            ...filter,
            paged: newPage ? (newPage + 1) : 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        });
    };

    useEffect(() => {
        const initFilter = {
            search: searchParams.get("search"),
            order: searchParams.get("order"),
            start_date: searchParams.get("start_date"),
            end_date: searchParams.get("end_date"),
            paged: searchParams.get("paged") || 1,
            per_page: searchParams.get("per_page") || 12,
        };
        let filterParams = {
            ...filter,
            ...initFilter
        };
        setFilter(filterParams);
        handleFindManyData(filterEmptyValuesObject(filterParams));
    }, []);

    useEffect(() => {
        if (pageStatus == "complete") {
            handleFindManyData(filterEmptyValuesObject(filter));
        }
    }, [filter]);

    return (
        <Box sx={{ width: "100%" }}>
            <NavHeader
                title={t("Media")}
                breadcrumb={[
                    {
                        title: t("Media")
                    }
                ]}
                endAction={(
                    <Link to="/admin/media/upload">
                        <Button
                            startIcon={<AddIcon />}
                            variant='contained'
                            size='small'
                            color='primary'>
                            {t("Create")}
                        </Button>
                    </Link>
                )}
            />

            <Box>
                <FilterTable filter={filter} setFilter={setFilter} />
                <Grid container spacing={3}>
                    {
                        Array.isArray(data) && data.length ?
                            data.map((val) => {
                                return (
                                    <Grid item key={val.id} xs={6} lg={3}>
                                        <MediaCard data={val} setDeleteMedia={setDeleteMedia}/>
                                    </Grid>
                                );
                            })
                            : ""
                    }
                </Grid>
                <TablePagination
                    labelRowsPerPage={t("Rows per page:")}
                    rowsPerPageOptions={[12, 24, 36]}
                    component="div"
                    count={total || 0}
                    rowsPerPage={parseInt(filter?.per_page) || 12}
                    page={parseInt(filter?.paged - 1)}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
            <ConfirmDelete 
                open={deleteFile} 
                setOpen={setDeleteMedia} 
                isLoading={isLoading} 
                onSubmit={() => handleDeleteFile(deleteFile)}
            />
        </Box>
    );
};

export default Index;
