import { Fragment, useEffect, useState } from "react";

import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

export default function ClasssSession({chart}) {
  
    var colorPalette = ["#00D8B6","#008FFB",  "#FEB019", "#FF4560", "#775DD0"];

    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState(["1", "2"]);
    const { t } = useTranslation();

    const [options, setOptions] = useState({
      fontFamily: "\"Inter\", sans-serif",
      chart: {
          type: "donut",
          width: "100%",
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            size: "60%",
          },
          offsetY: 20,
        },
        stroke: {
          colors: undefined
        }
      },
      colors: colorPalette,
      title: {
        text: t("Session count"),
        style: {
          fontSize: "18px"
        }
      },

      series: series,
      labels: labels,
      legend: {
        position: "bottom",
      }
    });

    useEffect(() => {
        if(Array.isArray(chart) && chart.length){
            let labels = chart.map((val) => val.title);
            let series =  chart.map((val) => val.session_count);
            setSeries(series);
            setOptions((options) => ({...options, labels: labels}));
        }
    }, [chart]);
    
    return (
        <Fragment>
           { Array.isArray(chart) && chart.length ? 
           <Chart 
              options={options} 
              series={series} 
              labels={labels} 
              type="donut" 
              width="100%" 
              height={380} 
           /> : ""}
        </Fragment>
    );
}