import { instance } from "./axiosInstance";

export async function findManyMembershipCategories(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/membership-categories", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneMembershipCategory(id) {
    return await instance
        .get(`/membership-categories/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createMembershipCategory(data) {
    return await instance
        .post("/membership-categories", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateMembershipCategory(id, data) {
    return await instance
        .patch("/membership-categories/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteMembershipCategory(id) {
    const config = {
        data: {
            id: id
        }
    };
    return await instance
        .delete("/membership-categories", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}