import { Tooltip } from "@mui/material";
import { IoAlertCircle } from "react-icons/io5";

const Info = ({ title, placement = "top" }) => {
  return (
    <Tooltip title={title} placement={placement}>
        <span style={{ display: "inline-block"}}>
            <IoAlertCircle color="#8d8d8d"/>
        </span>
    </Tooltip>
  );
};

export default Info;