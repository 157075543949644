import { instance } from "./axiosInstance";

export async function getSessionAnalytics(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/analytics/session-analytics", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function getClassSessionAnalytics(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/analytics/class-sessions", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function getTrainerAnalytics(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/analytics/trainers", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function getOrderAnalytics(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/analytics/order-analytics", config)
        .then((res) => res.data)
        .catch((err) => {
            return { 
                error: true,
                data: err
            };
        });
}

export async function getOrderByMonth() {
    return await instance
        .get("/analytics/orders/month")
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteanalytics(data) {
    const config = {
        data: data
    };
    return await instance
        .delete("/analytics", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function updateClass(id, data) {
    return await instance
        .patch("/analytics/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteClass(id) {
    return await instance
        .delete("/analytics/" + id)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}