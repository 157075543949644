import { createContext, useContext, useEffect, useRef, useState } from "react";

import LoadingBar from "react-top-loading-bar";
import { findAllTranslations } from "@/utils/Translations";
import "react-toastify/dist/ReactToastify.css";
import { getCookie, setCookie } from "./Hooks";

import i18n from "i18next";
import toast, { Toaster } from "react-hot-toast";
import { initReactI18next } from "react-i18next";

import vi from "@/utils/language/vi.json";

export const ToasterContext = createContext();

export function ToasterProvider({ children }) {

    const ref = useRef(null);

    const [translation, setTranslation] = useState({});
    const [currentLanguage, setLanguage] = useState("en");

    const vietnamese = {
        translation: {
            ...vi.translation,
            ...translation
        }
    };

    i18n.use(initReactI18next).init({
        compatibilityJSON: "v3",
        lng: currentLanguage,
        fallbackLng: "en",
        resources: {
            vi: vietnamese,
        },
        interpolation: {
            escapeValue: false // react already safes from xss 
        },
        react: {
            useSuspense:false,
        }
    });

    const handleGetTranslation = async () => {
        try {
            const response = await findAllTranslations();
            if(response && !response.error){
                const { data } = response;
                let translations = {};
                data.map((val) => translations[val.text_value] = val.text_translated);
                setTranslation(translations);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const showMessage = (type, message, position = "top-center") => {
        toast[type](message, {
            position: position,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };

    function startProgress() {
        ref.current.continuousStart(0, 200);
    }

    function completeProgress() {
        ref.current.complete();
    }

    function changeLanguage(language) {
        const expires_in = 30 * 24 * 60 * 60;
        setCookie("language", language, expires_in);
        i18n
            .changeLanguage(language)
            .then(() => setLanguage(language))
            .catch(err => console.log(err));
    }

    function handleGetLanguage(){
        const language = getCookie("language");
        if(["en", "vi"].includes(language)){
            i18n
            .changeLanguage(language)
            .then(() => setLanguage(language))
            .catch(err => console.log(err));
        }
    }

    useEffect(() => {
        handleGetTranslation();
        handleGetLanguage();
    }, []);

    return (
        <ToasterContext.Provider value={{ showMessage, startProgress, completeProgress, changeLanguage, currentLanguage }}>
            {children}
            <LoadingBar
                ref={ref}
                color='#e67e22'
            />
            <Toaster
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </ToasterContext.Provider>
    );
}

export function useToasterContext() {
    return useContext(ToasterContext);
}