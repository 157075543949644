import { instance } from "./axiosInstance";

export async function findUserInformation() {
    return await instance
        .get("/user")
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}


export async function changeUserPoint(data) {
    return await instance
        .post("/user/point", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function removeUserLoyalty(data) {
    return await instance
        .post("/user/point/remove", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createUserInformation(data) {
    return await instance
        .post("/user", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateUserInformation(data) {
    return await instance
        .patch("/user", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteUserInformation() {
    return await instance
        .delete("/user")
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

/* Đơn hàng */
export async function findManyUserOrders(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/user/orders", config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function findOneUserOrder(id) {
    return await instance
        .get("/user/orders/" + id)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

/* Đăng ký thành viên */
export async function findManyUserSubscriptions(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/user/subscriptions", config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

/* Đăng ký thành viên */
export async function findManyUserSubscriptionsForSession(session_id) {
    return await instance
        .get("/user/subscriptions/" + session_id)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

/* Đăng ký sessions */
export async function findManyUserSessions(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/user/sessions", config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function findOneUserSession(id) {
    return await instance
        .get("/user/sessions/" + id)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createUserSessions(data) {
    return await instance
        .post("/user/sessions", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateUserSessions(id, data) {
    return await instance
        .patch("/user/sessions/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteUserSession(id, data = {}) {
    const config = {
        data: data
    };
    return await instance
        .delete("/user/sessions/" + id , config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}