import { useToasterContext } from "@/utils/ToasterContext";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Chip, Divider, Modal, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import QRCode from "react-qr-code";

import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import { deleteUserSession, findManyUserSubscriptions, findOneUserSession } from "@/utils/UserInfomations";
import { useTranslation } from "react-i18next";

dayjs.extend(customParseFormat);

const APP_URL = process.env.REACT_APP_APP_URL;

const UserSessionApplyWaitingList = () => {
    
    const { id } = useParams();
    const { t } = useTranslation();
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const navigate = useNavigate();

    // Gộp trạng thái thành một object để dễ quản lý hơn
    const [state, setState] = useState({
        isLoading: false,
        pageInit: false,
        defaultValue: null,
        trainer: [],
        AcademicClass: [],
        users: [],
        subscriptions: [],
        selectedClassType: null,
        classSessions: [],
        selectedClassSession: null,
        deleteSession: false,
    });

    const { 
        isLoading, 
        pageInit, 
        defaultValue, 
        trainer, 
        AcademicClass, 
        users, 
        selectedClassType, 
        classSessions, 
        selectedClassSession, 
        deleteSession 
    } = state;

    const handleClose = () => {
        setState(prevState => ({ ...prevState, deleteSession: false }));
    };

    const handleCancelSession = useCallback(async (session_id) => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));
            const response = await deleteUserSession(session_id);
            if (response && !response.error) {
                handleClose();
                handleFindOneSession();
            } else {
                showMessage("error", t("An error occurred, please try later"));
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try later"));
        } finally {
            setState(prevState => ({ ...prevState, isLoading: false }));
        }
    }, [navigate, showMessage]);

    const handleFindManyUserSubscriptions = useCallback(async () => {
        try {
            const response = await findManyUserSubscriptions({});
            if (response && !response.error) {
                const { data } = response;
                setState(prevState => ({ ...prevState, subscriptions: Array.isArray(data) ? data : [] }));
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    const handleFindOneSession = useCallback(async () => {
        try {
            startProgress();
            const response = await findOneUserSession(id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const { class__session, trainer, users } = data;
                    const academicClass = data?.class;
                    const currentSession = class__session?.find(val => !val?.is_shared);

                    setState(prevState => ({
                        ...prevState,
                        defaultValue: {
                            title: data.title,
                            class_types: data.class_types,
                            capacity: data.capacity,
                            start_time: data.start_time,
                            end_time: data.end_time,
                        },
                        trainer: trainer,
                        users: users,
                        AcademicClass: academicClass,
                        classSessions: class__session || [],
                        selectedClassSession: currentSession,
                    }));
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            completeProgress();
            setState(prevState => ({ ...prevState, pageInit: "complete" }));
        }
    }, [id, startProgress, completeProgress]);

    useEffect(() => {
        handleFindManyUserSubscriptions();
        handleFindOneSession();
    }, [handleFindManyUserSubscriptions, handleFindOneSession]);

    // Tính toán giá trị duration chỉ khi cần
    const durationInMinutes = useMemo(() => {
        if (!defaultValue?.start_time || !defaultValue?.end_time) return 0;
        const start = dayjs(defaultValue.start_time);
        const end = dayjs(defaultValue.end_time);
        return end.diff(start, "minute");
    }, [defaultValue]);

    // Tính toán so sánh thời gian session với thời gian hiện tại
    const sessionDateCompare = useMemo(() => {
        return dayjs().isAfter(dayjs(defaultValue?.start_time));
    }, [defaultValue]);

    // Cập nhật selected class type khi `defaultValue` hoặc `AcademicClass` thay đổi
    useEffect(() => {
        const classTypes = AcademicClass?.class_types ? JSON.parse(AcademicClass.class_types) : null;
        const selectedClass = classTypes?.find(val => val?.title === defaultValue?.class_types);
        setState(prevState => ({ ...prevState, selectedClassType: selectedClass }));
    }, [defaultValue, AcademicClass]);

    function isMoreThan12Hours(start_time) {
        // Lấy thời gian hiện tại
        const now = dayjs();
        
        // Tạo đối tượng dayjs từ start_time
        const targetTime = dayjs(start_time);
        
        // Tính khoảng thời gian giữa hiện tại và start_time tính theo giờ
        const diffInHours = targetTime.diff(now, "hour");
        
        // Trả về true nếu khoảng cách lớn hơn 1 giờ, ngược lại false

        return diffInHours > 1;
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 540,
        bgcolor: "background.paper",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const SharedPersonal = ({ data }) => {
        const [viewQr, setViewQr] = useState(false);
        const { id, name_shared, check_in_token, status } = data;

        return (
            <Box className='p-3 border border-black mb-5'>
                <Stack 
                    direction="row" 
                    sx={{justifyContent: "space-between", alignItems: "center"}} 
                    spacing={1}>
                    <div className='flex-1'>
                        <Typography><strong>{name_shared}</strong></Typography>
                        <span className='border border-black px-1 text-[12px] capitalize'>{status}</span>
                    </div>
                    <Button 
                        onClick={() => setViewQr(!viewQr)} 
                        variant='outlined' 
                        sx={{
                            borderRadius:  0,
                            borderColor: "#000"
                        }}>
                        <strong>{t("View")}</strong>
                    </Button>
                </Stack>
                {
                    viewQr ? (
                        <Box sx={{ background: "#fff", mt: 3}}>
                            <QRCode
                                size={256}
                                style={{
                                    height: "auto",
                                    maxWidth: 180,
                                    margin: "auto",
                                    width: "100%",
                                    marginBottom: 15,
                                }}
                                value={APP_URL + `/session-checkin?token=${check_in_token}`}
                                viewBox={"0 0 256 256"}
                            />  
                            <Typography sx={{ textAlign: "center", textTransform: "uppercase" }}>
                                {t("Scan to check-in")}
                            </Typography>       
                            {
                            !sessionDateCompare ? (
                                    <div className='text-center mt-5'>
                                        {
                                            status != "canceled" && isMoreThan12Hours(defaultValue?.start_time) ? 
                                                <Button
                                                    variant='contained'
                                                    onClick={() => setState(prevState => ({ ...prevState, deleteSession: id }))}
                                                    sx={{
                                                        mb: 3,
                                                        background: "#000",
                                                        borderRadius: 0,
                                                        fontWeight: 600
                                                    }}>
                                                    {t("Cancel session")}
                                                </Button> : 
                                                <Typography className="font-normal tracking-[2px] uppercase">
                                                    {t("Session canceled")}
                                                </Typography>
                                        }
                                    </div>
                                ): ""
                        }
                        </Box>
                    ): ""
                }
            </Box>
        );
    }; 

    if (pageInit !== "complete") {
        return <PageLoading />;
    }

    return (
        <Box sx={{ width: "100%" }}>
            <NavHeader
                title={t("Your session")}
                breadcrumb={[{ title: t("Your session") }]}
                backLink="/account/"
            />
            <div className="max-w-[520px] mx-auto">
                {selectedClassSession?.check_in_token && (
                    <Box sx={{ mb: 3 }}>
                        <QRCode
                            size={256}
                            style={{
                                height: "auto",
                                maxWidth: 180,
                                margin: "auto",
                                width: "100%",
                                marginBottom: 15,
                            }}
                            value={`${APP_URL}/session-checkin?token=${selectedClassSession?.check_in_token}`}
                            viewBox={"0 0 256 256"}
                        />
                        <Typography sx={{ textAlign: "center", textTransform: "uppercase" }}>
                            {t("Scan to check-in")}
                        </Typography>
                    </Box>
                )}
                <Box>
                    <Divider sx={{ mb: 2 }}>
                        <Chip label={t("Session information")} className='uppercase' sx={{ borderRadius: 0 }} />
                    </Divider>
                    <Box sx={{ mb: 3 }}>
                        {selectedClassType || AcademicClass ? (
                            <Box>
                                <Typography>
                                    <span className='text-2xl font-bold uppercase'>
                                        {t((selectedClassType || AcademicClass)?.title)}
                                    </span>
                                </Typography>
                                <Typography className="text-sm">
                                    {t((selectedClassType || AcademicClass)?.description)}
                                </Typography>
                                {
                                    selectedClassSession?.status ? (
                                        <span className='border border-black px-1 text-[12px] capitalize inline-block mb-[15px]'>
                                            {selectedClassSession.status}
                                        </span>
                                    ) : ""
                                }
                                
                            </Box>
                        ) : null}
                        {isMoreThan12Hours(defaultValue?.end_time) && users[0]?.pivot?.status !== "canceled" && (
                            <Button
                                variant='contained'
                                onClick={() => setState(prevState => ({ ...prevState, deleteSession: selectedClassSession?.id }))}
                                sx={{ mb: 3, background: "#000", borderRadius: 0 }}
                            >
                                {t("Cancel session")}
                            </Button>
                        )}
                        <Typography>
                            {t("on")} <span className='font-bold uppercase'> {dayjs(defaultValue?.start_time).format("ddd, DD/MM")}</span>
                        </Typography>
                        <Typography>
                            {t("at")} <span className='font-bold uppercase'>{dayjs(defaultValue?.start_time).format("HH:mm")}</span>
                        </Typography>
                        <Typography>
                            {t("Duration")}: <span className="font-bold">{durationInMinutes} minutes</span>
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            {t("Instructor")}: <strong>{trainer?.full_name}</strong> 
                        </Typography>
                        {Array.isArray(classSessions) && classSessions.filter(val => val?.is_shared === 1).length > 0 && (
                            <Box sx={{ mt: 2 }}>
                                <h3 className='font-bold mb-0'>{t("Shared with person")}</h3>
                                {classSessions.filter(val => val?.is_shared === 1).map((val, index) => (
                                    <SharedPersonal data={val} key={index} />
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            </div>
            <Modal
                open={!!deleteSession}
                onClose={handleClose}
            >
                <Box sx={{ ...style, maxWidth: 540 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t("Do you want to cancel this session?")}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("Please note that this action cannot be undone.")}
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" mt={4}>
                        <Button
                            onClick={handleClose}
                            variant='contained'
                            sx={{ backgroundColor: "#000", color: "#fff", padding: "10px 20px", textTransform: "uppercase", borderRadius: 0 }}>
                            {t("Close")}
                        </Button>
                        <LoadingButton
                            onClick={() => handleCancelSession(deleteSession)}
                            loading={isLoading}
                            variant='contained'
                            sx={{ backgroundColor: "#d32f2f", color: "#fff", padding: "10px 20px", textTransform: "uppercase", borderRadius: 0 }}>
                            {t("Cancel session")}
                        </LoadingButton>
                    </Stack>
                </Box>
            </Modal>
        </Box>
    );
};
export default UserSessionApplyWaitingList;