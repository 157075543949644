import { Box, Button, Drawer } from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMenu } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";

const FilterWrapper = ({ children }) => {

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

    if(!isTabletOrMobile){
        return children;
    }

    return (
        <Fragment>
            <Button 
                onClick={() => setOpen(true)} 
                variant='outlined' 
                color='secondary'
                sx={{mb: 3}} 
                fullWidth 
                startIcon={<IoMenu />}>
                    {t("Filter")}
            </Button>
            <Drawer
                sx={{borderTopRightRadius: 8}}
                anchor={"bottom"}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            </Drawer>
        </Fragment>
  );
};

export default FilterWrapper;