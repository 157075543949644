import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserLoggedin } from "../Auth";

const initialState = {
    user: null,
    status: "idle" || "loading" || "complete"
};

export const getDataUser = createAsyncThunk(
    "users/getUserInformation",
    async () => {
        try {
            const response = await getUserLoggedin();
            if (response && !response.error) {
                return response;
            }
            return null;
        } catch (error) {
            console.error("error", error);
        }
    },
);

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDataUser.pending, (state) => {
            state.status = "loading";
        });
        builder.addCase(getDataUser.fulfilled, (state, action) => {
          state.user = action.payload || null;
          state.status = "complete";
        });
      },
});

export const { setStatus, setUser } = authSlice.actions;

export const user = (state) => state.auth.user;

export default authSlice.reducer;
