import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import { useToasterContext } from "@/utils/ToasterContext";
import { findManyUserWaitingLists } from "@/utils/UserWaitingList";
import { alpha } from "@mui/material/styles";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import FilterTable from "@/components/FilterTable";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";

const headCells = [
    {
        id: "class",
        numeric: false,
        label: "Class",
    },
    {
        id: "users",
        label: "Instructor",
    },
    {
        id: "start_time",
        label: "Start in",
    },
    {
        id: "duration",
        label: "Duration",
    },
    {
        id: "action",
        label: "Action",
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <p className="font-bold">
                            {t(headCell.label)}
                        </p>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableToolbar(props) {
    const { numSelected, isLoading, onSubmit } = props;
    const { t } = useTranslation();
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <>
                    <p>
                        {numSelected} {t("selected")}
                    </p>
                    <Tooltip title="Delete" isLoading={isLoading} onClick={() => onSubmit()}>
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : ""
            }
        </Toolbar>
    );
}

const UserWaitingList = () => {

    const navigate = useNavigate();

    const { startProgress, completeProgress } = useToasterContext();
    const { t } = useTranslation();

    const [pageStatus, setPageStatus] = useState("idle");
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [step, setStep] = useState(0);
    const [selected, setSelected] = useState([]);

    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_time: false,
        end_time: false,
    });

    const handleFindManyData = async (filter) => {
        try {
            startProgress();
            setPageStatus("idle");
            const response = await findManyUserWaitingLists(filter);
            if (response && !response.error) {
                const { data, meta } = response;
                setData(data || []);
                setTotal(meta?.total || 0);
            }
            setPageStatus("complete");
        } catch (error) {
            console.error("error", error);
        } finally {
            completeProgress();
            setPageStatus("complete");
        }
    };

    useEffect(() => {
        if (filter?.start_time) {
            handleFindManyData(filter);
        }
    }, [filter]);

    useEffect(() => {
        const start_time = dayjs().add(step, "week").startOf("week").format("YYYY-MM-DD HH:mm:ss");
        const end_time = dayjs().add(step, "week").endOf("week").format("YYYY-MM-DD HH:mm:ss");
        setFilter({
            ...filter,
            start_time: start_time,
            end_time: end_time
        });
    }, [step]);

    const handleChangePage = (event, newPage) => {
        setFilter({
            ...filter,
            paged: newPage ? (newPage + 1) : 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        });
    };

    if (pageStatus != "complete") {
        return (
            <PageLoading />
        );
    }


    const Row = ({ rowData }) => {

        const {
            id,
            session,
            billing_name,
            subscription,
            created_at,
            apply_token,
            user
        } = rowData;
        const [open, setOpen] = useState(false);

        const start = session?.start_time ? dayjs(session.start_time) : "-";
        const end = session?.end_time ? dayjs(session.end_time): "-";

        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={id}
                    className='!text-sm'
                    onClick={() => setOpen(!open)}
                    sx={{ cursor: "pointer" }}>
                    <TableCell>
                        <p>
                            {id}
                        </p>
                    </TableCell>
                    <TableCell>
                       <p>{session?.class_types}</p>
                    </TableCell>
                    <TableCell>
                        <p>
                           {session?.trainer?.full_name}
                        </p>
                    </TableCell>
                    <TableCell>{start.format("HH:mm, DD/MM/YYYY")}</TableCell>
                    <TableCell>{session?.duration_minutes} {t("minutes")}</TableCell>
                    <TableCell sx={{ width: 120}}>
                        <Button 
                            onClick={() => navigate("/account/waiting-list/" + id)}
                            variant='contained' 
                            disabled={apply_token}
                            sx={{borderRadius: 0, background: "#000"}}>
                            {t("View")}
                        </Button>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    };

    return (
        <Box>
            <NavHeader
                title={t("Account")}
                breadcrumb={[
                    {
                        title: t("Account")
                    }
                ]}
                endAction={(
                    <Button 
                        variant='contained'>
                        {t("My waiting list")}
                    </Button>
                )}
            />
               <Box>
                <FilterTable filter={filter} setFilter={setFilter} />
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                        <Table
                            stickyHeader aria-label="sticky table"
                            size='medium'>
                            <EnhancedTableHead />
                            <TableBody>
                                {data.map((row) => {
                                    return (
                                        <Row key={row.id} rowData={row} />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[12, 24, 36]}
                        component="div"
                        count={total || 0}
                        rowsPerPage={parseInt(filter?.per_page) || 12}
                        page={parseInt(filter?.paged - 1)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </Box>
    );
};

export default UserWaitingList;
