import { instance } from "./axiosInstance";

export async function findManyLoyalties(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/loyalties", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneLoyalty(id) {
    return await instance
        .get(`/loyalties/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function findOneLoyaltyByTitle(Loyalty_code) {
    return await instance
        .get(`/loyalties/code/${Loyalty_code}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createLoyalty(data) {
    return await instance
        .post("/loyalties", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateLoyalty(id, data) {
    return await instance
        .patch("/loyalties/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteLoyalty(id) {
    return await instance
        .delete("/loyalties/" + id)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function deleteLoyalties(data) {
    const config = {
        data: {
            ids: data
        }
    };
    return await instance
        .delete("/loyalties", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}