import {
    Autocomplete, Box, Button, FormControlLabel, Grid, List, ListItem, MenuItem, Modal,
    Paper, Select, Stack, Switch, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material";

import { Countries } from "@/utils/Countries";
import { numberWithCommas } from "@/utils/Hooks";
import { findManyMemberships } from "@/utils/Memberships";
import { applySubscription, findOneOrder, updateOrder } from "@/utils/Orders";
import { findManyPromotions } from "@/utils/Promotions";
import { useToasterContext } from "@/utils/ToasterContext";
import { findManyUsers } from "@/utils/Users";
import { LoadingButton } from "@mui/lab";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoTrash } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";

import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import PostPicker from "@/components/PostPicker";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import validate from "validate.js";
import TableSubscription from "./Components/TableSubscription";
import TableTransaction from "./Components/TableTransaction";

import CreditLine from "@/assets/176x24-credit.svg";
import { default as ATM, default as Credit } from "@/assets/64x64-atm.svg";
import Bank from "@/assets/64x64-bank.svg";
import Qrcode from "@/assets/64x64-vi-vnpay.svg";
import classNames from "classnames";


const UpdateOrder = () => {

    const { id } = useParams();
    const { showMessage, startProgress, completeProgress, currentLanguage } = useToasterContext();
    const { t } = useTranslation(); 
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(false);
    const [formError, setFormError] = useState(false);
    const [cart, setCart] = useState([]);
    const [defaultCart, setDefaultCart] = useState([]);
    const [deleteItem, setDeleteItem] = useState(false);
    const [defaultValue, setDefaultValue] = useState(false);
    const [pageInit, setPageInit] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("vietqr");
    const [paymentType, setPaymentType] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [promotion, setPromotion] = useState(false);
    const [cartTotal, setCartTotal] = useState(0);
    const [cartSubTotal, setCartSubTotal] = useState(0);
    const [promotionDiscount, setPromotionDiscount] = useState(0);
    const [loyaltyDiscount, setLoyaltyDiscount] = useState(0);

    const [openApplySubscription, setOpenApplySubscription] = useState(false);
    const [isLoadingApplySubscription, setIsLoadingApplySubscription] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);

    const [addToCart, setAddToCart] = useState(false);
    
    const [open, setOpen] = useState(false);

    async function handleFindOne() {
        try {
            const response = await findOneOrder(id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    
                    const {
                        payment_method,
                        payment_type,
                        cart,
                        transactions,
                        subscriptions,
                        promotion,
                        user,
                    } = data;

                    if(Array.isArray(subscriptions) && subscriptions.length){
                        setSubscriptions(subscriptions);
                    }

                    if(Array.isArray(transactions) && transactions.length){
                        setTransactions(transactions.reverse());
                    }
                    setDefaultCart(cart);
                    setUser(user);
                    setCart(cart);
                    setPaymentMethod(payment_method);
                    setPaymentType(payment_type);
                    setPromotion(promotion);
                    setDefaultValue(data);
                }
            }
        } catch (error) {
            console.error("Error: ", error);
        } finally {
            setPageInit(true);
        }
    }

    async function handleUpdateOrder(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updateOrder(id, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage("success", t("Data updated successfully!"));
                if (data?.id) {
                    handleFindOne();
                }
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try again later!"));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleApplySubscription(){
        try {
            setIsLoadingApplySubscription(true);
            const response = await applySubscription(id);
            if (response && !response.error) {
                showMessage("success", t("Data updated successfully!"));
                handleFindOne();
            } else {
                showMessage("error", t("An error occurred, please try again later!"));
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try again later!"));
        } finally {
            setOpenApplySubscription(false);
            setIsLoadingApplySubscription(false);
        }
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        let subTotalPrice = 0;
        let totalPrice = 0;
        let promotionDiscount = 0;
        let loyaltyDiscount = 0;
        if (Array.isArray(cart) && cart?.length) {
            cart.map((value) => {
                const { loyalty_amount, promotion_amount, subtotal, total } = value;
                loyaltyDiscount += loyalty_amount || 0;
                promotionDiscount += promotion_amount || 0;
                subTotalPrice += subtotal || 0;
                totalPrice += total || 0;
            });
        }
        setCartTotal(totalPrice);
        setLoyaltyDiscount(loyaltyDiscount);
        setCartSubTotal(subTotalPrice);
        setPromotionDiscount(promotionDiscount);
    }, [cart]);

    function handleChangePromotion(data){
        if (data) {
            const { id, memberships } = data;
            let validated = false;
            if(Array.isArray(cart) && cart?.length) {
                let updatedCart = cart.map((val) => {
                    if(memberships.find((_v) => _v.id == val?.membership?.id)){
                        val["promotion_id"] = id;
                        validated = true;
                    }
                    return val;
                });
                if(validated){
                    setCart(updatedCart);
                    showMessage("success", t("Promotion code successfully applied!"));
                    setPromotion(data);
                } else {
                    setPromotion(false);
                    showMessage("error", t("This code is not valid."));
                }
            } else {
                showMessage("error", t("This code is not valid."));
                setPromotion(false);
            }
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const notify = data.get("sent_notify");

        const dataForm = {
            billing_name: data.get("billing_name"),
            billing_phone: data.get("billing_phone"),
            billing_email: data.get("billing_email"),
            billing_country: data.get("billing_country"),
            billing_city: data.get("billing_city"),
            billing_district: data.get("billing_district"),
            billing_ward: data.get("billing_ward"),
            billing_address: data.get("billing_address"),
            payment_status: data.get("payment_status"),
            language: currentLanguage,
            payment_method: paymentMethod,
            payment_type: paymentType,
            billing_subtotal: cartTotal,
            promotion_id: promotion?.id,
            user_id: user?.id,
            send_email: notify == "on" ? 1 : 0,
            cart: cart,
        };

        const error = validate(dataForm, constraints);
        if (!error) {
            handleUpdateOrder(dataForm);
        }
        setFormError(error);
    }

    function handleAddToCart(e) {
        e.preventDefault();
   
        if(!addToCart){
            return showMessage("error", t("Membership is current in cart!"));
        }
        
        let validatePromotion = false;

            const isInCart = cart.find((val) => val?.membership?.id == addToCart?.id);
            
            if (isInCart) {
                return showMessage("error", t("Membership is current in cart!"));
            }

            let quantity = 1;
            let membershipLoyalty = null;
            let membershipLoyalties = addToCart?.membership_loyalty;
            if(Array.isArray(membershipLoyalties) && membershipLoyalties?.length){
                membershipLoyalty = membershipLoyalties[0];
            }

            let regular_price = addToCart?.regular_price;
            let loyaltyDiscount = 0;
            let promotionDiscount = 0;
            let promotionAmount = 0;
            let subtotal = regular_price * quantity;
            let total = subtotal;
   
            if(promotion){
                const { for_all_memberships, promotion_amount, memberships } = promotion;
                promotionAmount = promotion_amount;
                if(!for_all_memberships){
                    if(Array.isArray(memberships) && memberships?.length) {
                        validatePromotion = memberships.find((val) => val.id == addToCart?.id);
                    }
                } else {
                    validatePromotion = true;
                }
            }

            if(membershipLoyalty) {
                const discount_percent = membershipLoyalty?.discount_percent;
                loyaltyDiscount = subtotal * parseInt(discount_percent) / 100;
                total -= loyaltyDiscount;
            }

            if(validatePromotion){
                promotionDiscount = subtotal * parseInt(promotionAmount) / 100;
                total -= promotionDiscount;
            }

            let cartData = {
                membership_id: addToCart?.id,
                membership: addToCart,
                loyalty: membershipLoyalty?.loyalty,
                loyalty_id: membershipLoyalty?.loyalty?.id,
                promotion: promotion,
                promotion_id: promotion?.id,
                loyalty_amount: loyaltyDiscount,
                promotion_amount: promotionDiscount,
                quantity: quantity,
                subtotal: subtotal,
                total: total
            };

            setCart((cart) => ([...cart, cartData]));
            handleClose();
    }

    function handleDeleteFromCart(index) {
        setDeleteItem(index);
        setTimeout(() => {
            setCart((cart) => cart.filter((_v, _i) => _i !== index));
            setDeleteItem(false);
        }, 300);
    }

    const constraints = {
        billing_name: {
            presence: { allowEmpty: false },
        },
        billing_phone: {
            presence: { allowEmpty: false },
        },
        billing_email: {
            presence: { allowEmpty: false },
            email: true
        }
    };

    useEffect(() => {
        handleFindOne();
    }, []);

    const CartItem = ({ data }) => {
        {
            
            const { membership } = data;

            return (
                <ListItem className={"border-b border-dashed border-slate-200"}>
                    <Box sx={{ flex: 1 }}>
                        <Typography sx={{ fontWeight: 600, mb: 1 }}>
                            {t(membership?.title)}
                        </Typography>
                        <Typography sx={{ mb: 1, fontSize: 14, whiteSpace: "pre-wrap" }}>
                            {t(membership?.description)}
                        </Typography>
                    </Box>
                </ListItem>
            );

        }
    };

    const headCells = [
        {
            id: "id",
            numeric: false,
            disablePadding: false,
            label: "ID",
        },
        {
            id: "membership",
            numeric: false,
            disablePadding: false,
            label: "Membership",
        },
        {
            id: "subtotal",
            numeric: false,
            disablePadding: false,
            label: "Subtotal",
        },
        {
            id: "loyalty_amount",
            numeric: false,
            disablePadding: false,
            label: "Loyalty",
        },
        {
            id: "promotion_amount	",
            numeric: false,
            disablePadding: false,
            label: "Promotion",
        },
        {
            id: "total",
            numeric: false,
            disablePadding: false,
            label: "Total",
        },
        {
            id: "actions",
            numeric: false,
            disablePadding: false,
            label: "Actions",
        },
    ];

    function EnhancedTableHead(props) {
        const { order, orderBy } = props;
        const { t } = useTranslation();
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <p className='font-bold'>
                                {t(headCell.label)}
                            </p>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const Row = ({ indexKey, rowData }) => {

        const {
            id,
            membership,
            subtotal,
            total,
            loyalty_amount,
            promotion_amount
        } = rowData;

        const [open, setOpen] = useState(false);

        return (
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={id}
                className='!text-sm'
                onClick={() => setOpen(!open)}
                sx={{ cursor: "pointer" }}>
                <TableCell>
                    <p>
                        {id}
                    </p>
                </TableCell>
                <TableCell>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center">
                        <Link to={"/admin/memberships/" + membership?.id}>
                            <p>
                                {membership?.title}
                            </p>
                        </Link>
                    </Stack>
                </TableCell>
                <TableCell>
                    <p>
                        { subtotal ? numberWithCommas(subtotal) + "₫" : "-"}
                    </p>
                </TableCell>
                <TableCell>
                    <p>
                        { loyalty_amount ? numberWithCommas(loyalty_amount) + "₫" : "-"}
                    </p>
                </TableCell>
                <TableCell>
                    <p>
                        { promotion_amount ? numberWithCommas(promotion_amount) + "₫" : "-"}
                    </p>
                </TableCell>
                <TableCell>
                    { total ? numberWithCommas(total) + "₫" : "-"}
                </TableCell>
                <TableCell sx={{ width: 120}}>
                    <LoadingButton loading={deleteItem === indexKey} onClick={() => handleDeleteFromCart(indexKey)}>
                        <IoTrash />
                    </LoadingButton>
                </TableCell>
            </TableRow>
        );
    };
    

    if (!pageInit) {
        return (
            <PageLoading />
        );
    }

    return (
        <Fragment>
            <Box sx={{ width: "100%" }}>
                <NavHeader
                    title={t("Update Order")}
                    breadcrumb={[
                        {
                            title: t("Update Order")
                        }
                    ]}
                    endAction={
                        <Stack direction="row" spacing={1}>
                            {
                                defaultValue?.payment_status !== "success" ? (
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        type='button'
                                        as={Link}
                                        size='small'
                                        to={"/payment/" + id}
                                    >
                                        {t("Checkout url")}
                                    </Button>
                                )  :  (
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size='small'
                                        type='button'
                                        onClick={() => setOpenApplySubscription(true)}
                                    >
                                    {t("Apply subscriptions")}
                                    </Button>
                                )
                            }
                        </Stack>
                    }
                    backLink="/admin/orders"
                />
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} lg={8}>
                            <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                {t("Billing information")}
                            </Typography>
                            <Paper sx={{ p: 3, mb: 3}}>
                                <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_name} required>
                                    <FormLabel>Your full name</FormLabel>
                                    <TextField
                                        name="billing_name"
                                        error={formError?.billing_name}
                                        defaultValue={defaultValue?.billing_name}
                                    />
                                    {formError?.billing_name ? <FormHelperText>{formError?.billing_name[0]}</FormHelperText> : ""}
                                </FormControl>
                                <div className="grid grid-cols-2 gap-5">
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_phone} required>
                                        <FormLabel>{t("Phone number")}</FormLabel>
                                        <TextField
                                            name="billing_phone"
                                            defaultValue={defaultValue?.billing_phone}
                                        />
                                        {formError?.billing_phone ? <FormHelperText>{formError?.billing_phone[0]}</FormHelperText> : ""}
                                    </FormControl>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_email} required>
                                        <FormLabel>{t("Email address")}</FormLabel>
                                        <TextField
                                            name="billing_email"
                                            defaultValue={defaultValue?.billing_email}
                                        />
                                        {formError?.billing_email ? <FormHelperText>{formError?.billing_email[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </div>
                                <div className="grid grid-cols-2 gap-5">
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_country}>
                                        <FormLabel>{t("Country")}</FormLabel>
                                        <Autocomplete
                                            options={Countries}
                                            name="billing_country"
                                            defaultValue={defaultValue?.billing_country}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        name="billing_country"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        {formError?.billing_country ? <FormHelperText>{formError?.billing_country[0]}</FormHelperText> : ""}
                                    </FormControl>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_city}>
                                        <FormLabel>{t("Billing City")}</FormLabel>
                                        <TextField
                                            name="billing_city"
                                            defaultValue={defaultValue?.billing_city}
                                        />
                                        {formError?.billing_city ? <FormHelperText>{formError?.billing_city[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </div>
                                <div className="grid grid-cols-2 gap-5">
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_district}>
                                        <FormLabel>{t("Billing District")}</FormLabel>
                                        <TextField
                                            name="billing_district"
                                            defaultValue={defaultValue?.billing_district}
                                        />
                                        {formError?.billing_district ? <FormHelperText>{formError?.billing_district[0]}</FormHelperText> : ""}
                                    </FormControl>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_ward}>
                                        <FormLabel>{t("Billing Ward")}</FormLabel>
                                        <TextField
                                            name="billing_ward"
                                            defaultValue={defaultValue?.billing_ward}
                                        />
                                        {formError?.billing_ward ? <FormHelperText>{formError?.billing_ward[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </div>
                                <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_address}>
                                    <FormLabel>{t("Billing address")}</FormLabel>
                                    <TextField
                                        name="billing_address"
                                        defaultValue={defaultValue?.billing_address}
                                    />
                                    {formError?.billing_address ? <FormHelperText>{formError?.billing_address[0]}</FormHelperText> : ""}
                                </FormControl>
                            </Paper>
                            <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                {t("Cart")}
                            </Typography>
                            <Paper sx={{ width: "100%", overflow: "hidden", mb: 3 }}>
                                <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                                    <Table
                                        stickyHeader aria-label="sticky table"
                                        size='medium'>
                                        <EnhancedTableHead
                                            numSelected={0}
                                            rowCount={cart?.length}
                                        />
                                        <TableBody>
                                            {
                                                Array.isArray(cart) && cart?.length ?
                                                    cart.map((row, index) => {
                                                        return (
                                                            <Row key={index} indexKey={index} rowData={row} />
                                                        );
                                                    }) : ""
                                            }
                                            <TableRow>
                                                <TableCell rowSpan={4} colSpan={3}/>
                                                <TableCell colSpan={2}>
                                                    <strong>{t("Subtotal")}</strong>
                                                </TableCell>
                                                <TableCell colSpan={2} align="right">
                                                    {numberWithCommas(cartSubTotal)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <strong>{t("Loyalty")}</strong>
                                                </TableCell>
                                                <TableCell colSpan={2} align="right">
                                                 {numberWithCommas(loyaltyDiscount)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <strong>{t("Promotion")}</strong>
                                                </TableCell>
                                                <TableCell colSpan={2} align="right">
                                                    {numberWithCommas(promotionDiscount)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <strong>{t("Total")}</strong>
                                                </TableCell>
                                                <TableCell colSpan={2} align="right">
                                                    {numberWithCommas(cartTotal)}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <Box sx={{ textAlign: "right", mb: 2 }}>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    sx={{ borderRadius: 0 }}
                                    onClick={handleOpen}>
                                    {t("Add to Cart")}
                                </Button>
                            </Box>
                            {
                                 Array.isArray(subscriptions) && subscriptions.length ? (
                                    <Fragment>
                                        <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                            {t("Subscriptions applied")}
                                        </Typography>
                                        <TableSubscription data={subscriptions}/> 
                                    </Fragment>
                                 ) : ""
                            }
                            {
                                Array.isArray(transactions) && transactions?.length ? 
                                    <Fragment>
                                         <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2, mt: 3 }}>
                                            {t("VNPAY transactions")}
                                        </Typography>
                                        <TableTransaction defaultData={transactions}/> 
                                    </Fragment>
                                : ""
                            }
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <div className="sticky top-0">
                                <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                            {t("Payment method")}
                                        </Typography>
                                        <Paper sx={{ p: 3, mb: 3}}>
                                            <FormControl sx={{ width: "100%" }}>
                                                <div 
                                                    onClick={() => {
                                                        setPaymentMethod("vietqr");
                                                    }}
                                                    className={classNames({
                                                    "flex gap-3 cursor-pointer bg-white rounded border border-slate-200 mb-3 py-1 px-2 items-center": true,
                                                    "bg-green-50 border !border-green-600 text-green-600 shadow": paymentMethod == "vietqr"
                                                })}>
                                                    <div className='flex-1'>
                                                        <p>{t("Bank transfer")}</p>
                                                    </div>
                                                    <div>
                                                        <img width={34} height={34} src={Bank}/>
                                                    </div>
                                                </div>
                                                <div 
                                                    onClick={() => {
                                                        setPaymentMethod("vnpay");
                                                        setPaymentType("VNPAYQR");
                                                    }}
                                                    className={classNames({
                                                    "flex gap-3 cursor-pointer bg-white rounded border border-slate-200 mb-3 py-1 px-2 items-center": true,
                                                    "bg-green-50 border !border-green-600 text-green-600 shadow": paymentMethod == "vnpay" && paymentType == "VNPAYQR"
                                                })}>
                                                    <div className='flex-1'>
                                                    <p>{t("Payment code scanning application supported by VNPAY")}</p>
                                                    </div>
                                                    <div>
                                                        <img width={34} height={34} src={Qrcode}/>
                                                    </div>
                                                </div>
                                                <div 
                                                    onClick={() => {
                                                        setPaymentMethod("vnpay");
                                                        setPaymentType("VNBANK");
                                                    }}
                                                    className={classNames({
                                                    "flex gap-3 cursor-pointer bg-white rounded border border-slate-200 mb-3 py-1 px-2 items-center": true,
                                                    "bg-green-50 border !border-green-600 text-green-600 shadow": paymentMethod == "vnpay" && paymentType == "VNBANK"
                                                })}>
                                                    <div className='flex-1'>
                                                        <p>{t("Domestic card and bank account")}</p>
                                                </div>
                                                <div>
                                                        <img width={34} height={34} src={ATM}/>
                                                    </div>
                                                </div>
                                                <div 
                                                    onClick={() => {
                                                        setPaymentMethod("vnpay");
                                                        setPaymentType("INTCARD");
                                                    }}
                                                    className={classNames({
                                                    "flex gap-3 cursor-pointer bg-white rounded border border-slate-200 mb-3 py-1 px-2 items-center": true,
                                                    "bg-green-50 border !border-green-600 text-green-600 shadow": paymentMethod == "vnpay" && paymentType == "INTCARD"
                                                })}>
                                                    <div className='flex-1'>
                                                        <p>{t("International payment card")}</p>
                                                        <img src={CreditLine}/>
                                                </div>
                                                <div>
                                                    <img width={34} height={34} src={Credit}/>
                                                </div>
                                            </div>
                                        </FormControl>
                                    </Paper>
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                        {t("Payment status")}
                                    </Typography>
                                    <Select
                                        name="payment_status"
                                        value={defaultValue?.payment_status}
                                        onChange={(e) => setDefaultValue((defaultValue) => ({...defaultValue, payment_status: e.target.value }))}
                                        fullWidth>
                                        <MenuItem value="pending">{t("Transaction Pending")}</MenuItem>
                                        <MenuItem value="success">{t("Transaction Successful")}</MenuItem>
                                        <MenuItem value="failed">{t("Transaction Failed")}</MenuItem>
                                        <MenuItem value="refunded">{t("Refunded")}</MenuItem>
                                    </Select>
                                </Box>
                                <Box sx={{ mb: 1 }}>
                                    <PostPicker
                                        label={t("Select promotion")}
                                        name="promotion"
                                        defaultData={promotion}
                                        values={[promotion]}
                                        getApi={findManyPromotions}
                                        onChange={(value) => handleChangePromotion(value)}
                                        optionKey="promotion_code"
                                        previewKey="id"
                                    />
                                </Box>
                                <Box sx={{ mb: 1 }}>
                                    <PostPicker
                                        label={t("Select user")}
                                        name="user"
                                        defaultData={user}
                                        values={[user]}
                                        getApi={findManyUsers}
                                        required
                                        onChange={(value) => setUser(value)}
                                        optionKey="email"
                                        previewKey="id"
                                    />
                                </Box>
                                <FormControl sx={{ width: "100%", mb: 3 }}>
                                    <FormControlLabel
                                        control={<Switch />}
                                        label={t("Resend the order confirmation email to the customer")}
                                        name="sent_notify"
                                    />
                                </FormControl>
                                <FormControl>
                                    <Stack direction="row" alignItems="center" spacing={3}>
                                        <LoadingButton
                                            variant='contained'
                                            color='primary'
                                            size='lg'
                                            type='submit'
                                            loading={isLoading}
                                        >
                                            {t("Update order")}
                                        </LoadingButton>
                                    </Stack>
                                </FormControl>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 24,
                        textTransform: "uppsercase"
                    }}>
                        {t("Add to cart")}
                    </Typography>
                    <form onSubmit={handleAddToCart}>
                        <Box sx={{ mb: 1 }}>
                            <PostPicker
                                label="Select membership"
                                name="membership"
                                defaultValue
                                getApi={findManyMemberships}
                                defaultParams={{
                                    user_id: user?.id
                                }}
                                onChange={(value) => setAddToCart(value)}
                                optionKey="title"
                            />
                        </Box>
                        <FormControl >
                            <Button 
                                variant='contained' 
                                sx={{ borderRadius: 0, background: "#000", mt: 2, width: "100%"}} 
                                type="submit">
                                {t("Submit")}
                            </Button>
                        </FormControl>
                    </form>
                </Box>
            </Modal>

            <Modal
                open={openApplySubscription}
                onClose={() => setOpenApplySubscription(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 24,
                        textTransform: "uppsercase"
                    }}>
                        {t("Apply subscriptions")}
                    </Typography>
                    {
                        Array.isArray(defaultCart) && defaultCart?.length ? (
                            <Box>
                                <List>
                                    {
                                        defaultCart.map((val) => (
                                            <CartItem key={val.id} data={val}/>
                                        ))
                                    }
                                </List>
                                <LoadingButton 
                                    variant='contained' 
                                    sx={{mt: 2, width: "100%"}}
                                    loading={isLoadingApplySubscription}
                                    onClick={() => handleApplySubscription()} 
                                    type="submit">
                                    {t("Submit")}
                                </LoadingButton>
                            </Box>
                        ) : null
                    }
                </Box>
            </Modal>
        </Fragment>
    );
};

export default UpdateOrder;
