import { useRouteError } from "react-router-dom";


const ErrorBoundary = () => {

  let error = useRouteError();

  return (
      <div className="error-page">
        <div className="item">
            <img />
            <div className="text">
                <h1 className="error-page-code">500</h1>
                <p className="error-page-title">Rất tiếc… Bạn vừa tìm thấy một trang lỗi</p>
                <p><strong>Lỗi ứng dụng:</strong> <i>${error?.message}</i></p>
                <p className="error-page-subtitle text-muted" style={{marginBottom: 35}}>
                    Chúng tôi rất tiếc nhưng máy chủ của chúng tôi đã gặp lỗi nội bộ
                </p>
            </div>
        </div>
    </div>
  );
};

export default ErrorBoundary;