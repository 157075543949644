
import { findOneSubscription, updateSubscription } from "@/utils/Subscriptions";
import { useToasterContext } from "@/utils/ToasterContext";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import PostPicker from "@/components/PostPicker";
import { findManyMemberships } from "@/utils/Memberships";
import { findManyUsers } from "@/utils/Users";
import { Button, FormControlLabel, MenuItem, Select, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import validate from "validate.js";

const UpdateSubscriptionPage = () => {

    const { id } = useParams();
    
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [defaultValue, setDefaultValue] = useState(false);
    const [formError, setFormError] = useState(false);
    const [user, setUser] = useState(null);
    const [membership, setMembership] = useState(null);
    const [isTriggerActive, setIsTriggerActive] = useState(0);
    const navigate = useNavigate();
    const { t } = useTranslation();

    async function handleFindOne() {
        try {
            const response = await findOneSubscription(id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        user_id,
                        membership_id,
                        start_date,
                        end_date,
                        max_sessions,
                        user,
                        membership,
                        trigger_active,
                        status,
                    } = data;
                    setUser(user);
                    setMembership(membership);
                    setIsTriggerActive(trigger_active);
                    setDefaultValue({
                        user_id,
                        membership_id,
                        start_date,
                        end_date,
                        max_sessions,
                        status,
                    });
                }
            }
        } catch (error) {
            console.log("error", error);
        } 
    }

    async function handleUpdate(id, data) {
        try {
            setIsLoading(true);
            startProgress();
            const notify = data?.sent_notify;
            const response = await updateSubscription(id, data);
            if (response && !response.error) {
                const { data } = response;
                if (data?.id) {
                    showMessage("success", t("Data updated successfully!"));
                    if(notify){
                        showMessage("success", t("Registration information has been sent to the user's email"));
                    }
                    location.reload();
                } else {
                    showMessage("error", t("An error occurred, please try again later!"));
                }
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try again later!"));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const notify = data.get("sent_notify");

        let dataForm = {
            user_id: user?.id,
            membership_id: membership?.id,
            max_sessions: data.get("max_sessions"),
            status: data.get("status"),
            trigger_active: isTriggerActive ? 1 : 0,
            sent_notify: notify == "on" ? 1 : 0
        };

        if(!isTriggerActive) {
            dataForm = {
                ...dataForm,
                start_date: dayjs(data.get("start_date"), "DD/MM/YYYY").format("YYYY-MM-DD"),
                end_date: dayjs(data.get("end_date"), "DD/MM/YYYY").format("YYYY-MM-DD"),
            };
        }

        const error = validate(dataForm, constraints);

        if (!error) {
            handleUpdate(id, dataForm);
        } else {
            console.log("error", error);
            showMessage("error", t("One or more input fields are invalid!"));
        }

        setFormError(error);
    }

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startDate = dayjs(attributes.start_date, "YYYY-MM-DD");
        const endDate = dayjs(value, "YYYY-MM-DD");
        if (endDate.isBefore(startDate)) {
            return options.message || t("must be after start time");
        }
    };

    let constraints = {
        user_id: {
            presence: true,
        },
        membership_id: {
            presence: true,
        },
        max_sessions: {
            presence: true,
        },
        status: {
            presence: true,
        }
    };

    if(!isTriggerActive){
        constraints = {
            ...constraints,
            start_date: {
                presence: { allowEmpty: false, message: "is required" },
            },
            end_date: {
                presence: { allowEmpty: false, message: "is required" },
                timeAfter: {
                    message: t("must be after start time")
                }
            },
        };
    }

    useEffect(() => {
        handleFindOne();
    }, []);


    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <NavHeader
                title={t("Update Subscription")}
                breadcrumb={[
                    {
                        title: t("Subscriptions"),
                        link: "/admin/subscriptions/"
                    },
                    {
                        title: t("Update Subscription")
                    }
                ]}
                endAction={
                     <Button
                        variant='contained'
                        onClick={() => navigate(`/admin/subscriptions/${id}/detail`)}>
                        {t("View detail")}
                    </Button>
                }
                backLink="/admin/subscriptions"
            />
            <Box  component="form" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <Box sx={{ mb: 1 }}>
                            <PostPicker
                                label={t("Select User")}
                                name="user_id"
                                defaultData={user}
                                getApi={findManyUsers}
                                values={[user]}
                                onChange={(value) => {
                                    setUser(value);
                                }}
                                required
                                optionKey="email"
                                previewKey="id"
                            />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <PostPicker
                                label={t("Select membership")}
                                name="membership_id"
                                defaultData={membership}
                                getApi={findManyMemberships}
                                onChange={(value) => {
                                    setMembership(value);
                                }}
                                required
                                optionKey="title"
                                previewKey="is_private"
                            />
                        </Box>
                        <FormControl sx={{mb: 2}}>
                            <FormControlLabel
                                control={<Switch />}
                                checked={isTriggerActive}
                                onChange={() => setIsTriggerActive(!isTriggerActive)}
                                label={t("Automatically activate membership after registering for a session")} />
                        </FormControl>
                        { 
                            !isTriggerActive ? (
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_date} required>
                                            <FormLabel>{t("Start date")}</FormLabel>
                                            <DatePicker
                                                name="start_date"
                                                value={dayjs(defaultValue?.start_date, "YYYY-MM-DD")}
                                            />
                                            {formError?.start_date ? <FormHelperText>{formError?.start_date[0]}</FormHelperText> : ""}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.end_date} required>
                                            <FormLabel>{t("End date")}</FormLabel>
                                            <DatePicker
                                                name="end_date"
                                                value={dayjs(defaultValue?.end_date, "YYYY-MM-DD")}
                                            />
                                            {formError?.end_date ? <FormHelperText>{formError?.end_date[0]}</FormHelperText> : ""}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            ) : ""
                        }
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.max_sessions} required>
                            <FormLabel>{t("Max sessions (-1 is unlimited)")}</FormLabel>
                            <TextField
                                name="max_sessions"
                                type="number"
                                error={formError?.max_sessions}
                                defaultValue={defaultValue?.max_sessions}
                            />
                            {formError?.duration_days ? <FormHelperText>{formError?.duration_days[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.max_sessions} required>
                            <FormLabel>{t("Max sessions booked (permonth)")}</FormLabel>
                            <TextField
                                name="max_sessions"
                                type="number"
                                error={formError?.max_sessions}
                                defaultValue={defaultValue?.max_sessions}
                            />
                            {formError?.duration_days ? <FormHelperText>{formError?.duration_days[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.status} required>
                            <FormLabel>{t("Status")}</FormLabel>
                            <Select
                                name="status"
                                error={formError?.status}
                                defaultValue={defaultValue?.status}
                            >
                                <MenuItem value="active">{t("Active")}</MenuItem>
                                <MenuItem value="freeze">{t("freeze")}</MenuItem>
                                <MenuItem value="inactive">{t("Inactive")}</MenuItem>
                            </Select>
                            {formError?.status ? <FormHelperText>{formError?.status[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormControlLabel
                                control={<Switch />}
                                label={t("Sent an email notify to subscription")}
                                name="sent_notify"
                            />
                        </FormControl>
                        <FormControl>
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                size='lg'
                                type='submit'
                                loading={isLoading}>
                                {t("Update subscription")}
                            </LoadingButton>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default UpdateSubscriptionPage;
