import { numberWithCommas, objectToQueryString } from "@/utils/Hooks";
import { createTransaction } from "@/utils/Orders";
import { getSetings } from "@/utils/Settings";
import { useToasterContext } from "@/utils/ToasterContext";
import { findOneUserOrder } from "@/utils/UserInfomations";
import { LoadingButton } from "@mui/lab";
import { Button, FormControl, FormLabel, List, ListItem } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import CreditLine from "@/assets/176x24-credit.svg";
import { default as ATM, default as Credit } from "@/assets/64x64-atm.svg";
import Bank from "@/assets/64x64-bank.svg";
import Qrcode from "@/assets/64x64-vi-vnpay.svg";
import PageLoading from "@/components/PageLoading";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import classNames from "classnames";

const UserOrders = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    
    const [data, setData] = useState({});
    const [pageStatus, setPageStatus] = useState("idle");
    const [cart, setCart] = useState(false);
    const { startProgress, completeProgress } = useToasterContext();
    const [isLoaded, setIsLoaded] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("vietqr");
    const [paymentType, setPaymentType] = useState(false);
    const [loadingTransaction, setLoadingTransaction] = useState(false);
    const [promotion, setPromotion] = useState(false);

    const [qrData, setQrData] = useState({
        bank_id: "",
        bank_account_name: "",
        bank_account_number: "",
        bank_account_description: "",
    });

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    async function handleGetSettings() {
        try {
            startProgress();
            const response = await getSetings({
                settings: [
                    "bank_id",
                    "bank_account_name",
                    "bank_account_number",
                    "bank_account_description"
                ],
            });
            if (Array.isArray(response) && response.length) {
                let updateData = {};
                response.map((val) => {
                    updateData[val.setting_key] = val.setting_value;
                });
                setQrData((qrData) => ({
                    ...qrData,
                    ...updateData
                }));
            }
        } catch (error) {
            console.error("error", error);
        } finally {
            completeProgress();
            setIsLoaded(true);
        }
    }

    const handleFindData = async () => {
        try {
            startProgress();
            const response = await findOneUserOrder(id);
            if (response && !response.error) {
                const { data } = response;
                if(data){
                    const { cart, payment_method, payment_type, promotion } = data;
                    setPromotion(promotion);
                    setData(data);
                    setCart(cart);
                    setPaymentMethod(payment_method);
                    setPaymentType(payment_type);
                } 
            }
            setPageStatus("complete");
        } catch (error) {
            console.log("error", error);
        } finally {
            completeProgress();
        }
    };

    const handleCreateTransaction = async () => {
        try {
            setLoadingTransaction(true);
            const data = {
                payment_method: paymentMethod,
                payment_type: paymentType,
            };
            const response = await createTransaction(id, data);
            if(response && !response.error){
                const { type, url } = response;
                if(url){
                   location.href = url;
                }
            }
        } catch (error) {
            console.error("error", error);
        } finally {
            setLoadingTransaction(false);
        }
    };

    useEffect(() => {
        handleGetSettings();
        handleFindData();
    }, []);

    const CartItem = ({ data }) => {
        {
            const {
                id,
                membership,
                promotion,
                loyalty,
                subtotal,
                total,
                quantity,
                loyalty_amount,
                promotion_amount
            } = data;

            return (
                <ListItem
                    key={id}
                    className={"border-b border-dashed border-slate-200 !py-5"}>
                    <Box sx={{ flex: 1 }}>
                        <h3 className='font-bold text-lg'>
                            {t(membership?.title)} x {quantity}
                        </h3>
                        <div className="text-sm">
                            <p>
                                {t("Subtotal")}: {subtotal ? numberWithCommas(subtotal) + "₫" : ""} 
                            </p>
                            {
                                loyalty_amount && loyalty ? (
                                    <div className="text-sm text-red-500">
                                        -{numberWithCommas(loyalty_amount) + "₫"} <span className="text-gray-600">({loyalty?.title})</span>
                                    </div>
                                ) : ""
                            }
                            {
                                promotion_amount && promotion ? (
                                    <div className="text-sm text-red-500">
                                        -{numberWithCommas(promotion_amount) + "₫"} <span className="text-gray-600">({promotion?.promotion_code})</span>
                                    </div>
                                ) : ""
                            }
                            <p>
                                <strong className='mr-2'>{t("Total")}: {numberWithCommas(total)}₫</strong>
                            </p>
                        </div>
                    </Box>
                </ListItem>
            );

        }
    };


    if (pageStatus != "complete") {
        return (
            <PageLoading />
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Grid container spacing={4} sx={{ width: "100%" }}>
                <Grid xs={12} lg={6}>
                    <Typography sx={{ fontSize: 22, fontWeight: 600, textTransform: "uppercase" }}>
                        {t("Order Information")}
                    </Typography>
                    <div className="sticky top-[80px] border border-black p-3">
                        <List>
                            <ListItem
                                className='border-b border-dashed border-slate-200 !ps-0'>
                                <Typography sx={{ mb: 1 }}>
                                    {t("Billing name")}: <strong>{data?.billing_name}</strong>
                                </Typography>
                            </ListItem>
                            <ListItem
                                className='border-b border-dashed border-slate-200 !ps-0'>
                                <Typography sx={{ mb: 1 }}>
                                    {t("Phone number")}: <strong>{data?.billing_phone}</strong>
                                </Typography>
                            </ListItem>
                            <ListItem
                                className='border-b border-dashed border-slate-200 !ps-0'>
                                <Typography sx={{ mb: 1 }}>
                                    {t("Email address")}: <strong>{data?.billing_email}</strong>
                                </Typography>
                            </ListItem>
                            <ListItem
                                className='border-b border-dashed border-slate-200 !ps-0'>
                                <Typography sx={{ mb: 1 }}>
                                    {t("Country")}: <strong>{data?.billing_country}</strong>
                                </Typography>
                            </ListItem>
                            <ListItem
                                className='border-b border-dashed border-slate-200 !ps-0'>
                                <Typography sx={{ mb: 1 }}>
                                    {t("City")}: <strong>{data?.billing_city}</strong>
                                </Typography>
                            </ListItem>
                            <ListItem
                                className='border-b border-dashed border-slate-200 !ps-0'>
                                <Typography sx={{ mb: 1 }}>
                                    {t("District")}: <strong>{data?.billing_district}</strong>
                                </Typography>
                            </ListItem>
                            <ListItem
                                className='border-b border-dashed border-slate-200 !ps-0'>
                                <Typography sx={{ mb: 1 }}>
                                    {t("Ward")}: <strong>{data?.billing_ward}</strong>
                                </Typography>
                            </ListItem>
                            <ListItem
                                className='!ps-0'>
                                <Typography sx={{ mb: 1 }}>
                                    {t("Address")}: <strong>{data?.billing_address}</strong>
                                </Typography>
                            </ListItem>
                        </List>
                    </div>
                </Grid>
                <Grid xs={12} lg={6}>
                    <Typography sx={{ fontSize: 22, fontWeight: 600, textTransform: "uppercase" }}>
                        {t("Cart Information")}
                    </Typography>
                    <List sx={{p: 0}}>
                        {
                            Array.isArray(cart) && cart?.length ? (
                                <Fragment>
                                    <Box sx={{ borderColor: "#000", borderWidth: 1, px: 3, mb: 3}}>
                                        <List sx={{ mb: 2}}>
                                            {
                                                cart.map((val) => {
                                                    return (
                                                        <CartItem key={val.id} id={val.id} data={val} promotion={promotion} />
                                                    );
                                                })
                                            }
                                        </List>
                                        {
                                            promotion ?  (
                                                <Fragment>
                                                    <Typography sx={{ mt: 2 }}>
                                                        {t("Promotion code")}: <strong className='ml-2'>{promotion?.promotion_code}</strong>
                                                    </Typography>
                                                </Fragment>
                                            ) : ""
                                        }
                                         <Typography sx={{ mt: 2, mb: 3 }}>
                                            {t("Total")}: <strong className='ml-2'>{numberWithCommas(data?.billing_total)}₫</strong>
                                        </Typography>
                                    </Box>
                                </Fragment>
                            ) : (
                                <Box sx={{ textAlign: "center", py: 3 }}>
                                    <AddShoppingCartIcon sx={{ width: 80, height: 80, mb: 1 }} />
                                    <Typography sx={{ mb: 3 }}>{t("Cart empty")}</Typography>
                                    <Link to="/memberships/">
                                        <Button variant='contained' color="secondary">
                                            {t("Return to memberships")}
                                        </Button>
                                    </Link>
                                </Box>
                            )
                        }
                    </List>
                    <Typography sx={{ fontSize: 22, fontWeight: 600, textTransform: "uppercase" }}>
                        {t("Payment method")}
                    </Typography>
                    <List sx={{ mb: 3 }}>
                        {
                            data?.payment_status ? (
                                <ListItem
                                    className='border-b border-dashed border-slate-200 !ps-0'>
                                    <Typography sx={{ mb: 1 }}>
                                        {t("Payment Status")}:
                                        <div
                                            className={classNames("inline-block text-white px-1 text-sm uppercase-full ml-3 capitalize", {
                                                "bg-red-600": data.payment_status == "failed",
                                                "bg-yellow-600": data.payment_status == "pending",
                                                "bg-green-600": data.payment_status == "success",
                                            })}>
                                                {t("Payment")} {t(data.payment_status)}
                                            </div>
                                    </Typography>
                                </ListItem>
                            ) : ""
                        }
                        {data?.payment_status != "success" ? (
                            <ListItem
                            className='!ps-0'>
                            <Box>
                                <Box>
                                <FormControl sx={{ width: "100%", mb: 3 }}>
                                    <FormLabel>{t("Payment method")}</FormLabel>
                                    <div 
                                        onClick={() => {
                                            setPaymentMethod("vietqr");
                                        }}
                                        className={classNames({
                                        "flex gap-3 cursor-pointer bg-white border border-slate-200 mb-3 p-1 items-center": true,
                                        "bg-green-50 border !border-green-600 text-green-600 shadow": paymentMethod == "vietqr"
                                    })}>
                                        <div className='flex-1'>
                                            <p>{t("Bank transfer")}</p>
                                        </div>
                                        <div>
                                            <img width={34} height={34} src={Bank}/>
                                        </div>
                                    </div>
                                    <div 
                                        onClick={() => {
                                            setPaymentMethod("vnpay");
                                            setPaymentType("VNPAYQR");
                                        }}
                                        className={classNames({
                                        "flex gap-3 cursor-pointer bg-white border border-slate-200 mb-3 p-1 items-center": true,
                                        "bg-green-50 border !border-green-600 text-green-600 shadow": paymentMethod == "vnpay" && paymentType == "VNPAYQR"
                                    })}>
                                        <div className='flex-1'>
                                        <p>{t("Payment code scanning application supported by VNPAY")}</p>
                                        </div>
                                        <div>
                                            <img width={34} height={34} src={Qrcode}/>
                                        </div>
                                    </div>
                                    <div 
                                        onClick={() => {
                                            setPaymentMethod("vnpay");
                                            setPaymentType("VNBANK");
                                        }}
                                        className={classNames({
                                        "flex gap-3 cursor-pointer bg-white border border-slate-200 mb-3 p-1 items-center": true,
                                        "bg-green-50 border !border-green-600 text-green-600 shadow": paymentMethod == "vnpay" && paymentType == "VNBANK"
                                    })}>
                                        <div className='flex-1'>
                                            <p>{t("Domestic card and bank account")}</p>
                                    </div>
                                    <div>
                                        <img width={34} height={34} src={ATM}/>
                                    </div>
                                    </div>
                                    <div 
                                        onClick={() => {
                                            setPaymentMethod("vnpay");
                                            setPaymentType("INTCARD");
                                        }}
                                        className={classNames({
                                        "flex gap-3 cursor-pointer bg-white border border-slate-200 mb-3 p-1 items-center": true,
                                        "bg-green-50 border !border-green-600 text-green-600 shadow": paymentMethod == "vnpay" && paymentType == "INTCARD"
                                    })}>
                                    <div className='flex-1'>
                                        <p>{t("International payment card")}</p>
                                        <img src={CreditLine}/>
                                    </div>
                                    <div>
                                            <img width={34} height={34} src={Credit}/>
                                        </div>
                                    </div>
                                </FormControl>
                                </Box>
                                {
                                    paymentMethod == "vietqr" ? (
                                        <Box>
                                            <Typography sx={{ mb: 1 }}>
                                                {t("Use an e-wallet or banking app to scan the payment code for the order")}
                                            </Typography>
                                            <Box>
                                                {!isLoaded && <p>Loading...</p>}
                                                {
                                                    data?.billing_total && qrData ?
                                                        <img
                                                            style={{ display: isLoaded ? "block" : "none" }}
                                                            onLoad={handleImageLoad}
                                                            src={objectToQueryString({
                                                                ...qrData,
                                                                accountName: "Cong Ty Move Fitness",
                                                                amount: data?.billing_total,
                                                                addInfo: `MOVE${data?.id}`
                                                            })} /> : ""
                                                }
                                                <div className="border border-slate-200 bg-slate-50 p-3 mb-3">
                                                    <p className='font-bold'>MOVE FITNESS COMPANY LIMITED</p>
                                                    <p>{t("Bank Name")}: <strong>Techcombank</strong></p>
                                                    <p>{t("Account Number")}: <strong>881122</strong></p>
                                                    <p>{t("Total")}: <strong>{numberWithCommas(data?.billing_total)}đ</strong></p>
                                                    <p>{t("Payment message")}: <strong>Move{id}</strong></p>
                                                </div>
                                                <Typography sx={{ fontSize: 14 }}>
                                                    <span className="text-red-600">*</span> {t("Please use the provided payment details for a manual transfer. We will promptly update the payment status and send an order confirmation email to your sign-in email address. Thank you.")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) : ""
                                }
                                {
                                    paymentMethod == "vnpay" ? (
                                        <Box>
                                            <p className='mb-3'>
                                                <span className="text-red-600">*</span> {t("By clicking Proceed to payment gateway, you agree to our")} <Link className='underline' to="https://movevn.com/termconditions/" target='_blank'>{t("terms and conditions")}</Link> {t("of service")}.
                                            </p>
                                            <LoadingButton 
                                                loading={loadingTransaction}
                                                onClick={handleCreateTransaction}
                                                variant='contained'
                                                sx={{borderRadius: 0}}
                                                color='secondary'>
                                                {t("Proceed to payment gateway")}
                                            </LoadingButton>
                                        </Box>
                                    ) : ""
                                }
                            </Box>
                        </ListItem>
                        ): ""}
                    </List>
                </Grid>
            </Grid>
        </Box >
    );
};

export default UserOrders;
