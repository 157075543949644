import axios from "axios";
import { getCookie } from "./Hooks";

const APP_URL = process.env.REACT_APP_APP_URL + "/api";

export const instance = axios.create({
    baseURL: APP_URL,
});

instance.interceptors.request.use(
    (config) => {
        const token = getCookie("li_at");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status === 403) {
            location.href = "/login"; 
        }
        return Promise.reject(error);
    }
);