import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cart: [],
    status: "idle" || "loading" || "complete"
};

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        updateCartData: (state, action) => {
            state.cart = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    updateCartData
} = cartSlice.actions;

export default cartSlice.reducer;