import { Box, Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";
import dayjs from "dayjs";

import isBetween from "dayjs/plugin/isBetween";
import { useTranslation } from "react-i18next";

// Kích hoạt plugin
dayjs.extend(isBetween);

const WeekView = ({ data, step }) => {

    const { t } = useTranslation();
    const [weekDays, setWeekDays] = useState([]);
    const [weekData, setWeekData] = useState({
        sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: []
    });

    useEffect(() => {
        const startOfWeek = dayjs().startOf("week").add(step, "week"); 
        setWeekDays(Array.from({ length: 7 }, (_, i) => 
            startOfWeek.add(i, "day").format("DD/MM")
        ));
    }, [step]);



    useEffect(() => {
        const updatedWeekData = {
            sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: []
        };

        data?.forEach(item => {
            const dayKey = dayjs(item.start_time).format("ddd").toLowerCase();
            updatedWeekData[dayKey]?.push(item);
        });

        setWeekData(updatedWeekData);
    }, [data]);

    const daysOfWeek = {
        sun: t("Sunday"),
        mon: t("Monday"),
        tue: t("Tuesday"),
        wed: t("Wednesday"),
        thu: t("Thursday"),
        fri: t("Friday"),
        sat: t("Saturday"),
    };

    return (
       <Fragment>
           <Box 
                sx={{ 
                minHeight: 480,
                overflow: "auto",
                maxHeight: {
                    lg: 999,
                    xs: 480
                }, maxWidth: "calc(100vw - 54px)" }}>
                <Grid sx={{ minWidth: 1280 }} container spacing={1}>
                    {Object.keys(daysOfWeek).map((dayKey, index) => {

                        const currentDayString = dayjs().format("DD/MM");
                        const currentDay = dayjs(currentDayString, "DD/MM");
                        const compareDay = dayjs(weekDays[index], "DD/MM");
                        const add_days = dayjs().startOf("week").add(`${index}`, "days");

                        let compare = false;

                        if (compareDay.isBefore(currentDay)) {
                            compare = "before";
                        } else if (compareDay.isAfter(currentDay)) {
                            compare = "after";
                        } else {
                            compare = "same";
                        }

                        return (
                            <Grid
                                key={dayKey}
                                sx={{ flex: 1 }}
                                item>
                                <Box
                                    className={{
                                        before: "shadow bg-white",
                                        same: "shadow bg-white",
                                        after: "shadow bg-white"
                                    }[compare]}>
                                    <Box className={{
                                        before: "bg-neutral-300 sticky top-0 z-10 text-neutral-900 py-3",
                                        same: "bg-blue-700 sticky top-0 z-10 text-white py-3",
                                        after: "bg-neutral-900 sticky top-0 z-10 text-white py-3"
                                    }[compare]}>
                                        <Typography
                                            sx={{
                                                fontWeight: 700,
                                                textTransform: "uppercase",
                                                textAlign: "center",
                                                fontSize: 14
                                            }}>
                                            {daysOfWeek[dayKey]} {weekDays[index]}
                                        </Typography>
                                        <p className='text-xs text-center'>{weekData[dayKey]?.length > 2 ? `${weekData[dayKey].length } ${t("sessions")}`: `${weekData[dayKey].length } ${t("session")}`}</p>
                                    </Box>
                                    {
                                        Array.isArray(weekData[dayKey]) && weekData[dayKey].length ?
                                            weekData[dayKey].sort((a, b) => dayjs(a.start_time).unix() - dayjs(b.start_time).unix()).map((val) => {
                                                const { id, trainer, class_sessions, start_time, end_time, duration_minutes, is_private, capacity, class_types } = val;
                                                const classInfo = val.class;
                                                const start = dayjs(start_time);
                                                const end = dayjs(end_time);
                                                const isActive = dayjs().isBefore(start);
                                                const isInBetween = dayjs().isBetween(start, end);
                                                const activeUser = Array.isArray(class_sessions) && class_sessions?.length ? class_sessions.filter((val) => val.status == "active"): [];
                                                const candeledUser = Array.isArray(class_sessions) && class_sessions?.length ? class_sessions.filter((val) => val.status == "canceled"): [];
                                                return (
                                                    <div
                                                        className={classNames({
                                                            "bg-white relative hover:bg-neutral-100 cursor-pointer border py-2 relative group": true,
                                                            "border-neutral-200 text-neutral-900": compare === "before",
                                                            "border-neutral-100": compare === "after"
                                                        })}
                                                        key={id}>
                                                        <Link
                                                            to={"/hoi/sessions/" + id + "?step=" + step}
                                                            size="small">
                                                            <Box sx={{ p: 1 }}>
                                                                {
                                                                    classInfo ?
                                                                        <p className='font-bold mb-2 text-md uppercase'>
                                                                            <strong>{class_types ? class_types : classInfo?.title}</strong>
                                                                        </p>
                                                                        : ""
                                                                }
                                                                {
                                                                    trainer ?
                                                                        <p className="text-xs mb-1">
                                                                            {t("with")} <strong>{trainer?.full_name}</strong>
                                                                        </p>
                                                                        : ""
                                                                }
                                                                {
                                                                    class_sessions ?
                                                                        <p className="text-xs mb-1">
                                                                            {t("Capacity")}: {activeUser.length} / {capacity}
                                                                        </p>
                                                                        : ""
                                                                }
                                                                {
                                                                    candeledUser?.length ? 
                                                                        <p className="text-xs mb-1">
                                                                            {candeledUser.length} {t("canceled")}
                                                                        </p> : ""
                                                                }
                                                                {
                                                                    start ?
                                                                        <p className="text-xs mb-1">
                                                                            {start.format("HH:mm")} - {end.format("HH:mm")}
                                                                        </p>
                                                                        : ""
                                                                }
                                                                {
                                                                    duration_minutes ?
                                                                        <p className="text-xs mb-1">
                                                                            {t("Duration")}: <strong>{duration_minutes} {t("minutes")}</strong>
                                                                        </p>
                                                                        : ""
                                                                } 
                                                                <div className="flex gap-1 mt-3">
                                                                    { isInBetween ? (<span className='border border border-black px-1 text-xs uppercase !text-[8px]'>{t("Ongoing")}</span>) : !isActive ? <span className='border border border-black px-1 text-xs uppercase !text-[8px]'>{t("Passed")}</span> : ""}
                                                                    {
                                                                        is_private ? <span className='border border border-black px-1 text-xs uppercase !text-[8px]'>
                                                                            {t("Private")}
                                                                        </span> : ""
                                                                    }
                                                                </div>
                                                            </Box>
                                                        </Link>
                                                    </div>
                                                );
                                            })
                                            : (
                                                <Box className="py-3">
                                                    <Typography sx={{ fontSize: 12, textAlign: "center" }}>
                                                        {t("Empty")}
                                                    </Typography>
                                                </Box>
                                            )
                                    }
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>            
            </Box>
       </Fragment>
    );
};

export default WeekView;