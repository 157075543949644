import { Fragment, useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { getInitials } from "@/utils/Hooks";
import { Avatar, Button, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


const APP_URL = process.env.REACT_APP_APP_URL;

function EnhancedTableHead(props) {
    const { order, orderBy } = props;
    const { t } = useTranslation();
    const { user } = useSelector((state) => state.auth);

    let headCells = [
        {
            id: "trainer",
            numeric: false,
            disablePadding: false,
            label: "Instructor",
        },
        {
            id: "latest-seen",
            numeric: false,
            disablePadding: false,
            label: "Sessions",
        },
        {
            id: "Total hours",
            numeric: false,
            disablePadding: false,
            label: "Total hours",
        }
    ];

    if(user?.role == "administrator"){
        headCells = [
            ...headCells,
            {
                id: "action",
                numeric: false,
                disablePadding: false,
                label: "Action",
            },
        ];
    } 

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <p className="font-bold">
                            {t(headCell.label)}
                        </p>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function EnhancedTable({ data }) {

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");
    const [selected, setSelected] = useState([]);

    const { user } = useSelector((state) => state.auth);

    const roles = user?.roles;

    let role = null;

    if(Array.isArray(roles) && roles?.length){
        role = roles[0];
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;


    const Row = ({ rowData }) => {
        const {
            id,
            full_name,
            total_hours,
            thumbnail_url,
            total_sessions
        } = rowData;

        const { t } = useTranslation();

        const isItemSelected = isSelected(id);

        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}>
                    <TableCell>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar 
                                sx={{
                                    bgcolor: "#000",
                                }} 
                                src={APP_URL + "/" + thumbnail_url}
                            >
                                {getInitials(full_name)}
                            </Avatar>
                            <p className="font-bold">
                                {full_name}
                            </p>
                        </Stack>
                    </TableCell>
                    <TableCell>
                        <p>
                            {total_sessions}
                        </p>
                    </TableCell>
                    <TableCell sx={{fontWeight: 600}}>
                        <p>
                            {total_hours}
                        </p>
                    </TableCell>
                    {
                        role?.name == "administrator" ? (
                            <TableCell>
                                <Link to={"/admin/trainers/" + id + "/analytics"}>
                                    <Button size='small' variant='contained' sx={{borderRadius: 0}}>
                                        {t("Detail")}
                                    </Button>
                                </Link>
                            </TableCell>
                        ) : ""
                    }
                </TableRow>
            </Fragment>
        );
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Paper variant='plain' sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 480, maxWidth: "calc(100vw - 54px)" }}>
                    <Table
                        stickyHeader aria-label="sticky table"
                        size='medium'>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data?.length}
                        />
                        <TableBody>
                            {
                                Array.isArray(data) && data.length ? data.sort((a, b) => b.total_hours - a.total_hours).map((row) => {
                                        return (
                                            <Row key={row.id} rowData={row} />
                                        );
                                    }) : ""
                            }
                        </TableBody>
                </Table>
            </TableContainer>
            </Paper>
        </Box>
    );
}