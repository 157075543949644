import { Fragment, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

import NavHeader from "@/components/NavHeader";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { findManyUserSessions } from "@/utils/Users";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import FilterTable from "@/components/FilterTable";
import PageLoading from "@/components/PageLoading";
import classNames from "classnames";
import dayjs from "dayjs";

const headCells = [
    {
        id: "transaction_ref",
        numeric: false,
        disablePadding: false,
        label: "Class",
    },
    {
        id: "transaction_type",
        numeric: false,
        disablePadding: false,
        label: "Trainer",
    },
    {
        id: "share_with",
        numeric: false,
        disablePadding: false,
        label: "Share with",
    },
    {
        id: "transaction_amount",
        numeric: false,
        disablePadding: false,
        label: "Start in",
    },
    {
        id: "duration",
        numeric: false,
        disablePadding: false,
        label: "Duration",
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
    },
    {
        id: "no_show",
        numeric: false,
        disablePadding: false,
        label: "No show",
    },
    {
        id: "transaction_status",
        numeric: false,
        disablePadding: false,
        label: "Checked in",
    },
    {
        id: "created_at",
        numeric: false,
        disablePadding: false,
        label: "Checked out",
    },
    {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Action",
    }
];

function EnhancedTableHead(props) {
    const { order, orderBy } = props;
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Typography sx={{ fontWeight: 600 }}>
                            {t(headCell.label)}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const TableUserSessions = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [pageInit, setPageInit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [total, setTotal] = useState(0);

    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_date: null,
        end_date: null
    });
    
    const [data, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
        setFilter({
            ...filter,
            paged: newPage ? (newPage + 1) : 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        });
    };

    const handleFindManyData = async (params) => {
        try {
            setIsLoading(true);
            const response = await findManyUserSessions(id, params);
            if (response && !response.error) {
                const { data, meta } = response;
                if(Array.isArray(data) && data?.length) {
                    setData(data);
                    setTotal(meta?.total);
                } else {
                    setData([]);
                }
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            setIsLoading(false);
            setPageInit("complete");
        }
    };

    useEffect(() => {
        if (pageInit == "complete") {
            let filterParams = filter;
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key)) {
                    const element = filter[key];
                    if (element) {
                        filterParams[key] = element;
                    }
                }
            }
            handleFindManyData(filterParams);
        }
    }, [filter]);

    useEffect(() => {
        const initFilter = {
            search: searchParams.get("search"),
            order: searchParams.get("order"),
            start_date: searchParams.get("start_date"),
            end_date: searchParams.get("end_date"),
            paged: searchParams.get("paged") || 1,
            per_page: searchParams.get("per_page") || 12,
        };
        let filterParams = filter;
        for (const key in initFilter) {
            if (Object.hasOwnProperty.call(initFilter, key)) {
                const element = initFilter[key];
                if (element && element != "null") {
                    filterParams[key] = element;
                }
            }
        }
        setFilter((filter) => ({
            ...filter,
            ...filterParams
        }));
        handleFindManyData(filterParams);
    }, []);

    useEffect(() => {
        console.log("data", data);
    }, [data]);

    if (pageInit != "complete") {
        return (
            <PageLoading />
        );
    }

    const Row = ({ rowData }) => {

        const {
            transaction_ref,
            no_show,
            name_shared,
            check_in_time,
            check_out_time,
            status,
            session
        } = rowData;


        const academicClass = rowData?.class;
        const [open, setOpen] = useState(false);
        const { trainer, class_types, start_time, duration_minutes } = session;

        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={transaction_ref}
                    onClick={() => setOpen(!open)}
                    sx={{ cursor: "pointer" }}>
                    <TableCell>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center">    
                            <Link to={"/admin/sessions/" + session?.id}>
                                <strong className="uppercase">
                                    {class_types ? class_types : academicClass?.title} 
                                </strong>
                            </Link>
                        </Stack>
                    </TableCell>
                    <TableCell>
                       <Link to={`/admin/trainers/${trainer?.id}`}>
                            <Typography>
                                {trainer?.full_name}
                            </Typography>
                       </Link>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {name_shared || "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            { start_time ? dayjs(start_time).format("HH:mm, DD/MM/YYYY") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {duration_minutes} minutes
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {
                            status ? (
                                <span className={classNames("border px-1 capitalize text-sm", {
                                    "border-green-200 bg-green-100 text-green-600": status == "active",
                                    "border-red-200 bg-red-100 text-red-600": status == "canceled",
                                    "border-yellow-200 bg-yellow-100 text-yellow-600": status == "pending"
                                })}>
                                    {{
                                        active: t("Booked"),
                                        pending: t("Pending"),
                                        canceled: t("canceled"),
                                    }[status]}
                                </span>
                            ) : "-"
                        }
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {no_show ? t("No show") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {check_in_time ? dayjs(check_in_time).format("HH:mm, DD/MM/YYYY") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {check_out_time ? dayjs(check_out_time).format("HH:mm, DD/MM/YYYY") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{ width: 120}}>
                        <Link to={"/admin/sessions/" + session?.id}>
                            <Button variant='contained' size='small'>
                                {t("View")}
                            </Button>
                        </Link>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    };

    return (
        <Box>
            <NavHeader
                title={t("User's sessions")}
                breadcrumb={[
                    {
                        title: t("User's sessions")
                    }
                ]}
                backLink={"/admin/users/" + id}
            />
            <FilterTable filter={filter} setFilter={setFilter} />
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='medium'>
                        <EnhancedTableHead
                            rowCount={data?.length}
                        />
                        <TableBody>
                                {data.map((row) => {
                                    return (
                                        <Row key={row.id} rowData={row} />
                                    );
                                })}
                            </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage={t("Rows per page:")}
                    rowsPerPageOptions={[12, 24, 36]}
                    component="div"
                    count={total}
                    rowsPerPage={filter?.per_page}
                    page={filter?.paged - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default TableUserSessions;
