
import { findOneLoyalty, updateLoyalty } from "@/utils/Loyalty";
import { findManyMemberships } from "@/utils/Memberships";
import { useToasterContext } from "@/utils/ToasterContext";
import { LoadingButton } from "@mui/lab";
import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import PostPicker from "@/components/PostPicker";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import validate from "validate.js";

const UpdateLoyaltyPage = () => {

    const { id } = useParams();
    
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [defaultValue, setDefaultValue] = useState(false);
    const [formError, setFormError] = useState(false);
    const [memberships, setMemberships] = useState([]);

    const { t } = useTranslation();
    const navigate = useNavigate();

    async function handleFindOne() {
        try {
            const response = await findOneLoyalty(id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        title ,
                        description,
                        min_point,
                        max_point,
                        memberships
                    } = data;
                    if(Array.isArray(memberships) && memberships?.length) {
                        let updatedMembership = memberships.map((val) => ({
                            ...val,
                            discount_percent: val?.pivot?.discount_percent
                        }));
                        setMemberships(updatedMembership);
                    }
                    setDefaultValue({
                        title ,
                        description,
                        min_point,
                        max_point
                    });
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    async function handleUpdate(id, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updateLoyalty(id, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage("success", "Data updated successfully!");
                if (data?.id) {
                    navigate("/admin/loyalties/" + data.id);
                }
            }
        } catch (error) {
            showMessage("error", "An error occurred, please try again later!");
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let UpdateMemberShip = [];

        if(Array.isArray(memberships) && memberships?.length){
            UpdateMemberShip = memberships.map((val) => ({
                membership_id: val.id,
                discount_percent: val.discount_percent
            }));
        }

        const dataForm = {
            title: data.get("title"),
            min_point: data.get("min_point"),
            max_point: data.get("max_point"),
            description: data.get("description"),
            memberships: UpdateMemberShip
        };

        const error = validate(dataForm, constraints);

        if (!error) {
            handleUpdate(id, dataForm);
        } else {
            console.log("error", error);
            showMessage("error", "One or more input fields are invalid!");
        }

        setFormError(error);
    }

    const constraints = {
        title: {
            presence: true,
        },
        min_point: {
            presence: true,
            numericality: { greaterThanOrEqualTo: 0 }
        },
        max_point: {
            presence: true,
            numericality: { greaterThanOrEqualTo: 0 }
        }
    };

    useEffect(() => {
        handleFindOne();
    }, []);

    const SelectedCell = ({ cellLabel, data, onChange, display_key }) => {

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
            // hide last border
            "&:last-child td, &:last-child th": {
                border: 0,
            },
        }));
    
        return (
            <Paper sx={{ width: "100%", mb: 3 }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: 60 }}>No</TableCell>
                            <TableCell>{cellLabel}</TableCell>
                            <TableCell>Discount percent</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Array.isArray(data) && data.length ? data.map((row, index) => (
                                <StyledTableRow
                                    key={row.id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {(index + 1)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {display_key ? row[display_key] : row.title}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TextField 
                                            type="number" 
                                            defaultValue={row.discount_percent} 
                                            onBlur={(e) => onChange(data.map((_v) => _v?.id == row.id ? {...row, discount_percent: parseInt(e.target.value)}: _v))}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => {
                                            onChange(data.filter((_v) => _v?.id !== row.id));
                                        }}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </StyledTableRow>
                            )) : ""
                        }
                    </TableBody>
                </Table>
            </Paper>
        );
    };


    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <NavHeader
                title="Update Loyalty"
                breadcrumb={[
                    {
                        title: "Loyalty",
                        link: "/admin/loyalties/"
                    },
                    {
                        title: "Update loyalty"
                    }
                ]}
                backLink="/admin/loyalties"
            />
             <Box  component="form" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title} required>
                            <FormLabel>{t("Title")}</FormLabel>
                            <TextField
                                name="title"
                                type="text"
                                error={formError?.title}
                                defaultValue={defaultValue?.title}
                            />
                            {formError?.title ? <FormHelperText>{formError?.title[0]}</FormHelperText> : ""}
                        </FormControl>
                        <div className="grid grid-cols-2 gap-3">
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.min_point} required>
                                <FormLabel>{t("Min point")}</FormLabel>
                                <TextField
                                    name="min_point"
                                    type="number"
                                    error={formError?.min_point}
                                    defaultValue={defaultValue?.min_point}
                                />
                                {formError?.min_point ? <FormHelperText>{formError?.min_point[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.max_point} required>
                                <FormLabel>{t("Max point")}</FormLabel>
                                <TextField
                                    name="max_point"
                                    type="number"
                                    error={formError?.max_point}
                                    defaultValue={defaultValue?.max_point}
                                />
                                {formError?.max_point ? <FormHelperText>{formError?.max_point[0]}</FormHelperText> : ""}
                            </FormControl>
                        </div>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.description}>
                            <FormLabel>{t("Description")}</FormLabel>
                            <TextField
                                multiline
                                minRows={3}
                                name="description"
                                defaultValue={defaultValue?.description}
                            />
                            {formError?.description ? <FormHelperText>{formError?.description[0]}</FormHelperText> : ""}
                        </FormControl>
                        <Box sx={{ mb: 1 }}>
                            <PostPicker
                                label="Select memberships"
                                name="memberships"
                                defaultData
                                getApi={findManyMemberships}
                                values={memberships}
                                onChange={(value) => {
                                    if (value) {
                                        setMemberships((membership) => {
                                            let filterMemberships = [];
                                            filterMemberships = membership.filter((_v) => _v?.id !== value.id);
                                            filterMemberships = [...filterMemberships, value];
                                            return filterMemberships;
                                        });
                                    }
                                }}
                                optionKey="title"
                            />
                            <SelectedCell
                                cellLabel="Selected memberships"
                                data={memberships}
                                onChange={(value) => setMemberships(value)}
                            />
                        </Box>
                        <FormControl>
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                size='lg'
                                type='submit'
                                loading={isLoading}>
                                {t("Update loyalty")}
                            </LoadingButton>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default UpdateLoyaltyPage;
