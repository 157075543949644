import { useToasterContext } from "@/utils/ToasterContext";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import PageLoading from "@/components/PageLoading";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import dayjs from "dayjs";

import { numberWithCommas } from "@/utils/Hooks";
import { findManyUserOrders } from "@/utils/UserInfomations";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const UserOrders = () => {

    const { t } = useTranslation();
    
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageStatus, setPageStatus] = useState("idle");

    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_date: null,
        end_date: null
    });

    const handleFindManyData = async (filter) => {
        try {
            startProgress();
            setSearchParams(filter);
            const response = await findManyUserOrders(filter);
            if (response && !response.error) {
                const { data, meta } = response;
                if (Array.isArray(data)) {
                    setData(data);
                    setTotal(meta?.total);
                } else {
                    setData([]);
                }
            }
            setPageStatus("complete");
        } catch (error) {
            console.log("error", error);
        } finally {
            completeProgress();
        }
    };

    useEffect(() => {
        const initFilter = {
            search: searchParams.get("search"),
            order: searchParams.get("order"),
            start_date: searchParams.get("start_date"),
            end_date: searchParams.get("end_date"),
            paged: searchParams.get("paged") || 1,
            per_page: searchParams.get("per_page") || 12,
        };
        let filterParams = filter;
        for (const key in initFilter) {
            if (Object.hasOwnProperty.call(initFilter, key)) {
                const element = initFilter[key];
                if (element && element != "null") {
                    filterParams[key] = element;
                }
            }
        }
        setFilter((filter) => ({
            ...filter,
            ...filterParams
        }));
        handleFindManyData(filterParams);
    }, []);

    useEffect(() => {
        if (pageStatus == "complete") {
            let filterParams = filter;
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key)) {
                    const element = filter[key];
                    if (element) {
                        filterParams[key] = element;
                    }
                }
            }
            handleFindManyData(filterParams);
        }
    }, [filter]);

    const handleChangePage = (event, newPage) => {
        setFilter({
            ...filter,
            paged: newPage ? (newPage + 1) : 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        });
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    if (pageStatus != "complete") {
        return (
            <PageLoading />
        );
    }

    const headCells = [
        {
            id: "id",
            label: t("Order ID"),
        },
        {
            id: "items",
            label: t("Items"),
        },
        {
            id: "total",
            label: t("Billing total"),
        },
        {
            id: "created_at",
            label: t("Billing date"),
        },
        {
            id: "status",
            label: t("Billing status"),
        },
        {
            id: "action",
            label: t("Action"),
        },
    ];

    const Row = ({ rowData }) => {

        const {
            id,
            cart_count,
            payment_status,
            created_at,
            billing_total
        } = rowData;

        const isItemSelected = isSelected(id);

        return (
            <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={id}
            selected={isItemSelected}
            sx={{ cursor: "pointer" }}>
            <TableCell>
                <Typography>
                    {id}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {cart_count}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {billing_total ? numberWithCommas(billing_total) : 0}₫
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {created_at ? dayjs(created_at).format("DD/MM/YYYY") : "-"}
                </Typography>
            </TableCell>
            <TableCell>
                <div
                    className={classNames("inline-block text-white px-1 text-sm uppercase-full ml-3 capitalize", {
                        "bg-red-600": payment_status == "failed",
                        "bg-yellow-600": payment_status == "pending",
                        "bg-green-600": payment_status == "success",
                    })}>
                        {t(payment_status)}
                    </div>
            </TableCell>
            <TableCell>
                <Link to={"/account/orders/" + id}>
                   <Button 
                    variant='contained'
                    sx={{
                        background: "#000",
                        borderRadius: 0
                    }}
                    size='small'>
                    {t("View order")}
                   </Button>
                </Link>
            </TableCell>
        </TableRow>
        );
    };

    function EnhancedTableHead(props) {
        const { order, orderBy } = props;
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <Typography sx={{ fontWeight: 600 }}>
                                {headCell.label}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='medium'>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            rowCount={data?.length}
                        />
                        <TableBody>
                            {data.map((row) => {
                                return (
                                    <Row key={row.id} rowData={row} />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[12, 24, 36]}
                    component="div"
                    count={total || 0}
                    rowsPerPage={parseInt(filter?.per_page) || 12}
                    page={parseInt(filter?.paged - 1)}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default UserOrders;
