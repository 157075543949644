import { Fragment } from "react";
import { Outlet } from "react-router-dom";

import Box from "@mui/material/Box";

export default function Account() {
    return (
        <Fragment>
            <Box>
                <Outlet />
            </Box>
        </Fragment>
    );
}