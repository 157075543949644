import { useToasterContext } from "@/utils/ToasterContext";
import { findManyUserSessions } from "@/utils/UserInfomations";
import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import WeekView from "./WeekView";

const UserSessions = () => {

    const { startProgress, completeProgress } = useToasterContext();
    const { t } = useTranslation();

    const [pageStatus, setPageStatus] = useState("idle");
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [step, setStep] = useState(0);

    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_time: false,
        end_time: false,
    });

    const handleFindManyData = async (filter) => {
        try {
            startProgress();
            setPageStatus("idle");
            const response = await findManyUserSessions(filter);
            if (response && !response.error) {
                const { data, meta } = response;
                setData(data || []);
                setTotal(meta?.total || 0);
            }
            setPageStatus("complete");
        } catch (error) {
            console.error("error", error);
        } finally {
            completeProgress();
            setPageStatus("complete");
        }
    };

    useEffect(() => {
        if (filter?.start_time) {
            handleFindManyData(filter);
        }
    }, [filter]);

    useEffect(() => {
        const start_time = dayjs().add(step, "week").startOf("week").format("YYYY-MM-DD HH:mm:ss");
        const end_time = dayjs().add(step, "week").endOf("week").format("YYYY-MM-DD HH:mm:ss");
        setFilter({
            ...filter,
            start_time: start_time,
            end_time: end_time
        });
    }, [step]);

    if (pageStatus != "complete") {
        return (
            <PageLoading height={320} />
        );
    }

    return (
        <Box>
            <NavHeader
                title={t("Account")}
                breadcrumb={[
                    {
                        title: t("Account")
                    }
                ]}
                endAction={(
                   <Link to={"/account/waiting-list"}>
                        <Button 
                            variant='contained' 
                            sx={{borderRadius: 0, background: "#000"}}>
                            {t("My waiting list")}
                        </Button>
                    </Link>
                )}
            />
            <Box sx={{ py: 2 }}>
                <h1 className='text-center font-bold uppercase text-3xl mb-3'>
                    {t("My bookings")}
                </h1>
                <Stack direction="row" justifyContent="space-between" spacing={3}>
                    <Button
                        onClick={() => setStep((step) => step - 1)}
                        variant='outlined'
                        sx={{
                            borderRadius: 0,
                            letterSpacing: 2,
                            alignItems: "center",
                            fontWeight: 600,
                            display: "inline-flex"
                        }}
                        color="secondary">
                        {t("Prev week")}
                    </Button>
                    <Button
                        onClick={() => setStep((step) => step + 1)}
                        variant='outlined'
                        sx={{
                            borderRadius: 0,
                            fontWeight: 600,
                            letterSpacing: 2
                        }}
                        color="secondary">
                        {t("Next week")}
                    </Button>
                </Stack>
            </Box>
            <WeekView data={data} step={step}/>
        </Box>
    );
};

export default UserSessions;
