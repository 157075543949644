import React from "react";
import { Link } from "react-router-dom";
import { Box, Breadcrumbs, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import HomeIcon from "@mui/icons-material/Home";

const NavHeader = ({ title, breadcrumb, backLink, endAction }) => {

    const { t } = useTranslation();

    return (
        <Box sx={{
            py: 1,
            mb: 2
        }}>
            <div className='flex flex-wrap justify-between gap-3'>
                <Box sx={{
                    width: {
                        xs: "100%",
                        lg: "auto"
                    }
                }}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}>
                        <Typography
                            sx={{
                                flex: "1 1 100%",
                                fontWeight: 900,
                                mb: 2,
                                textTransform: "uppercase",
                                fontSize: 28,
                                letterSpacing: 2
                            }}
                            variant="h1"
                            id="tableTitle"
                            component="h1"
                        >
                            {title}
                        </Typography>
                    </Stack>
                    <Breadcrumbs sx={{ fontSize: 12 }} aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            <Stack direction="row" spacing={1}>
                                <HomeIcon sx={{ fontSize: 16 }} />
                                <Typography
                                    sx={{ fontSize: 12 }}
                                    color="text.blue">
                                    {t("Home")}
                                </Typography>
                            </Stack>
                        </Link>
                        {
                            Array.isArray(breadcrumb) && breadcrumb.length ?
                                breadcrumb.map((val, index) => {
                                    const { title, url } = val;
                                    if (!url) {
                                        return (
                                            <Typography
                                                key={index}
                                                sx={{ fontSize: 12 }}
                                                color="text.primary">
                                                {title}
                                            </Typography>
                                        );
                                    } else {
                                        return (
                                            <Link
                                                key={index}
                                                underline="hover"
                                                color="inherit"
                                                to={url}
                                            >
                                                {title}
                                            </Link>
                                        );
                                    }
                                })
                                : ""
                        }
                    </Breadcrumbs>
                </Box>
                <Stack direction="row" alignItems="center" spacing={2}>
                    {endAction}
                    {
                        backLink ?
                            (
                                <Link to={backLink}>
                                    <Button
                                        variant='plain'
                                        color='secondary'>
                                        {t("Back")}
                                    </Button>
                                </Link>
                            )
                            : ""
                    }
                </Stack>
            </div >
        </Box>
    );
};

export default NavHeader;
