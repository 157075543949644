import { Fragment } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import classNames from "classnames";

import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
    IoCalendarOutline, 
    IoHomeOutline,
    IoImagesOutline,
    IoPersonOutline
} from "react-icons/io5";

const ListItems = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    return (
        <Fragment>
             <Link to="/">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname == "/hoi"})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoHomeOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Home")} />
                </ListItemButton>
            </Link>
            <Link to="/hoi/media">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/hoi/media")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoImagesOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText className='!text-sm' primary={t("Media")} />
                </ListItemButton>
            </Link>
            <Link to="/hoi/trainers">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/hoi/trainers")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoPersonOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText className='!text-sm' primary={t("Instructors")} />
                </ListItemButton>
            </Link>
            <Link to="/hoi/sessions">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/hoi/sessions/")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoCalendarOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Sessions")} />
                </ListItemButton>
            </Link>
        </Fragment>
    );
};

export default ListItems;