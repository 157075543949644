import { useToasterContext } from "@/utils/ToasterContext";
import { Autocomplete, Box, Button, FormControlLabel, Grid, Switch } from "@mui/material";
import { useEffect, useState } from "react";

import NavHeader from "@/components/NavHeader";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import validate from "validate.js";

import { Countries } from "@/utils/Countries";
import { findManyLoyalties } from "@/utils/Loyalty";
import { findManyTrainers } from "@/utils/Trainers";
import { findOneUser, updateUser } from "@/utils/Users";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import PageLoading from "@/components/PageLoading";
import PostPicker from "@/components/PostPicker";
import dayjs from "dayjs";
import TableSubscription from "../Orders/Components/TableSubscription";

const CreateClass = () => {

    const { t } = useTranslation();
    
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [defaultValue, setDefaultValue] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [subscriptions, setSubscription] = useState([]);
    const [role, setRole] = useState(false);
    const [trainer, setTrainer] = useState(false);
    const [loyalty, setLoyalty] = useState(false);
    const [pageInit, setPageInit] = useState(false);

    const navigate = useNavigate();

    async function handleFindOne() {
        try {
            const response = await findOneUser(id);

            if (response && !response.error) {
                const {
                    user_information,
                    display_name,
                    email,
                    roles,
                    subscriptions,
                    trainer
                } = response;

                let role = null;
            
                if(Array.isArray(roles) && roles?.length){
                    role = roles[0];
                }

                setSubscription(subscriptions);
                setRole(role?.name);
                setTrainer(trainer);
                setLoyalty(user_information?.loyalty);
                setDefaultValue({
                    display_name,
                    email,
                    ...user_information,
                    password: ""
                });
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            setPageInit(true);
        }
    }

    async function handleUpdate(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updateUser(id, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage("success", t("Data updated successfully!"));
                handleFindOne();
            } else {
                showMessage("error", response?.data?.message || t("An error occurred, please try again later"));
            }
        } catch (error) {
            console.error("An error occurred:", error);
            showMessage("error", t("An error occurred, please try again later"));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let dataForm = {
            first_name: data.get("first_name"),
            last_name: data.get("last_name"),
            date_of_birth: dayjs(data.get("date_of_birth"), "DD/MM/YYYY").format("YYYY-MM-DD"),
            billing_phone: data.get("billing_phone"),
            billing_country: data.get("billing_country"),
            billing_city: data.get("billing_city"),
            billing_district: data.get("billing_district"),
            billing_ward: data.get("billing_ward"),
            billing_address: data.get("billing_address"),
            loyalty_id: loyalty?.id,
            email: data.get("email"),
            role: data.get("role"),
            chane_password: changePassword,
            password: data.get("password")
        };
        if(role == "instructor"){
            dataForm = {
                ...dataForm,
                trainer_id: trainer?.id
            };
        }
        const error = validate(dataForm, constraints);
        if (!error) {
            handleUpdate(dataForm);
        }
        setFormError(error);
    }

    var roles = [
        { "label": "Administrator", "value": "administrator" },
        { "label": "Receptionist", "value": "receptionist" },
        { "label": "Head of instructor", "value": "head_of_instructor" },
        { "label": "Instructor", "value": "instructor" },
        { "label": "User", "value": "user" },
    ];

    useEffect(() => {
        handleFindOne();
    }, []);

    let constraints = {
        first_name: {
            presence: { allowEmpty: false },
        },
        last_name: {
            presence: { allowEmpty: false },
        },
        email: {
            email: true
        },
        role: {
            presence: { allowEmpty: false }
        }
    };

    if(changePassword){
        constraints = {...constraints,         
        password: {
            presence: { allowEmpty: false }
        }};
    }

    if (!pageInit) {
        return (
            <PageLoading />
        );
    }

    console.log("defaultValue", defaultValue);

    return (
            <Box sx={{ width: "100%" }}>
                <NavHeader
                    title={t("Update user")}
                    breadcrumb={[
                        {
                            title: t("Update User")
                        }
                    ]}
                    endAction={(
                        <Link to={"/admin/users/" + id + "/sessions"} >
                            <Button
                                variant='contained'
                                size='small'
                                color='primary'>
                                {t("View sessions")}
                            </Button>
                        </Link>
                    )}
                    backLink="/admin/users"
                />
                <Box  component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                        <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.first_name} required>
                                        <FormLabel>{t("First name")}</FormLabel>
                                        <TextField
                                            name="first_name"
                                            error={formError?.first_name}
                                            defaultValue={defaultValue?.first_name}
                                        />
                                        {formError?.first_name ? <FormHelperText>{formError?.first_name[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.last_name} required>
                                        <FormLabel>{t("Last name")}</FormLabel>
                                        <TextField
                                            name="last_name"
                                            error={formError?.last_name}
                                            defaultValue={defaultValue?.last_name}
                                        />
                                        {formError?.last_name ? <FormHelperText>{formError?.last_name[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.date_of_birth} required>
                                <FormLabel>{t("Date of birth")}</FormLabel>
                                <DatePicker
                                    name="date_of_birth"
                                    required
                                    value={defaultValue?.date_of_birth ? dayjs(defaultValue?.date_of_birth): dayjs()}
                                />
                                {formError?.date_of_birth ? <FormHelperText>{formError?.date_of_birth[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ mb: 3, width: "100%" }} error={formError?.role} required>
                                <FormLabel id="demo-multiple-name-label">User role</FormLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="role"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    input={<OutlinedInput label="" />}>
                                    {roles.map((val, index) => (
                                        <MenuItem
                                            key={index}
                                            value={val.value}>
                                            {val.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formError?.role ? <FormHelperText>{formError?.role[0]}</FormHelperText> : ""}
                            </FormControl>
                            {
                                role == "instructor" ? (
                                    <Box sx={{ mb: 1 }}>
                                        <PostPicker
                                            label={t("Select intructor")}
                                            required={true}
                                            name="user"
                                            defaultData={trainer}
                                            values={[trainer]}
                                            getApi={findManyTrainers}
                                            defaultParams={{
                                                assign_user: "none"
                                            }}
                                            onChange={(value) => setTrainer((value))}
                                            error={formError?.trainer_id}
                                            optionKey="full_name"
                                            previewKey="email"
                                        />
                                    </Box>
                                ): ""
                            }
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label={t("Loyalty")}
                                    name="loyalty"
                                    defaultData={loyalty}
                                    values={[loyalty]}
                                    getApi={findManyLoyalties}
                                    onChange={(value) => setLoyalty((value))}
                                    error={formError?.loyalty_id}
                                    optionKey="title"
                                    previewKey=""
                                />
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_phone} required>
                                        <FormLabel>{t("Phone number")}</FormLabel>
                                        <TextField
                                            name="billing_phone"
                                            type='text'
                                            defaultValue={defaultValue?.billing_phone}
                                        />
                                        {formError?.billing_phone ? <FormHelperText>{formError?.billing_phone[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.email} required>
                                        <FormLabel>Email</FormLabel>
                                        <TextField
                                            name="email"
                                            type="email"
                                            error={formError?.email}
                                            defaultValue={defaultValue?.email}
                                        />
                                        {formError?.email ? <FormHelperText>{formError?.email[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_country}>
                                <FormLabel>{t("Country")}</FormLabel>
                                <Autocomplete
                                    options={Countries}
                                    name="billing_country"
                                    defaultValue={defaultValue?.billing_country}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                name="billing_country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                }}
                                            />
                                        );
                                    }}
                                />
                                {formError?.billing_country ? <FormHelperText>{formError?.billing_country[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_city}>
                                <FormLabel>{t("City")}</FormLabel>
                                <TextField
                                    name="billing_city"
                                    type='text'
                                    defaultValue={defaultValue?.billing_city}
                                />
                                {formError?.billing_city ? <FormHelperText>{formError?.billing_city[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_district}>
                                <FormLabel>{t("District")}</FormLabel>
                                <TextField
                                    name="billing_district"
                                    type='text'
                                    defaultValue={defaultValue?.billing_district}
                                />
                                {formError?.billing_district ? <FormHelperText>{formError?.billing_district[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_ward}>
                                <FormLabel>{t("Ward")}</FormLabel>
                                <TextField
                                    name="billing_ward"
                                    type='text'
                                    defaultValue={defaultValue?.billing_ward}
                                />
                                {formError?.billing_ward ? <FormHelperText>{formError?.billing_ward[0]}</FormHelperText> : ""}
                            </FormControl>
  
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_address}>
                                <FormLabel>{t("Address")}</FormLabel>
                                <TextField
                                    name="billing_address"
                                    type='text'
                                    defaultValue={defaultValue?.billing_address}
                                />
                                {formError?.billing_address ? <FormHelperText>{formError?.billing_address[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    checked={changePassword}
                                    onChange={() => setChangePassword(!changePassword)}
                                    label="Change user's password" />
                            </FormControl>
                            {
                                changePassword ? (
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.password}>
                                        <FormLabel>User password</FormLabel>
                                        <TextField
                                            name="password"
                                            type="password"
                                            error={formError?.password}
                                        />
                                        {formError?.password ? <FormHelperText>{formError?.password[0]}</FormHelperText> : ""}
                                    </FormControl>
                                ) : ""
                            }
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                    {t("Update user")}
                                </LoadingButton>
                            </FormControl>
                            <Box sx={{mt: 3}}>
                                <h3 className='uppercase font-bold mb-3'>Subscriptions</h3>
                                <TableSubscription defaultData={subscriptions}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    );
};

export default CreateClass;
