import { useState } from "react";

import { findManyLoyalties } from "@/utils/Loyalty";
import { findManyMemberships } from "@/utils/Memberships";
import { Box, FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import PostPicker from "./PostPicker";

const FilterUser = ({ filter, setFilter }) => {

  const { t } = useTranslation();
  
  const [membership, setMembership] = useState(false);
  const [loyalty, setLoyalty] = useState(false);

  function handleChangeMemberShip(value){
    setMembership(value);
    setFilter((filter) => ({...filter, membership_id: value?.id}));
  }

  function handleChangeLoyalty(value){
    setMembership(value);
    setFilter((filter) => ({...filter, loyalty_id: value?.id}));
  }
  

  return (
    <Box>
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-3 mb-5'>
            <PostPicker 
              label={t("Membership")}
              getApi={findManyMemberships}
              values={[membership]}
              optionKey={"title"}
              labelStyle={{mb: 1, fontSize: 14}}
              onChange={(value) => handleChangeMemberShip(value)}
            />
            <FormControl>
              <FormLabel sx={{mb: 1, fontSize: 14}}>{t("User role")}</FormLabel>
              <Select 
                  fullWidth
                  value={filter?.user_role}
                  onChange={(e) => setFilter((filter) => ({
                    ...filter, 
                    user_role: e.target.value,
                    paged: 1
                  }))}
                  size='small'>
                    <MenuItem value="all">{t("All")}</MenuItem>
                    <MenuItem value="administrator">{t("Administrator")}</MenuItem>
                    <MenuItem value="receptionist">{t("Receptionist")}</MenuItem>
                    <MenuItem value="head_of_instructor">{t("Head Of Instructor")}</MenuItem>
                    <MenuItem value="instructor">{t("Instructor")}</MenuItem>
                    <MenuItem value="user">{t("User")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <PostPicker 
                  label={t("Loyalty")}
                  getApi={findManyLoyalties}
                  values={[loyalty]}
                  optionKey={"title"}
                  labelStyle={{mb: 1, fontSize: 14}}
                  onChange={(value) => handleChangeLoyalty(value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel sx={{mb: 1, fontSize: 14}}>{t("Ranking")}</FormLabel>
              <Select 
                  fullWidth
                  value={filter?.ranking}
                  onChange={(e) => setFilter((filter) => ({
                    ...filter, 
                    ranking: e.target.value,
                    paged: 1
                  }))}
                  size='small'>
                  <MenuItem value="all">{t("All")}</MenuItem>
                  <MenuItem value="red_diamond">{t("Red diamond")}</MenuItem>
                  <MenuItem value="diamond">{t("Diamond")}</MenuItem>
                  <MenuItem value="gold">{t("Gold")}</MenuItem>
                  <MenuItem value="silver">{t("Silver")}</MenuItem>
                  <MenuItem value="member">{t("Member")}</MenuItem>
                </Select>
            </FormControl>
        </div>
    </Box>
  );
};

export default FilterUser;