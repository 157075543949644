import { instance } from "./axiosInstance";

export async function findManySubscriptions(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/subscriptions", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}


export async function findManySessionsBySubscription(id, params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/subscriptions/" + id + "/sessions", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneSubscription(id) {
    return await instance
        .get(`/subscriptions/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createSubscription(data) {
    return await instance
        .post("/subscriptions", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateSubscription(id, data) {
    return await instance
        .patch("/subscriptions/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function sendExtendEmail(id) {
    return await instance
        .post("/subscriptions/" + id + "/notify-extend", {})
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}


export async function deleteSubscription(id) {
    return await instance
        .delete("/subscriptions/" + id)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function deleteSubscriptions(data) {
    const config = {
        data: {
            ids: data
        }
    };
    return await instance
        .delete("/subscriptions", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}