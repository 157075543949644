import { instance } from "./axiosInstance";

export async function findOneTrainerInformation() {
    return await instance
        .get("/trainer/information")
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createTrainerInformation(data) {
    return await instance
        .post("/trainer/information", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateTrainerInformation(id, data) {
    return await instance
        .patch("/trainer/information/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}
