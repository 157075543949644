import { instance } from "./axiosInstance";

export async function findManyMedia(params = {}) {
    const config = {
        params: params,
    };
    return await instance
        .get("/media", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneMedia(id) {
    return await instance
        .get(`/media/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function uploadMedia(data, onProgress) {
    let config = {
        timeout: 60 * 4 * 1000,
        maxContentLength: Infinity,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };

    if (onProgress) {
        config = {
            ...config,
            onUploadProgress: (progressEvent) => {
                onProgress(progressEvent);
            }
        };
    }
    return await instance
        .post("/media/upload", data, config) 
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function updateMedia(id, data) {
    return await instance
        .post(`/media/${id}`, data)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function deleteMedia(id) {
    return await instance.delete(`/media/${id}`)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

