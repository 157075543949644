import { createClass } from "@/utils/Classes";
import { useToasterContext } from "@/utils/ToasterContext";
import { Box, Button, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { useState } from "react";

import NavHeader from "@/components/NavHeader";
import ThumbnailPicker from "@/components/ThumbnailPicker";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import validate from "validate.js";

import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

const CreateClass = () => {

    const { t } = useTranslation();
    
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [thumbnail, setThumbnail] = useState(false);
    const [isPrivate, setIsPrivate] = useState(0);
    const [classTypes, setClassTypes] = useState([]);

    const navigate = useNavigate();

    async function handleCreate(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await createClass(data);
            if (response && !response.error) {
                const { data } = response;
                showMessage("success", t("Data created successfully!"));
                if (data?.id) {
                    navigate("/admin/classes/" + data.id);
                }
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try again later!"));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            title: data.get("title"),
            description: data.get("description"),
            thumbnail: thumbnail?.id,
            capacity: data.get("capacity"),
            is_private: isPrivate ? 1 : 0,
            class_types: classTypes ? JSON.stringify(classTypes) : "",
        };

        const error = validate(dataForm, constraints);

        if (!error) {
            handleCreate(dataForm);
        } else {
            console.log("error", error);
            showMessage("error", t("One or more input fields are invalid!"));
        }

        setFormError(error);
    }

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startTime = parseTime(attributes.start_time);
        const endTime = parseTime(value);
        if (startTime && endTime && endTime <= startTime) {
            return options.message || "must be after start time";
        }
    };

    // Helper function to parse time in hh:mm AM/PM format to Date object
    function parseTime(timeString) {
        const [time, modifier] = timeString.split(" ");
        let [hours, minutes] = time.split(":").map(Number);

        if (modifier === "PM" && hours < 12) {
            hours += 12;
        }
        if (modifier === "AM" && hours === 12) {
            hours = 0;
        }

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }

    const constraints = {
        title: {
            presence: { 
                allowEmpty: false },
        },
        description: {
            presence: { 
                allowEmpty: false 
            },
        },
        capacity: {
            presence: { 
                allowEmpty: false 
            },
        }
    };

    function addClassTypes(){
        setClassTypes((classTypes) => ([...classTypes, {
            title: "",
            capacity: 0
        }]));
    }

    function updateClassTypes(index, value){
        let updateClassType = classTypes.map((_v, _i) => _i == index ? value : _v);
        setClassTypes(updateClassType);
    }

    function removeClassType(index){
        let filterClassType = classTypes.filter((_v, _i) => _i !== index);
        setClassTypes(filterClassType);
    }

    return (
        <Box sx={{ width: "100%" }}>
            <NavHeader
                title="Create class"
                breadcrumb={[
                    {
                        title: "classes"
                    }
                ]}
                backLink="/admin/classes"
            />
            <Box  component="form" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title} required>
                            <FormLabel>{t("Class Title")}</FormLabel>
                            <TextField
                                name="title"
                                error={formError?.title}
                            />
                            {formError?.title ? <FormHelperText>{formError?.title[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.description} required>
                            <FormLabel>{t("Class description")}</FormLabel>
                            <TextField
                                multiline
                                minRows={3}
                                name="description"
                            />
                            {formError?.description ? <FormHelperText>{formError?.description[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormLabel>{t("Class note")}</FormLabel>
                            <TextField
                                multiline
                                minRows={3}
                                name="class_note"
                            />
                        </FormControl>
                        <Box sx={{ mb: 3}}>
                            <Typography sx={{
                                fontWeight: 600,
                                textTransform: "uppercase",
                                mb: 1
                            }}>
                                {t("Class Types")}
                            </Typography>
                            {
                                Array.isArray(classTypes) && classTypes.length ?
                                    <Grid container spacing={3}>
                                        {
                                            classTypes.map((val, index) => {
                                                return (
                                                    <Grid  key={index} item xs={12} lg={6}>
                                                        <Box className="border border-black mb-3 p-3">
                                                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                                                <FormLabel>{t("Title")}</FormLabel>
                                                                <TextField value={val?.title} onChange={(e) => updateClassTypes(index, {...val, title: e.target.value})} type="text"/>
                                                            </FormControl>
                                                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                                                <FormLabel>{t("Description")}</FormLabel>
                                                                <TextField
                                                                    multiline
                                                                    minRows={3} 
                                                                    value={val?.description} 
                                                                    onChange={(e) => updateClassTypes(index, {...val, description: e.target.value})} type="text"
                                                                />
                                                            </FormControl>
                                                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                                                <FormLabel>{t("Capacity")}</FormLabel>
                                                                <TextField value={val?.capacity} onChange={(e) => updateClassTypes(index, {...val, capacity: e.target.value})} type="number" />
                                                            </FormControl>
                                                            <Box sx={{ textAlign: "right" }}>
                                                                <Button
                                                                    onClick={() => removeClassType(index)}
                                                                    startIcon={<DeleteIcon />}>
                                                                    {t("Delete")}
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                );
                                            })
                                        }
                                    </Grid>
                                    : ""
                            }
                            <Button
                                variant='contained'
                                onClick={() => addClassTypes()}>
                                {t("Add class type")}
                            </Button>
                        </Box>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.Capacity} required>
                            <FormLabel>{t("Capacity")}</FormLabel>
                            <TextField
                                name="capacity"
                                type="number"
                                error={formError?.capacity}
                            />
                            {formError?.capacity ? <FormHelperText>{formError?.capacity[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl>
                            <FormControlLabel
                                control={<Switch />}
                                checked={isPrivate}
                                onChange={() => setIsPrivate(!isPrivate)}
                                label={t("Is private class")} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormControl sx={{ mb: 3, width: "100%" }}>
                            <FormLabel id="demo-multiple-name-label">{t("Thumbnail")}</FormLabel>
                            <ThumbnailPicker value={thumbnail} onChange={(Image) => setThumbnail(Image)} />
                        </FormControl>
                        <FormControl>
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                size='lg'
                                type='submit'
                                loading={isLoading}
                            >
                                {t("Create")}
                            </LoadingButton>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CreateClass;
