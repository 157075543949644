import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { userLogin } from "@/utils/Auth";
import { FormControl, FormHelperText, FormLabel, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { useToasterContext } from "@/utils/ToasterContext";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setCookie } from "@/utils/Hooks";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import validate from "validate.js";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function SignIn() {

    const { t } = useTranslation();
    
    const [formError, setFormError] = useState(false);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const { status } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            email: data.get("email"),
            password: data.get("password"),
        };

        const error = validate(dataForm, constraints);
        if (!error) {
            try {
                startProgress();
                setIsLoading(true);
                const response = await userLogin(dataForm);
                if (response && !response.error) {
                    const { error, access_token, expires_in } = response;
                    if (!error && access_token && expires_in) {
                        setCookie("li_at", access_token, expires_in);
                        navigate("/");
                    } else {
                        showMessage("error", t("Wrong account name or password"));
                    }
                } else {
                    showMessage("error", t("Wrong account name or password"));
                }
            } catch (error) {
                console.log("error", error);
                showMessage("error", error);
            } finally {
                completeProgress();
                setIsLoading(false);
            }
        } else {
            showMessage("error", t("One or more input fields are not valid"));
        }

        setFormError(error);
    };

    const constraints = {
        email: {
            presence: {
                allowEmpty: false,
                message: t("^Email cannot be blank")
            },
            email: {
                message: t("^Invalid email")
            }
        },
        password: {
            presence: {
                allowEmpty: false,
                message: t("^Password cannot be blank")
            },
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box sx={{ p: 3 }} component="form" onSubmit={handleSubmit} noValidate>
                    <Typography sx={{ fontSize: 27, fontWeight: 600, mb: 2 }}>
                        {t("Login")}
                    </Typography>
                    <FormControl error={formError?.email} sx={{ width: "100%", mb: 2 }}>
                        <FormLabel htmlFor="outlined-adornment-password">Your email</FormLabel>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            size='medium'
                            error={formError?.email}
                        />
                        {formError?.email ? <FormHelperText>{formError?.email[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControl error={formError?.password} sx={{ width: "100%",  mb: 2  }}>
                        <FormLabel htmlFor="outlined-adornment-password">Password</FormLabel>
                        <OutlinedInput
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="current-password"
                            error={formError?.password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        onMouseUp={handleMouseUpPassword}
                                        edge="end"
                                        size='small'
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {formError?.password ? <FormHelperText>{formError?.password[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color='secondary'
                        sx={{ mt: 3, mb: 2 }}
                        loading={isLoading}
                    >
                        Sign In
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/reset-password" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/register" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}