import { instance } from "./axiosInstance";

export async function findAllTranslations(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/translations/all", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findManyTranslations(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/translations", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneTranslation(id) {
    return await instance
        .get(`/translations/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createTranslation(data) {
    return await instance
        .post("/translations", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateTranslation(id, data) {
    return await instance
        .patch("/translations/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteTranslation(id) {
    return await instance
        .delete("/translations/" + id)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function deleteTranslations(data) {
    const config = {
        data: {
            ids: data
        }
    };
    return await instance
        .delete("/translations", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}