import { refreshToken } from "@/utils/Auth";
import { deleteCookie, getTokenExpiration, setCookie } from "@/utils/Hooks";
import { setUser } from "@/utils/slice/AuthSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TokenRefresher = () => {

    const { user} = useSelector((state) => state.auth);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            const intervalId = setInterval(async () => {
                try {
                    const now = Math.floor(Date.now() / 1000);
                    const expiration = getTokenExpiration("li_at");
                    if ((expiration - now) < 900) { 
                        const response = await refreshToken();
                        const { error, access_token, expires_in } = response;
                        if (!error && access_token && expires_in) {
                            setCookie("li_at", access_token, expires_in);
                        } else {
                            dispatch(setUser(false));
                            deleteCookie("li_at");
                            navigate("/login");
                        }
                    }
                } catch (error) {
                    console.error("Refresh token failed", error);
                }
            }, 30000); // Kiểm tra mỗi 30 giây
    
            return () => clearInterval(intervalId);
        }
    }, [user]);

  return null;
};

export default TokenRefresher;