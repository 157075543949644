import NavHeader from "@/components/NavHeader";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import vietQR from "./assets/viet-qr.svg";
import vnPay from "./assets/VNPAY-QR.svg";

const Index = () => {
    const { t } = useTranslation();
    return (
        <Box>
            <NavHeader
                title="Integrations"
                breadcrumb={[
                    {
                        title: "Integrations"
                    }
                ]}
            />
            <Grid container spacing={3} >
                <Grid>
                    <Link to="/admin/integrations/viet-qr">
                        <Box sx={{ textAlign: "center" }}>
                            <Box className="shadow bg-white p-3 h-[200px] w-[200px] flex items-center justify-center mb-3">
                                <img src={vietQR} />
                            </Box>
                            <Typography sx={{ fontWeight: 600 }}>
                                {t("Viet QR")}
                            </Typography>
                        </Box>
                    </Link>
                </Grid>
                <Grid>
                    <Link to="/admin/integrations/vnpay">
                        <Box sx={{ textAlign: "center" }}>
                            <Box className="shadow bg-white p-3 h-[200px] w-[200px] flex items-center justify-center mb-3">
                                <img src={vnPay} />
                            </Box>
                            <Typography sx={{ fontWeight: 600 }}>
                                {t("VNPAY")}
                            </Typography>
                        </Box>
                    </Link>
                </Grid>
                {/* <Grid>
                    <Link to="/integrations/onepay">
                        <Box sx={{ textAlign: "center" }}>
                            <Box className="shadow bg-white p-3 h-[200px] w-[200px] flex items-center justify-center mb-3">
                                <img src={onePay} />
                            </Box>
                            <Typography sx={{ fontWeight: 600 }}>
                                OnePay
                            </Typography>
                        </Box>
                    </Link>
                </Grid> */}
            </Grid>
        </Box>
    );
};

export default Index;
