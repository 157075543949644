import { instance } from "./axiosInstance";

export async function findManySessions(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/sessions", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneSession(id) {
    return await instance
        .get(`/sessions/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createSession(data) {
    return await instance
        .post("/sessions", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createSessionNotify(id, data) {
    return await instance
        .post("/sessions/" + id + "/notify", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateSession(id, data) {
    return await instance
        .patch("/sessions/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function deleteSession(id) {
    return await instance
        .delete("/sessions/" + id)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}