import { instance } from "./axiosInstance";

export async function findManyMemberships(params = {}) {
    const config = {
        params: params
    };
    return await instance
        .get("/memberships", config)
        .then((res) => res.data)
        .catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function findOneMembership(id) {
    return await instance
        .get(`/memberships/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function createMembership(data) {
    return await instance
        .post("/memberships", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}

export async function updateMembership(id, data) {
    return await instance
        .patch("/memberships/" + id, data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}
export async function deleteMembership(id) {
    return await instance
        .delete("/memberships/" + id)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}

export async function deleteMemberships(data) {
    const config = {
        data: data
    };
    return await instance
        .delete("/memberships", config)
        .then((res) => {
            return res?.data;
        }).catch(function (err) {
            return({
                error: true,
                data: err
            });
        });
}