import { useToasterContext } from "@/utils/ToasterContext";
import { findOneTrainer, updateTrainer } from "@/utils/Trainers";
import { Box, Button, FormControlLabel, Grid, Stack, Switch, TableContainer, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import NavHeader from "@/components/NavHeader";
import PostPicker from "@/components/PostPicker";
import ThumbnailPicker from "@/components/ThumbnailPicker";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import validate from "validate.js";

import PageLoading from "@/components/PageLoading";
import { findManyClasses } from "@/utils/Classes";
import { findManyUsers } from "@/utils/Users";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";


import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

import { debounce } from "@/utils/Hooks";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTranslation } from "react-i18next";

const CreateClass = () => {

    const { id } = useParams();
    
    const currentUser = useSelector((state) => state.auth.user);

    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(false);
    const [formError, setFormError] = useState(false);
    const [thumbnail, setThumbnail] = useState(false);
    const [defaultValue, setDefaultValue] = useState(false);
    const [TrainerSessions, setTrainerSessions] = useState([]);
    const [AcademicClasses, setAcademicClasses] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("AcademicClasses", AcademicClasses);
    }, [AcademicClasses]);

    async function handleUpdateData(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = updateTrainer(id, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage("success", t("Data updated successfully!"));
                if (data?.id) {
                    navigate("/admin/trainers/" + id);
                }
            } else {
                showMessage("error", t("An error occurred, please try again later"));
            }
        } catch (error) {
            console.log("error", error);
            showMessage("error", t("An error occurred, please try again later"));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleFindOne() {
        try {
            const response = await findOneTrainer(id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        full_name,
                        birthday,
                        gender,
                        email,
                        phone_number,
                        address,
                        description,
                        academic_classes,
                        user,
                        status,
                        is_have_private,
                        thumbnail,
                        bonus,
                        bonus_threshold,
                        schedule
                    } = data;
                    setUser(user);
                    setThumbnail(thumbnail);
                    if(Array.isArray(academic_classes)){
                        let updatedClass = academic_classes.map((val) => ({...val, ...val?.pivot}));
                        setAcademicClasses(updatedClass);
                    }
                    if(schedule){
                        setTrainerSessions(JSON.parse(schedule));
                    }
                    setDefaultValue({
                        full_name,
                        birthday,
                        gender,
                        email,
                        phone_number,
                        address,
                        description,
                        is_have_private,
                        bonus,
                        bonus_threshold,
                        status,
                    });
                }
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const schedule = JSON.stringify(TrainerSessions);
        const is_have_private = data.get("is_have_private");
        const dataForm = {
            full_name: data.get("full_name"),
            birthday: data.get("birthday"),
            gender: data.get("gender"),
            email: data.get("email"),
            phone_number: data.get("phone_number"),
            address: data.get("address"),
            description: data.get("description"),
            bonus: data.get("bonus"),
            bonus_threshold: data.get("bonus_threshold"),
            user_id: user?.id,
            status: data.get("status"),
            academic_classes: AcademicClasses,
            schedule: schedule,
            thumbnail: thumbnail?.id,
            is_have_private: is_have_private == "on" ? 1 : 0,
        };

        const error = validate(dataForm, constraints);
        if (!error) {
            handleUpdateData(dataForm);
        }
        console.log("error", error);
        setFormError(error);
    }

    const constraints = {
        full_name: {
            presence: { allowEmpty: false }
        },
        email: {
            presence: { allowEmpty: false }
        },
        gender: {
            presence: { allowEmpty: false }
        },
        status: {
            presence: { allowEmpty: false }
        },
        user_id: {
            presence: { allowEmpty: false }
        },
        academic_classes: {
            presence: { allowEmpty: false }
        }
    };

    useEffect(() => {
        handleFindOne();
    }, []);

    function handleAddSchedule() {
        setTrainerSessions((TrainerSessions) => ([
            ...TrainerSessions,
            {
                id: uuidv4(),
                start_time: "00:00:00",
                end_time: "00:00:00"
            }
        ]));
    }

    function handleUpdateSchedule(data) {
        const updateSessions = TrainerSessions.map((val) => val.id == data.id ? data : val);
        setTrainerSessions(updateSessions);
    }

    function handleDeleteSchedule(id) {
        const removeSessions = TrainerSessions.filter((_v) => _v.id !== id);
        setTrainerSessions(removeSessions);
    }

    const SelectedCell = ({ cellLabel, data, onChange }) => {

        const [defaultData, setDefaultData] = useState(data);
        
        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
            // hide last border
            "&:last-child td, &:last-child th": {
                border: 0,
            },
        }));
    
        const handleChange = useCallback(debounce((id, field, value) => {
            const updatedData = data.map((row) =>
                row.id === id ? { ...row, [field]: value } : row
            );
            setDefaultData(updatedData);
            onChange(updatedData);
        }, 300), [defaultData, onChange]);
    
        return (
            <Paper sx={{ width: "100%", overflow: "hidden", mb: 3, borderRadius: 0 }}>
                <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                    <Table
                        stickyHeader aria-label="sticky table"
                        size='medium'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: 60 }}>{t("No")}</TableCell>
                                <TableCell>{cellLabel}</TableCell>
                                <TableCell>{t("Unit cost")}</TableCell>
                                <TableCell>{t("Bonus")}</TableCell>
                                <TableCell>{t("Bonus threshold")}</TableCell>
                                <TableCell align="right">{t("Actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(defaultData) && defaultData.length ? defaultData.map((row, index) => (
                                <StyledTableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TextField
                                            type="number"
                                            name="unit_cost"
                                            defaultValue={row.unit_cost}
                                            onChange={(e) => handleChange(row.id, "unit_cost", e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TextField
                                            type="number"
                                            name="bonus"
                                            defaultValue={row.bonus}
                                            onChange={(e) => handleChange(row.id, "bonus", e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TextField
                                            type="number"
                                            name="bonus_threshold"
                                            defaultValue={row.bonus_threshold}
                                            onChange={(e) => handleChange(row.id, "bonus_threshold", e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => onChange(data.filter((_v) => _v.id !== row.id))}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </StyledTableRow>
                            )) : ""}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    };

    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    return (
            <Box sx={{ width: "100%" }}>
                <NavHeader
                    title="Update Instructor"
                    breadcrumb={[
                        {
                            title: "Update Instructor"
                        }
                    ]}
                    endAction={(
                        <Stack direction="row" spacing={3}>
                            {
                                currentUser?.role == "administrator" ? (
                                    <Button 
                                        variant='outlined' 
                                        sx={{borderRadius: 0}} 
                                        size='small'
                                        onClick={() => navigate("/admin/trainers/" + id + "/analytics")}>
                                        {t("View Analytics")}
                                    </Button>
                                ) : ""
                            }
                        </Stack>
                    )}
                    backLink="/admin/trainers"
                />
                <Box  component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.full_name} required>
                                <FormLabel>{t("Full Name")}</FormLabel>
                                <TextField
                                    name="full_name"
                                    error={formError?.full_name}
                                    defaultValue={defaultValue?.full_name}
                                />
                                {formError?.full_name ? <FormHelperText>{formError?.full_name[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormLabel>{t("Description")}</FormLabel>
                                <TextField
                                    multiline
                                    minRows={3}
                                    name="description"
                                    defaultValue={defaultValue?.description}
                                />
                            </FormControl>
                            <div className="grid grid-cols-2 gap-3">
                                <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.email} required>
                                    <FormLabel>{t("Email")}</FormLabel>
                                    <TextField
                                        name="email"
                                        type="email"
                                        error={formError?.email}
                                        defaultValue={defaultValue?.email}
                                    />
                                    {formError?.email ? <FormHelperText>{formError?.email[0]}</FormHelperText> : ""}
                                </FormControl>
                                <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.phone_number}>
                                    <FormLabel>{t("Phone number")}</FormLabel>
                                    <TextField
                                        name="phone_number"
                                        type="tel"
                                        error={formError?.phone_number}
                                        defaultValue={defaultValue?.phone_number}
                                    />
                                    {formError?.phone_number ? <FormHelperText>{formError?.phone_number[0]}</FormHelperText> : ""}
                                </FormControl>
                            </div>
                            <div className="grid grid-cols-2 gap-3">
                                <FormControl sx={{ width: "100%", mb: 3 }} required>
                                    <FormLabel id="demo-simple-select-label">{t("Gender")}</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={defaultValue?.gender}
                                        name="gender"
                                    >
                                        <MenuItem value={"male"}>{t("Male")}</MenuItem>
                                        <MenuItem value={"female"}>{t("Female")}</MenuItem>
                                    </Select>
                                    {formError?.gender ? <FormHelperText>{formError?.gender[0]}</FormHelperText> : ""}
                                </FormControl>
                                <FormControl sx={{ width: "100%", mb: 3 }} required>
                                    <FormLabel id="demo-simple-select-label">
                                        {t("Status")}
                                    </FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={defaultValue?.status}
                                        name="status"
                                    >
                                        <MenuItem value={"active"}>{t("Active")}</MenuItem>
                                        <MenuItem value={"inactive"}>{t("Inacvive")}</MenuItem>
                                    </Select>
                                    {formError?.status ? <FormHelperText>{formError?.status[0]}</FormHelperText> : ""}
                                </FormControl>
                            </div>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.address}>
                                <FormLabel>{t("Address")}</FormLabel>
                                <TextField
                                    name="address"
                                    error={formError?.address}
                                    defaultValue={defaultValue?.address}
                                />
                                {formError?.address ? <FormHelperText>{formError?.address[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label={t("Select user")}
                                    name="user"
                                    defaultData={user}
                                    values={[user]}
                                    required={true}
                                    error={formError?.user_id}
                                    getApi={findManyUsers}
                                    onChange={(value) => setUser((value))}
                                    optionKey="email"
                                    previewKey="id"
                                />
                            </Box>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    label={t("Private bookings are allowed")}
                                    name="is_have_private"
                                    checked={defaultValue?.is_have_private}
                                    onChange={() => setDefaultValue((defaultValue) => ({...defaultValue, is_have_private: !defaultValue.is_have_private}))}
                                />
                            </FormControl>
                            <Typography sx={{
                                    fontWeight: 600,
                                    textTransform: "uppercase",
                                    mb: 2
                                }}>
                                {t("Instructor classes")}
                            </Typography>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label="Select classes"
                                    name="academic_classes"
                                    defaultData
                                    required={true}
                                    error={formError?.academic_classes}
                                    getApi={findManyClasses}
                                    values={AcademicClasses}
                                    onChange={(value) => {
                                        if (value) {
                                            setAcademicClasses((AcademicClasses) => {
                                                let filterClasses = AcademicClasses.filter((_v) => _v?.id !== value.id);
                                                filterClasses = [...filterClasses, value];
                                                return filterClasses;
                                            });
                                        }
                                    }}
                                    optionKey="title"
                                >
                                    <SelectedCell
                                        cellLabel={t("Classes")}
                                        data={AcademicClasses}
                                        onChange={(value) => setAcademicClasses(value)}
                                    />
                                </PostPicker>
                            </Box>
                            {/* <Box>
                                <Typography sx={{
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    mb: 2
                                }}>
                                    {t("Private instructor schedule")}
                                </Typography>
                                {
                                    Array.isArray(TrainerSessions) && TrainerSessions.length ?
                                        TrainerSessions.map((val, index) => {
                                            return (
                                                <Box key={index}>
                                                    <Grid container spacing={3} >
                                                        <Grid item xs={6}>
                                                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_time} required>
                                                                <FormLabel>{t("Start hour")}</FormLabel>
                                                                <TimePicker
                                                                    name="start_time"
                                                                    value={val?.start_time ? dayjs(val.start_time, "HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss")}
                                                                    onChange={(value) => handleUpdateSchedule({ ...val, start_time: dayjs(value).format("HH:mm:ss") })}
                                                                />
                                                                {formError?.start_time ? <FormHelperText>{formError?.start_time[0]}</FormHelperText> : ""}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.end_time} required>
                                                                <FormLabel>{t("End hour")}</FormLabel>
                                                                <TimePicker
                                                                    name="end_time"
                                                                    value={val?.end_time ? dayjs(val.end_time, "HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss")}
                                                                    onChange={(value) => handleUpdateSchedule({ ...val, end_time: dayjs(value).format("HH:mm:ss") })}
                                                                />
                                                                {formError?.end_time ? <FormHelperText>{formError?.end_time[0]}</FormHelperText> : ""}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Box sx={{ textAlign: 'right' }}>
                                                        <Button
                                                            onClick={() => handleDeleteSchedule(val.id)}
                                                            startIcon={<DeleteIcon />}>
                                                            {t("Delete schedule")}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                        : ""
                                }
                                <Button
                                    variant='contained'
                                    onClick={() => handleAddSchedule()}>
                                    {t("Add schedule")}
                                </Button>
                            </Box> */}
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormControl sx={{ mb: 3, width: "100%" }}>
                                <FormLabel id="demo-multiple-name-label">{t("Thumbnail")}</FormLabel>
                                <ThumbnailPicker value={thumbnail} onChange={(Image) => setThumbnail(Image)} />
                            </FormControl>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='secondary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                    {t("Update Instructor")}
                                </LoadingButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    );
};

export default CreateClass;
