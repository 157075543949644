import { useToasterContext } from "@/utils/ToasterContext";
import { createUserSessions } from "@/utils/UserInfomations";
import { findManyUsers } from "@/utils/Users";
import { LoadingButton } from "@mui/lab";
import { Box, Button, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, MenuItem, Modal, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import PostPicker from "@/components/PostPicker";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ScrollContainer from "react-indiana-drag-scroll";
import validate from "validate.js";

dayjs.extend(customParseFormat);
dayjs.extend(require("dayjs/plugin/isBetween"));

const BookForSession = ({ open, setOpen, session }) => {

    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const { t } = useTranslation();

    const { showMessage } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);

    const [bookUser, setBookUser] = useState(false);

    const [defaultValue, setDefaultValue] = useState(false);
    const [trainer, setTrainer] = useState([]);
    const [AcademicClass, setClass] = useState([]);
    const [users, setUsers] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [formError, setFormError] = useState(false);
    const [selectedClassType, setSelectedClassType] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(false);
    const [shared, setShared] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [capacity, setCapacity] = useState(0);
    const [isOnlyShared, setIsOnlyShared] = useState(0);
    const [personalSelect, setPersonalSelect] = useState(false);
    const [unSendEmail, setUnsendEmail] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    function handleSelectPersonalBook(value){
        setIsOnlyShared(value);
        setShared([]);
        setPersonalSelect(true);
    }

    async function handleBookSession(event) {
        event.preventDefault();
        const formData = {
            subscription_id: selectedSubscription?.id,
            shared: shared.filter((val) => val != ""),
            is_only_shared: isOnlyShared ? 1 : 0,
            user_id: bookUser?.id,
            session_id: id,
            un_send_email: unSendEmail ? 1 : 0
        };
        const error = validate(formData, createSessionConstraint);
        if (!error) {
            try {
                setIsLoading(true);
                const response = await createUserSessions(formData);
                if (response && !response.error) {
                    showMessage("success", "Data created successfully!");
                    location.reload();
                } else {
                    showMessage("error", response?.message || "An error occurred, please try later");
                }
            } catch (error) {
                showMessage("error", "An error occurred, please try later");
            } finally {
                setIsLoading(false);
            }
        } else {
            showMessage("error", "An error occurred, please try later");
        }
        setFormError(error);
    }

    useEffect(() => {
        if (session) {
            const {
                title,
                description,
                capacity,
                trainer,
                start_time,
                end_time,
                class_types,
                is_free_session,
                users
            } = session;
            const academicClass = session?.class;
            setCapacity(capacity);
            setClass(academicClass);
            setTrainer(trainer);
            setUsers(users);
            setDefaultValue({
                title: title,
                class_types: class_types,
                description: description,
                capacity: capacity,
                start_time: start_time,
                end_time: end_time,
                is_free_session: is_free_session,
                class: academicClass
            });
        }
    }, []);

    function handleChangeBookUser(user){
        if(user?.subscriptions){
            setSubscriptions(user.subscriptions);
        } else {
            setSubscriptions([]);
        }
        setBookUser(user);
    }

    useEffect(() => {
        const class_types = AcademicClass?.class_types;
        let classTypeInfo = class_types ? JSON.parse(class_types) : null;
        if(Array.isArray(classTypeInfo) && classTypeInfo.length && defaultValue?.class_types){
            let selectedClass = classTypeInfo.find((val) => val?.title == defaultValue?.class_types);
            setSelectedClassType(selectedClass);
        }
    }, [defaultValue, AcademicClass]);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 540,
        bgcolor: "background.paper",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    let createSessionConstraint = {
        user_id: {
            presence: true,
        }
    };

    if(!defaultValue?.is_free_session){
        createSessionConstraint = {
            subscription_id: {
                presence: true,
            }
        };
    }


    function handleChangeSubscription(e){
        setShared([]);
        const selectedSubscription = subscriptions.find((val) => val.id == e.target.value);
        setIsOnlyShared(0);
        setSelectedSubscription(selectedSubscription);
    }

    function handleChangeShared(value, index) {
        const updated = shared.map((_v, _i) => index == _i ? value : _v);
        setShared(updated);
    }

    function handleDeleteShared(index) {
        const updated = shared.filter((_v, _i) => _i != index);
        setShared(updated);
    }

    function handleAddShared() {
        setShared((shared) => [...shared, ""]);
    }

    const start = dayjs(defaultValue.start_time);
    const end = dayjs(defaultValue.end_time);
    // Tính khoảng thời gian giữa hai thời điểm trong phút
    const durationInMinutes = end.diff(start, "minute");

    const currentDayString = dayjs().format("MM/DD");
    const currentDay = dayjs(currentDayString, "MM/DD");

    const sessionDateCompare = currentDay.isAfter(start);

    // Tính toán trước số lượng `shared` hợp lệ để tránh lặp lại
    const validSharedLength = shared.filter((val) => val !== "").length;

    // Điều kiện tính toán cho `disabledSharedButton`
    const sharedCountAdjustment = isOnlyShared ? 0 : 1;
    const validateUsers = Array.isArray(users) && users?.length ? users.filter((val) => val?.pivot?.status == "active") : [];
    const hasReachedCapacity = (capacity - validateUsers?.length) <= (shared?.length + sharedCountAdjustment);
    const hasUnlimitedSessions = selectedSubscription.max_sessions == "-1";
    const counter = selectedSubscription?.class_session_user_count || 0;
    const hasAvailableSessions = defaultValue?.is_free_session ? validateUsers?.length <= capacity : (counter + shared?.length + sharedCountAdjustment) <= (selectedSubscription?.max_sessions - 1);

    console.log(`hasReachedCapacity: ${hasReachedCapacity}, hasReachedCapacity: ${hasUnlimitedSessions}, hasReachedCapacity: ${hasAvailableSessions},`);
    const disabledSharedButton = hasReachedCapacity
        ? true
        : hasUnlimitedSessions
            ? false
            : !hasAvailableSessions;

    // Tính `usageCounter` dựa trên giá trị `validSharedLength`
    const usageCounter = isOnlyShared ? validSharedLength : validSharedLength + 1;

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{
                    ...style,
                    width: 680,
                    maxWidth: "90vw"
                }}>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 24,
                        mb: 2,
                        textTransform: "uppercase"
                    }}>
                    {confirm ? t("Your session detailed") : t("Book a session")}
                    </Typography>
                    {
                       !bookUser ? (
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label="Select Users"
                                    name="user"
                                    defaultData
                                    getApi={findManyUsers}
                                    values={[bookUser]}
                                    defaultParams={{
                                        session_id: id
                                    }}
                                    onChange={(value) => {
                                        handleChangeBookUser(value);
                                    }}
                                    optionKey="email"
                                    previewKey="display_name"
                                />
                            </Box>
                       ): (
                        <ScrollContainer hideScrollbars={false} className="scroll-container max-h-[80vh] py-2">
                            {
                                capacity - validateUsers?.length <= 0 ? (
                                    <Box>
                                        <Typography>{t("This session is now full. Please try a different session.")}</Typography>
                                    </Box>
                                ) : (
                                        <form onSubmit={handleBookSession}>
                                        {
                                            (defaultValue?.is_free_session != 1) && !selectedSubscription ? (
                                                <Box>
                                                    <InputLabel id="demo-simple-select-label">
                                                        {t("Select subscription")}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        label="Select subscription"
                                                        name="subscription_id"
                                                        value={selectedSubscription?.id}
                                                        onChange={(e) => handleChangeSubscription(e)}
                                                        sx={{
                                                            mb: 2,
                                                            width: "100%"
                                                        }}>
                                                        {
                                                            subscriptions.map((val) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={val.id}
                                                                        sx={{ fontSize: 14 }}
                                                                        value={val.id}>
                                                                        <Box>
                                                                            <Typography sx={{ fontWeight: 600 }}>
                                                                                {val.membership.title} { val.membership?.can_be_share ? <span className='font-normal text-sm'>({t("Can be shared")})</span> : ""}
                                                                            </Typography>
                                                                            <Typography sx={{ fontSize: 12 }}>
                                                                                {val.max_sessions == -1 ? t("unlimited")  : `${val.max_sessions - val.class_session_user_count} / ${val.max_sessions}`}
                                                                            </Typography>
                                                                        </Box>
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                    {formError?.subscription_id ? <FormHelperText>{formError?.subscription_id[0]}</FormHelperText> : ""}
                                                </Box>
                                            ) : (
                                                <FormControl fullWidth error={formError?.subscription_id}>
                                                    {
                                                        !personalSelect && (selectedSubscription?.membership?.can_be_share || defaultValue?.is_free_session) ? (
                                                            <div className="grid grid-cols-1 gap-3">
                                                                <div className="border p-3 border-black">
                                                                    <h3 className='font-bold text-xl'>
                                                                        {t("Personal Booking and Shared with Others")}
                                                                    </h3>
                                                                    <p className='text-[14px]'>
                                                                        {t("Choose this option if you are booking for yourself and others. You will receive the check-in QR code via email, which you can use for yourself and forward to others.")}
                                                                    </p>
                                                                    <Button 
                                                                        variant='outlined' 
                                                                        sx={{borderRadius: 0, mt: 2, fontWeight: 600, borderColor: "#000", color: "#000"}}
                                                                        onClick={() => handleSelectPersonalBook(false)}>
                                                                            {t("Next")}
                                                                    </Button>
                                                                </div>
                                                                <div className="border p-3 border-black">
                                                                    <h3 className='font-bold text-xl'>
                                                                        {t("Booking for someone else")}
                                                                    </h3>
                                                                    <p className='text-[14px]'>
                                                                        {t("Select this option if you are booking on behalf of someone else and will not be attending. The check-in QR code will be sent to your email to share with the person attending.")}
                                                                    </p>
                                                                    <Button 
                                                                        variant='outlined' 
                                                                        sx={{borderRadius: 0, mt: 2, fontWeight: 600, borderColor: "#000", color: "#000"}}
                                                                        onClick={() => handleSelectPersonalBook(true)}>
                                                                            {t("Next")}
                                                                    </Button>
                                                                </div>
                                                                {
                                                                    !defaultValue?.is_free_session ? (
                                                                        <Button 
                                                                            variant='plain' 
                                                                            sx={{ borderRadius: 0}} 
                                                                            onClick={() => setSelectedSubscription(false)}>
                                                                                {t("Back to select subscription")}
                                                                        </Button>
                                                                    ): ""
                                                                }
                                                            </div>
                                                        ) : (
                                                            <Box>
                                                            {
                                                                !confirm && (selectedSubscription?.membership?.can_be_share || defaultValue?.is_free_session) ? (
                                                                    <Fragment>
                                                                {
                                                                    (selectedSubscription?.membership?.can_be_share || defaultValue?.is_free_session) ? (
                                                                        <Box>
                                                                            <h3 className='font-bold'>{t("Add shared person")}</h3>
                                                                            <p className='text-sm'>{t("Add the people you want to register for this class. For each person shared, your class credit will be deducted.")}</p>
                                                                            <Box>
                                                                                {
                                                                                    Array.isArray(shared) && shared.length ? 
                                                                                        shared.map((val, index) => {
                                                                                            return (
                                                                                                <Stack direction="row" spacing={1} sx={{my: 2,}} key={index}>
                                                                                                    <TextField 
                                                                                                        label={t("Your shared name") + " (" + (index + 1) + ")"}
                                                                                                        sx={{flex: 1}} 
                                                                                                        value={val} 
                                                                                                        onChange={(e) => handleChangeShared(e.target.value, index)}
                                                                                                    />
                                                                                                    <IconButton 
                                                                                                        variant='outlined' 
                                                                                                        sx={{ borderColor: "#000", borderRadius: 0 }} 
                                                                                                        onClick={() => handleDeleteShared(index)}>
                                                                                                            <DeleteOutlineIcon />
                                                                                                    </IconButton>
                                                                                                </Stack>
                                                                                            );
                                                                                        }) : ""
                                                                                }
                                                                            </Box>
                                                                            <div className="text-end">
                                                                                    <Button 
                                                                                        variant='outlined'
                                                                                        sx={{ borderColor: "#000", borderRadius: 0, mt: 1 }} 
                                                                                        startIcon={<AddIcon />}
                                                                                        disabled={disabledSharedButton}
                                                                                        onClick={() => handleAddShared()}>
                                                                                        {t("Add shared")}
                                                                                    </Button>
                                                                            </div>
                                                                            <Stack direction="row" spacing={1} sx={{mt: 3}}>
                                                                                <Button 
                                                                                    onClick={() => setPersonalSelect(false)} 
                                                                                    sx={{
                                                                                        flex: 1,
                                                                                        padding: "10px 12px",
                                                                                        borderRadius: 0,
                                                                                        display: "block",
                                                                                        mt: 2,
                                                                                        mb: 2,
                                                                                        textAlign: "center",
                                                                                        letterSpacing: 2
                                                                                    }}
                                                                                    variant='outlined'>
                                                                                    {t("Back")}
                                                                                </Button>
                                                                                <Button
                                                                                    variant='contained'
                                                                                    type='button'
                                                                                    onClick={() => { setConfirm(true); }}
                                                                                    disabled={usageCounter <= 0}
                                                                                    sx={{
                                                                                        background: "#000",
                                                                                        padding: "10px 12px",
                                                                                        width: "100%",
                                                                                        borderRadius: 0,
                                                                                        display: "block",
                                                                                        mt: 2,
                                                                                        mb: 2,
                                                                                        flex: 1,
                                                                                        textAlign: "center",
                                                                                        letterSpacing: 2
                                                                                    }}>
                                                                                    {t("Next step")}
                                                                                </Button>
                                                                            </Stack>
                                                                        </Box>
                                                                    ) :  
                                                                        selectedSubscription?.id && !confirm ? (
                                                                            <Stack direction="row" spacing={1}>
                                                                                <Button 
                                                                                    onClick={() => setSelectedSubscription(false)} 
                                                                                    sx={{
                                                                                        flex: 1,
                                                                                        padding: "10px 12px",
                                                                                        borderRadius: 0,
                                                                                        display: "block",
                                                                                        mt: 2,
                                                                                        mb: 2,
                                                                                        textAlign: "center",
                                                                                        letterSpacing: 2
                                                                                    }}
                                                                                    variant='outlined'>
                                                                                    {t("Back")}
                                                                                </Button>
                                                                                <Button
                                                                                    variant='contained'
                                                                                    type='button'
                                                                                    onClick={() => { setConfirm(true); }}
                                                                                    disabled={usageCounter <= 0}
                                                                                    sx={{
                                                                                        background: "#000",
                                                                                        padding: "10px 12px",
                                                                                        width: "100%",
                                                                                        borderRadius: 0,
                                                                                        display: "block",
                                                                                        mt: 2,
                                                                                        mb: 2,
                                                                                        flex: 1,
                                                                                        textAlign: "center",
                                                                                        letterSpacing: 2
                                                                                    }}>
                                                                                    {t("Next step")}
                                                                                </Button>
                                                                            </Stack>
                                                                        ) : ""
                                                                    }
                                                            </Fragment>
                                                        ): (
                                                                    <Box sx={{ mb: 3}}>
                                                                    <div className="border-black mb-3">
                                                                    {
                                                                        selectedClassType ? (
                                                                            <Fragment>
                                                                                <Typography>
                                                                                    <span className='text-2xl font-bold uppercase'>{selectedClassType?.title}</span>
                                                                                </Typography>
                                                                                <Typography className="text-sm" sx={{mb: 3}}>
                                                                                    {t(selectedClassType?.description)}
                                                                                </Typography>
                                                                            </Fragment>
                                                                        ) : (
                                                                            AcademicClass ?
                                                                                <Fragment>
                                                                                    <Typography>
                                                                                        <span className='text-2xl font-bold upe'>{AcademicClass?.title}</span>
                                                                                    </Typography>
                                                                                    <Typography className="text-sm" sx={{mb: 3}}>
                                                                                        {AcademicClass?.description}
                                                                                    </Typography>
                                                                                </Fragment>
                                                                                : ""
                                                                        )
                                                                    }
                                                                    {
                                                                        start ?
                                                                            <Fragment>
                                                                                <Typography>
                                                                                    {t("on")} <span className='font-bold uppercase'> {start.format("ddd, DD/MM")}</span>
                                                                                </Typography>
                                                                                <Typography>
                                                                                    {t("at")} <span className='font-bold uppercase'>{start.format("HH:mm")}</span>
                                                                                </Typography>
                                                                            </Fragment>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        durationInMinutes ?
                                                                            <Typography>
                                                                                {t("Duration")}: <span className="font-bold">{durationInMinutes} {t("minutes")}</span>
                                                                            </Typography>
                                                                            : ""
                                                                    }
                                                                    <Typography>
                                                                        {t("Instructor")}: <strong>{trainer?.full_name || "-"}</strong> 
                                                                    </Typography>
                                                                    <Typography>{t("Number of reservations")}: <strong className='ml-2'>{ usageCounter }</strong></Typography>
                                                                    </div>
                                                                    {
                                                                        Array.isArray(shared) && shared.length ? 
                                                                            <Box sx={{ mt: 2, maxHeight: 360, overflowY: "auto" }}>
                                                                                <h3 className='font-bold mb-0'>{t("Shared with person")}</h3>
                                                                                {
                                                                                    shared.filter((val) => val != "").map((val, index) => {
                                                                                        return (
                                                                                            <Stack direction="row" spacing={1} key={index}>
                                                                                                <Typography>{(index + 1)}. <strong>{val}</strong></Typography>
                                                                                            </Stack>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Box> : ""
                                                                    }
                                                                    <FormControl sx={{ width: "100%", mb: 3 }}>
                                                                        <FormControlLabel
                                                                            control={<Switch />}
                                                                            label={
                                                                                <Box>
                                                                                    {t("Do not notify users by email")}
                                                                                </Box>
                                                                            }
                                                                            checked={unSendEmail}
                                                                            onChange={() => setUnsendEmail(!unSendEmail)}
                                                                            name="un_send_email"
                                                                        />
                                                                    </FormControl>
                                                                    <Stack direction="row" spacing={1} sx={{mt: 3, mb: 2}}>
                                                                        {
                                                                            selectedSubscription?.membership?.can_be_share || defaultValue?.is_free_session == "1" ? (
                                                                                <Button 
                                                                                    onClick={() => {
                                                                                        setConfirm(false);
                                                                                    }} 
                                                                                    sx={{
                                                                                        flex: 1,
                                                                                        padding: "10px 12px",
                                                                                        borderRadius: 0,
                                                                                        display: "block",
                                                                                        mt: 2,
                                                                                        mb: 2,
                                                                                        textAlign: "center",
                                                                                        letterSpacing: 2
                                                                                    }}
                                                                                    variant='outlined'>
                                                                                    {t("Back")}
                                                                                </Button>
                                                                            ) : (
                                                                                <Button 
                                                                                    onClick={() => {
                                                                                        setSelectedSubscription(false);
                                                                                    }} 
                                                                                    sx={{
                                                                                        flex: 1,
                                                                                        padding: "10px 12px",
                                                                                        borderRadius: 0,
                                                                                        display: "block",
                                                                                        mt: 2,
                                                                                        mb: 2,
                                                                                        textAlign: "center",
                                                                                        letterSpacing: 2
                                                                                    }}
                                                                                    variant='outlined'>
                                                                                    {t("Back")}
                                                                                </Button>
                                                                            )
                                                                            
                                                                        }
                                                                        <LoadingButton
                                                                            loading={isLoading}
                                                                            variant='contained'
                                                                            type='submit'
                                                                            disabled={usageCounter <= 0}
                                                                            sx={{
                                                                                background: "#000",
                                                                                padding: "10px 12px",
                                                                                flex: 1,
                                                                                borderRadius: 0,
                                                                                display: "block",
                                                                                mt: 2,
                                                                                mb: 2,
                                                                                textAlign: "center",
                                                                                letterSpacing: 2
                                                                            }}>
                                                                            {t("Book session")}
                                                                        </LoadingButton>
                                                                    </Stack>
                                                                    <p className="text-sm">
                                                                        <span className="text-red-600 mr-2">*</span>
                                                                        {t("We will send the appointment booking information to the email addres")} <strong>{user?.email}</strong>. {t("You will receive an email containing the appointment details and an attached event file. Please open the attachment and add it to your calendar.")}
                                                                    </p>
                                                                </Box>
                                                                )
                                                            }
                                                            </Box>
                                                        )
                                                    }
                                                </FormControl>
                                            ) 
                                        }
                                    </form>
                                )
                            }
                            <Button sx={{ width: "100%" }} onClick={() => setBookUser(false)}>Back to select user</Button>
                        </ScrollContainer>
                       )
                    }
                </Box>
            </Modal>
        </div>
    );
};

export default BookForSession;