import { getDataUser } from "@/utils/slice/AuthSlice";
import { Avatar, Badge, BottomNavigation, BottomNavigationAction, Box, Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import StyleIcon from "@mui/icons-material/Style";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./Header";

import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { userLogout } from "@/utils/Auth";
import { findManyCarts } from "@/utils/Cart";
import { getInitials } from "@/utils/Hooks";
import { useToasterContext } from "@/utils/ToasterContext";
import { updateCartData } from "@/utils/slice/cartSlice";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import PageLoading from "@/components/PageLoading";
import TokenRefresher from "@/components/TokenRefresher";

const Index = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { pathname } = useLocation();
    const { showMessage } = useToasterContext();
    const { user, status } = useSelector((state) => state.auth);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if(pathname.startsWith("/schedule")){
            setValue("schedule");
        } else if(pathname.startsWith("/memberships")) {
            setValue("memberships");
        } else if(pathname.startsWith("/")) {
            setValue("/");
        } 
    }, []);

    function handleChangeRoute(path) {
        setValue(path);
        navigate(path);
    }

    async function handleFindCart() {
        try {
            const response = await findManyCarts();
            if (response && !response.error) {
                const { data } = response;
                dispatch(updateCartData(data));
            } else {
                showMessage("error", t("An error occurred during delete item!"));
            }
        } catch (error) {
            showMessage("error", "An error occurred during add item!");
        }
    }

    async function handleUserLogout() {
        try {
            setIsLoading("logout");
            const response = await userLogout();
            if (response && !response.error) {
                showMessage("success", t("You have successfully logged out!"));
                navigate("/login");
            } else {
                showMessage("error", t("An error occurred during delete item!"));
            }
        } catch (error) {
            console.error("error", error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        dispatch(getDataUser());
    }, []);

    useEffect(() => {
        if(user){
            handleFindCart();
        }
    }, [user]);

    let menuItems = [];

    const roles = user?.roles;

    let role = null;

    if(Array.isArray(roles) && roles?.length){
        role = roles[0];
    }

    if(role?.name == "instructor") {
        menuItems = [
            {
                to: "/trainer/sessions",
                icon: <DashboardIcon color='#000'/>,
                primaryText: t("Dashboard")
            }
        ];
    } else if(role?.name == "administrator") {
        menuItems = [
            {
                to: "/admin",
                icon: <DashboardIcon color='#000'/>,
                primaryText: t("Dashboard")
            }
        ];
    } else if(role?.name == "receptionist") {
        menuItems = [
            {
                to: "/admin",
                icon: <DashboardIcon color='#000'/>,
                primaryText: t("Dashboard")
            }
        ];
    } else if(role?.name == "head_of_instructor") {
        menuItems = [
            {
                to: "/hoi",
                icon: <DashboardIcon color='#000'/>,
                primaryText: t("Dashboard")
            }
        ];
    }

    menuItems = [
        ...menuItems,
        {
            to: "/account",
            icon: <CalendarMonthIcon color='#000'/>,
            primaryText: t("My bookings")
        },
        {
            to: "/account/waiting-list",
            icon: <EventRepeatIcon color='#000'/>,
            primaryText: t("My waitlist")
        },
        {
            to: "/account/subscriptions",
            icon: <StyleIcon color='#000'/>,
            primaryText: t("My subscriptions")
        },
        {
            to: "/account/orders",
            icon: <LocalMallIcon color='#000'/>,
            primaryText: t("My orders")
        },
        {
            to: "/account/user-information",
            icon: <AccountCircleIcon color='#000'/>,
            primaryText: t("Account information")
        }
    ];

    const DrawerList = (
        <Box
            sx={{ width: 380, maxWidth: "75vw", p: 2 }}
            role="presentation"
            onClick={() => setOpen(false)}>
            {
                user ? (
                    <Box>
                        <div className='flex justify-between items-center gap-3 mb-3'>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar 
                                    sx={{
                                        bgcolor: "#000",
                                    }}>
                                    {getInitials(user?.display_name || user?.email)}
                                </Avatar>
                                <Box>
                                    <Typography className='uppercase'>
                                        <strong>{user?.display_name || "-"}</strong>
                                    </Typography>
                                    <Typography sx={{ fontSize: 12}}>
                                        {user?.email}
                                    </Typography>
                                    {
                                        user?.user_information?.loyalty?.title ? (
                                            <span className='px-1 inline-block text-xs border border-black'>
                                                {user?.user_information?.loyalty?.title}
                                            </span> 
                                        ): ""
                                    }
                                </Box>
                            </Stack>
                            <IconButton onClick={() => setOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Divider />
                    </Box>
                ) : ""
            }
            <List>
                {menuItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton
                            component={Link}
                            to={item.to}
                            onClick={() => setValue(false)}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.primaryText} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem disablePadding>
                    <ListItemButton
                        loading={isLoading == "logout"}
                        onClick={handleUserLogout}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Logout")} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box >
    );

    const location = useLocation();

    const routeVariants = {
        initial: {
            y: 35,
            opacity: 0,
        },
        final: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                duration: 1.5,
            },
        },
        exit: {
            y: "100vh",
            opacity: 0,
        },
    };
    
    if(status !== "complete"){
        return <PageLoading />;
    }

    return (
        <Fragment>
            <CssBaseline />
            <TokenRefresher />
            <Header />
            <Box sx={{
                px: "24px",
                minHeight: "105vh",
                paddingY: "125px"
            }} className="container mx-auto">
                <AnimatePresence>
                    <motion.div
                        key={location.pathname}
                        variants={routeVariants}
                        initial="initial"
                        animate="final">
                        <Outlet />
                    </motion.div>
                </AnimatePresence>
            </Box>
            <Paper 
                sx={{ 
                    position: "fixed", 
                    bottom: 0, 
                    left: 0, 
                    right: 0,
                    background: "#ffffffba",
                    backdropFilter: "blur(10px)",
                }} 
            elevation={3}>
                <BottomNavigation
                    className='bottom-nav'
                    sx={{background: "transparent"}}
                    showLabels
                    value={value}>
                    <BottomNavigationAction
                        label={t("Book a session")}
                        value="/"
                        onClick={() => handleChangeRoute("/")} icon={<CalendarMonthIcon />} />
                    <BottomNavigationAction
                        label={t("Memberships")}
                        value="memberships"
                        onClick={() => handleChangeRoute("memberships")} icon={<BeenhereIcon />} />
                    <BottomNavigationAction
                        label={t("Your Account")}
                        value="cart"
                        onClick={() => { if(user) { setOpen(true);} else { navigate("/login" );}}}
                        icon={<Badge><AccountCircleIcon /></Badge>}
                    />
                </BottomNavigation>
                <Drawer
                    anchor='right'
                    open={open}
                    onClose={() => setOpen(false)}>
                    {DrawerList}
                </Drawer>
            </Paper>
        </Fragment>
    );
};

export default Index;
