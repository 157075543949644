import { Grid, Input, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { findManyMedia } from "../utils/Media";

import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

const APP_URL = process.env.REACT_APP_APP_URL;

const MediaPicker = ({ open, setOpen, onSelect }) => {

    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");

    const handleFindManyData = async ({ params }) => {
        try {
            const response = await findManyMedia({
                per_page: 12,
                ...params
            });
            if (response && !response.error) {
                const { data } = response;
                setData(data);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        handleFindManyData({});
    }, []);

    function handleSelect(Image) {
        onSelect(Image);
        setOpen(false);
    }

    return (
        <Drawer open={open} onClose={() => setOpen(false)}>
            <Box sx={{ p: 3, width: 640, maxWidth: "90vw" }}>
                <Box sx={{ mb: 2 }}>
                    <Input
                        fullWidth
                        value={search}
                        variant="filled"
                        placeholder='Search for files'
                        onChange={(e) => setSearch(e.target.value)}
                        endAdornment={
                            <InputAdornment onClick={() => handleFindManyData({ search: search })} position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                    />
                </Box>
                <Grid container spacing={2}>
                    {data.map((row) => {
                        const { id, file_path } = row;
                        return (
                            <Grid
                                item
                                key={id}
                                xs={3}
                            >
                                <img
                                    onClick={() => handleSelect(row)}
                                    className='cursor-pointer border border-slate-200 h-[160px] object-center object-cover'
                                    src={APP_URL + "/" + file_path}
                                    alt=""
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Drawer>
    );
};

export default MediaPicker;
