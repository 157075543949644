import { getDataUser } from "@/utils/slice/AuthSlice";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import Logo from "@/assets/logo-light.svg";
import PageLoading from "@/components/PageLoading";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonAdd from "@mui/icons-material/PersonAdd";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import classNames from "classnames";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import ListItems from "./listItems";

import { userLogout } from "@/utils/Auth";
import { getInitials } from "@/utils/Hooks";
import { pusher } from "@/utils/Pusher";
import { addNotify } from "@/utils/slice/NotifySlice";
import { useToasterContext } from "@/utils/ToasterContext";
import { Avatar, Badge, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import TokenRefresher from "@/components/TokenRefresher";

const drawerWidth = 230;


export default function DashboardLayout() {

    const { t } = useTranslation();
    const { user, status } = useSelector((state) => state.auth);
    const { notify } = useSelector((state) => state.notify);

    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const { showMessage, changeLanguage, currentLanguage } = useToasterContext();
    const { pathname } = useLocation();
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        var channel = pusher.subscribe("order");
        channel.bind("App\\Events\\OrderCreated", function(data) {
            dispatch(addNotify(data));
            showMessage("success", "Order created recently");
        });
    }, []);
    
    useEffect(() => {
        setMobileOpen(false);
    }, [pathname]);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    async function handleUserLogout() {
        try {
            const response = await userLogout();
            if (response && !response.error) {
                showMessage("success", t("You have successfully logged out!"));
                navigate("/login");
            } else {
                showMessage("error", t("An error occurred during delete item!"));
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        dispatch(getDataUser());
    }, []);
    
    if (status !== "complete") {
        return (
            <PageLoading />
        );
    }

    const roles = user?.roles;
    
    if(Array.isArray(roles) && roles?.length){
        const role = roles[0];
        if (user && !["administrator", "receptionist"].includes(role?.name)) {
            navigate("/");
        }
    }

    const DrawerContent = () => {
        return (
            <Box className='text-white'>
                <Box className='flex bg-black items-center justify-center w-full text-center sticky top-0 py-5'>
                    <Link to={"/admin"} >
                        <img src={Logo} width={120} alt="" />
                    </Link>
                </Box>
                <List
                    sx={{
                        p: 1,
                    }}
                    component="nav">
                    <ListItems />
                </List>
            </Box>
        );
    };

    return (
    <Fragment>
        <TokenRefresher />
        <Box id="dashboard" sx={{ display: "flex" }}>
            <Box
                component="nav"
                id="dashboard-nav"
                className='!bg-slate-900'
                sx={{ 
                    width: { sm: drawerWidth }, 
                    flexShrink: { sm: 0 },
                }}
                aria-label="mailbox folders">
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onTransitionEnd={handleDrawerTransitionEnd}
                        onClose={handleDrawerClose}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            background: "unset",
                            display: { xs: "block", sm: "none" },
                            borderRight: "unset !important",
                            "& .MuiDrawer-paper": { 
                                boxSizing: "border-box", 
                                width: drawerWidth,
                                background: "unset",

                            },
                        }}>
                        <DrawerContent />
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: "none", sm: "block" },
                            borderRight: "unset !important",
                            background: "unset",
                            "& .MuiDrawer-paper": {
                                background: "unset",
                                boxSizing: "border-box", 
                                width: drawerWidth 
                            },
                        }}
                        open>
                        <DrawerContent />
                    </Drawer>
            </Box>
            <Box
                component="main"
                className='bg-neutral-100 min-h-screen'
                sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                    <AppBar
                        position="sticky"
                        sx={{
                            background: "rgb(0 0 0 / 85%)",
                            backdropFilter: "blur(10px)",
                            zIndex: 99
                        }}>
                        <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ display: { sm: "none" } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Toolbar
                            sx={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                p: 0,
                                justifyContent: "space-between",
                            }}>
                                <div></div>
                                <Stack direction="row" alignItems="center" spacing={3}>
                                    <div>
                                        <div className='bg-slate-700 rounded-full flex gap-1 items-center px-1 py-1 h-auto' spacing={1}>
                                            <button onClick={() => changeLanguage("en")} className={classNames("uppercase text-[16px] leading-[16px] rounded-full !px-3 !py-1", {"bg-black": currentLanguage == "en"})}>
                                                {t("EN")}
                                            </button>
                                            <button onClick={() => changeLanguage("vi")} className={classNames("uppercase text-[16px] leading-[16px] rounded-full !px-3 !py-1", {"bg-black": currentLanguage == "vi"})}>
                                                {t("VN")}
                                            </button>
                                        </div>
                                    </div>
                                    <PopupState variant="popover" popupId="demo-popup-menu">
                                        {(popupState) => (
                                            <Fragment>
                                                <Badge badgeContent={notify?.length} color="secondary" showZero={false}>
                                                    <IconButton 
                                                        {...bindTrigger(popupState)}
                                                        color="inherit"
                                                        aria-label="open drawer"
                                                        edge="start">
                                                        <NotificationsIcon color='#fff'/>
                                                    </IconButton>
                                                </Badge>
                                                <Menu 
                                                    {...bindMenu(popupState)}
                                                    id="account-menu"
                                                    slotProps={{
                                                    paper: {
                                                        elevation: 0,
                                                        sx: {
                                                        overflow: "visible",
                                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                        mt: 1.5,
                                                        "& .MuiAvatar-root": {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        "&::before": {
                                                            content: "\"\"",
                                                            display: "block",
                                                            position: "absolute",
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: "background.paper",
                                                            transform: "translateY(-50%) rotate(45deg)",
                                                            zIndex: 0,
                                                        },
                                                        },
                                                    },
                                                    }}
                                                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                                                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                                >
                                                    {
                                                        Array.isArray(notify) && notify?.length ? 
                                                            notify.map((val, index) => {
                                                                return (
                                                                    <MenuItem key={index}>
                                                                        {val?.order?.title}
                                                                    </MenuItem>
                                                                );
                                                        }) :   <MenuItem>
                                                                    {t("Nothing here!")}
                                                                </MenuItem>
                                                    }
                                            </Menu>
                                            </Fragment>
                                        )}
                                    </PopupState>
                                    <PopupState variant="popover" popupId="demo-popup-menu">
                                        {(popupState) => (
                                            <Fragment>
                                                <IconButton
                                                    size="small"
                                                    sx={{ ml: 2 }}
                                                    aria-controls={open ? "account-menu" : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? "true" : undefined}
                                                    {...bindTrigger(popupState)}
                                                >
                                                    <Avatar sx={{ width: 32, height: 32 }}>
                                                        {getInitials(user?.display_name)}
                                                    </Avatar>
                                                </IconButton>
                                                <Menu 
                                                    {...bindMenu(popupState)}
                                                    id="account-menu"
                                                    slotProps={{
                                                    paper: {
                                                        elevation: 0,
                                                        sx: {
                                                        overflow: "visible",
                                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                        mt: 1.5,
                                                        "& .MuiAvatar-root": {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        "&::before": {
                                                            content: "\"\"",
                                                            display: "block",
                                                            position: "absolute",
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: "background.paper",
                                                            transform: "translateY(-50%) rotate(45deg)",
                                                            zIndex: 0,
                                                        },
                                                        },
                                                    },
                                                    }}
                                                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                                                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                                >
                                                    <Link to="/admin/users/create">
                                                        <MenuItem>
                                                            <ListItemIcon>
                                                                <PersonAdd fontSize="small" />
                                                            </ListItemIcon>
                                                            {t("Add another account")}
                                                        </MenuItem>
                                                    </Link>
                                                    <MenuItem onClick={handleUserLogout}>
                                                        <ListItemIcon>
                                                            <Logout fontSize="small" />
                                                        </ListItemIcon>
                                                        {t("Logout")}
                                                    </MenuItem>
                                            </Menu>
                                        </Fragment>
                                        )}
                                    </PopupState>
                                </Stack>
                            </Toolbar>
                        </Toolbar>
                    </AppBar>
                <Box sx={{
                    px: {
                        lg: 4,
                        xs: 2
                    }, 
                    pt: 10
                    }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    </Fragment>
    );
}