import { instance } from "./axiosInstance";

export async function approveWaitingList(id, data) {
    return await instance
        .post("/waiting-lists/" + id + "/approve", data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
}