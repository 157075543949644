import { numberWithCommas } from "@/utils/Hooks";
import { AnalyticTrainer, findOneTrainer } from "@/utils/Trainers";
import { FormControl, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import NavHeader from "@/components/NavHeader";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import CountUp from "react-countup";

const headCells = [
    {
        id: "Id",
        numeric: false,
        label: "ID",
    },
    {
        id: "class",
        numeric: false,
        label: "Class",
    },
    {
        id: "start",
        label: "Start in",
    },
    {
        id: "end",
        label: "End In",
    },
    {
        id: "active_slots",
        label: "Checked-in slots",
    },
    {
        id: "class_threshold",
        label: "Class Threshold",
    },
    {
        id: "checked_in",
        label: "Bonus users",
    },
    {
        id: "bonus",
        label: "Bonus",
    },
    {
        id: "unit_cost",
        label: "Unit cost",
    }
];

function EnhancedTableHead(props) {
    const { order, orderBy } = props;
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Typography sx={{ fontWeight: 600 }}>
                            {t(headCell.label)}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const TrainerAnalytic = () => {

    const { id } = useParams();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [trainer, setTrainer] = useState([]);
    const [academicClasses, setAcademicClasses] = useState([]);
    
    const { t } = useTranslation();

    const [totalHours, setTotalHours] = useState(0);
    const [totalBonus, setTotalBonus] = useState(0);
    const [totalUnitCost, setTotalUnitCost] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [filter, setFilter] = useState({
      start_date: dayjs().startOf("month").format("DD/MM/YYYY"),
      end_date: dayjs().endOf("month").format("DD/MM/YYYY"),
  });

    async function handleAnalyticTrainer (filter){
      try {
        setIsLoading(true);
        const response = await AnalyticTrainer(id, filter);
        if (response && !response.error) {
            const { data } = response;
            if (data) {
               setData(data);
            }
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    }

    async function handleFindOne() {
        try {
            const response = await findOneTrainer(id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                   setTrainer(data);
                   if(Array.isArray(data?.academic_classes) && data?.academic_classes?.length) {
                    setAcademicClasses(data.academic_classes);
                   }
                }
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    }

    useEffect(() => {
        handleFindOne();
        handleAnalyticTrainer(filter);
    }, []);

    useEffect(() => {
        if(Array.isArray(data) && data?.length){
            let totalHours = 0, totalBonus = 0, totalUnitCost = 0;
            data.map((val) => {
                const { academic_class_id, start_time, end_time, class_sessions } = val;

                const activeSlots = class_sessions.filter((val) => val?.apply_bonus == 1 && val?.status == "active")?.length;

                const selectedClass = academicClasses.find((val) => val.id == academic_class_id);

                const start = dayjs(start_time);
                const end = dayjs(end_time);
                const hoursDiff = end.diff(start, "hour", true);

                totalHours += hoursDiff;

                let bonus, bonus_threshold;

                if (selectedClass && selectedClass?.pivot) {
                    bonus = selectedClass?.pivot?.bonus;
                    bonus_threshold = selectedClass?.pivot?.bonus_threshold;
                    totalUnitCost += selectedClass?.pivot?.unit_cost;
                }

                if(bonus_threshold < activeSlots){
                    totalBonus += activeSlots * bonus;
                }

            });
            
            setTotalHours(totalHours);
            setTotalUnitCost(totalUnitCost);
            setTotalBonus(totalBonus);
        }
    }, [data, academicClasses]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    
    const handleChangeDate = (name, value) => {
        setFilter({
            ...filter,
            [name]: value ? dayjs(value).format("DD/MM/YYYY") : null
        });
    };


    return (
        <Box sx={{ width: "100%" }}>
        <NavHeader
            title={trainer?.full_name}
            breadcrumb={[
                {
                    title: t("Instructor"),
                    url: "/admin/trainers"
                },
                {
                    title: trainer?.full_name
                }
            ]}
            backLink={"/admin/trainers/" + trainer?.id}
        />
            <div className='flex flex-wrap gap-5 mb-5 justify-end'>
                <FormControl>
                    <DatePicker
                        label= {t("From")}
                        slotProps={{ textField: { size: "small" } }}
                        value={filter?.start_date ? dayjs(filter.start_date, "DD/MM/YYYY") : null}
                        onChange={(value) => handleChangeDate("start_date", value)}
                        sx={{ width: "100%",  background: "#fff", }}
                    />
                </FormControl>
                <FormControl>
                    <DatePicker
                        label= {t("To")}
                        slotProps={{ textField: { size: "small" } }}
                        onChange={(value) => handleChangeDate("end_date", value)}
                        renderInput={(params) => <TextField size="small" {...params} />}
                        value={filter?.end_date ? dayjs(filter.end_date, "DD/MM/YYYY") : null}
                        sx={{ width: "100%",  background: "#fff" }}
                    />
                </FormControl>
                <FormControl>
                    <LoadingButton 
                        loading={isLoading}
                        onClick={() => handleAnalyticTrainer(filter)} 
                        variant='contained'>
                        {t("Filter")}
                    </LoadingButton>
                </FormControl>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
                <div>
                    <div className="border border-slate-200 bg-white p-3 lg:p-10">
                        <h3 className="lg:text-xl mb-2">{t("Total sessions")}</h3>
                        <div className="text-3xl lg:text-4xl font-bold">
                            <CountUp end={data?.length} separator="," decimals={0}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="border border-slate-200 bg-white p-3 lg:p-10">
                        <h3 className="lg:text-xl mb-2">{t("Total hours")}</h3>
                        <div className="text-3xl lg:text-4xl font-bold">
                            <CountUp end={totalHours} separator="," decimals={0}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="border border-slate-200 bg-white p-3 lg:p-10">
                        <h3 className="lg:text-xl mb-2">{t("Total cost")}</h3>
                        <div className="text-3xl lg:text-4xl font-bold">
                            <CountUp end={totalUnitCost} separator="," decimals={0}/>đ
                        </div>
                    </div>
                </div>
                <div>
                    <div className="border border-slate-200 bg-white p-3 lg:p-10">
                        <h3 className="lg:text-xl mb-2">{t("Total bonus")}</h3>
                        <div className="text-3xl lg:text-4xl font-bold">
                            <CountUp end={totalBonus} separator="," decimals={0}/>đ
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-xl font-[900] mb-5">
                {t("Sessions detailed")}
            </h3>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                    <Table
                        stickyHeader aria-label="sticky table"
                        size='medium'>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data.length}
                        />
                        <TableBody>
                            {data.sort((a, b) => dayjs(a.start_time).unix() - dayjs(b.start_time).unix()).map((row) => {
                                const isItemSelected = isSelected(row.id);
                                const { class_sessions, start_time, end_time, academic_class_id } = row;
                                const start = dayjs(start_time);
                                const end = dayjs(end_time);
                                const selectedClass = academicClasses.find((val) => val.id == academic_class_id);

                                let bonus, bonus_threshold, totalBonus, unit_cost = 0;

                                const activeUsers = class_sessions.filter((val) => val?.check_in_time)?.length;
                                const activeSlots = class_sessions.filter((val) => val?.apply_bonus == 1 && val?.status == "active")?.length;

                                if (selectedClass && selectedClass?.pivot) {
                                    bonus = selectedClass?.pivot?.bonus;
                                    bonus_threshold = selectedClass?.pivot?.bonus_threshold;
                                    unit_cost = selectedClass?.pivot?.unit_cost;
                                }

                                if(bonus_threshold < activeSlots){
                                    totalBonus = activeSlots * bonus;
                                }

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row?.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <TableCell>
                                            <Link className='' to={"/admin/sessions/" + row?.id}>
                                                <strong className="uppercase">{row?.id}</strong>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link className='' to={"/admin/sessions/" + row?.id}>
                                                <strong className="uppercase">{row?.class_types ? row.class_types : row?.class?.title}</strong>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <p>
                                                {start.format("hh:mm A, DD/MM")}
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            <p>
                                                {end.format("hh:mm A, DD/MM")}
                                            </p>
                                        </TableCell>
                                        <TableCell>{activeUsers}</TableCell>
                                        <TableCell>{bonus_threshold}</TableCell>
                                        <TableCell>{activeSlots}</TableCell>
                                        <TableCell>{totalBonus ? `${numberWithCommas(totalBonus)}đ` : "-"}</TableCell>
                                        <TableCell>{unit_cost ? `${numberWithCommas(unit_cost)}đ` : "-"}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

export default TrainerAnalytic;