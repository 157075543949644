import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Input, Typography } from "@mui/material";
import { useEffect, useState } from "react";

function InputDragData({ progress, setDataInput }) {

    const [isDrop, setIsDrop] = useState();
    const [getData, setGetData] = useState();

    useEffect(() => {
        if (getData) {
            setDataInput(getData);
        }
    }, [getData]);

    function dragOver(ev) {
        ev.preventDefault();
        setIsDrop(true);
    }

    function drop(ev) {
        const droppedItem = ev.dataTransfer.files;
        setGetData(droppedItem);
        setIsDrop(false);
    }

    return (
        <div
            onDragOver={dragOver}
            onDragLeave={() => setIsDrop(false)}
            onDrop={drop}
            className="flex border-2 border-dashed rounded-xl items-center justify-center w-auto my-4"
        >
            <label
                htmlFor="dropzone-file"
                className={(isDrop ? "border-blue-300 bg-gray-200" : "border-gray-300 bg-gray-50") + " flex flex-col relative items-center justify-center w-full h-64 border-1  border-dashed rounded-md cursor-pointer hover:border-blue-300 hover:bg-gray-100"}
            >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">

                    <CloudUploadIcon />
                    <Typography sx={{ mb: 1, fontSize: "sm" }}>Nhấn vào  hoặc kéo thả để tải tệp</Typography>
                    <Typography sx={{ fontSize: "sm" }}>Được phép tối đa là 300MB</Typography>
                    {
                        progress ? (
                            <div className="py-1 h-5 relative mx-10 z-2 w-[300px] mt-4">
                                <div className="absolute top-0 bottom-0 left-0 w-full rounded-md-[100px] h-2 bg-gray-400"></div>
                                <div style={{ width: `${progress}%` }}
                                    className="absolute top-0 bottom-0 left-0 h-2 rounded-md-[100px] transition-all duration-150 bg-blue-300"
                                ></div>
                                <div className="relative top-full flex items-center justify-center w-full h-full">
                                    <span className="text-sm font-bold text-blue">{progress}%</span>
                                </div>
                            </div>
                        ) : ""
                    }
                </div>
                <Input
                    id="dropzone-file"
                    type="file"
                    className="absolute z-2 w-full h-full opacity-0"
                    sx={{
                        position: "absolute",
                        zIndex: 2,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer"
                    }}
                    multiple
                    //value={getData}
                    onChange={(value) => setGetData(value.target.files)}
                />
            </label>
        </div>
    );
}

export default InputDragData;