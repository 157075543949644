import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const Index = () => {
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignItems: "center",
                }}
            >
                <MarkEmailReadIcon sx={{ width: 32, height: 32 }} />
                <Typography sx={{ fontSize: 24 }}>
                    An email to reset your password has been sent, please check your inbox or draft to process to change your password
                </Typography>
                <Button as={Link} to="/">
                    Return home
                </Button>
            </Box>
        </Container>
    );
};

export default Index;