import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

const ConfirmDelete = ({ open, setOpen, isLoading, onSubmit }) => {

    const { t } = useTranslation();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 3,
    };

    const handleClose = () => setOpen(false);

    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    TransitionComponent: Fade,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography
                        id="spring-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ fontWeight: 600 }}>
                        {t("Delete Item")}
                    </Typography>
                    <Typography id="spring-modal-description" sx={{ mt: 2 }}>
                        {t("Are you sure you want to delete this item?")}
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
                        <LoadingButton
                            loading={isLoading}
                            onClick={onSubmit}
                            variant='contained'
                            color='error'>
                            {t("Confirm")}
                        </LoadingButton>
                        <Button
                            onClick={handleClose}
                            variant='contained'
                            color='primary'>
                            {t("Close")}
                        </Button>
                    </Stack>
                </Box>
            </Fade>
        </Modal>
    );
};

export default ConfirmDelete;
