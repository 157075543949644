import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SignIn() {
    const { t } = useTranslation();
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignItems: "center",
                }}
            >
                <MarkEmailReadIcon sx={{ width: 32, height: 32 }} />
                <Typography sx={{ fontSize: 24 }}>
                    {t("An email has been sent to your inbox. Please check your inbox to reset/change your password.")}
                </Typography>
                <Button as={Link} to="/" variant='contained' sx={{background: "#000", borderRadius: 0}}>
                    {t("Return home")}
                </Button>
            </Box>
        </Container>
    );
}