import { formatBytes } from "@/utils/Hooks";
import { uploadMedia } from "@/utils/Media";
import { useToasterContext } from "@/utils/ToasterContext";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import InputDragData from "@/components/InputDragData";
import NavHeader from "@/components/NavHeader";

const UploadMedia = () => {
    const [dataFile, setDataFile] = useState(null);
    const [dataPreview, setDataPreview] = useState();
    const [processUpload, setProgress] = useState(0);
    const { showMessage } = useToasterContext();
    
    function onProgress(progressEvent) {
        const percentage = (progressEvent.loaded * 100) / progressEvent.total;
        setProgress(+percentage.toFixed(2));
    }

    useEffect(() => {
        if (dataFile) {
            const formData = new FormData();
            Array.from(dataFile).forEach((file) => {
                formData.append("file", file);
            });
            handleUploadFile(formData);
        }
        setDataPreview(dataFile);
        console.log("dataFile", dataFile);
    }, [dataFile]);

    async function handleUploadFile(data) {
        try {
            setProgress(0);
            const response = await uploadMedia(data, onProgress);
            if (response && !response.error) {
                const { error } = response;
                if (!error) {
                    showMessage("success", "File uploaded successfully!");
                    location.reload();
                } else {
                    showMessage("error", "An error occurred! Please try again!");
                }
                setDataPreview(data);
            } else {
                showMessage("error", "An error occurred! Please try again!");
            }
        } catch (error) {
            console.error("error", error);
            showMessage("error", "An error occurred! Please try again!");
        } finally {
            setDataFile(false);
            setProgress(false);
        }
    }

    return (
        <div>
            <NavHeader
                title="Upload media"
                breadcrumb={[
                    {
                        title: "Upload Media"
                    }
                ]}
                backLink="/trainer/media/"
            />
            <Box>
                <InputDragData setDataInput={setDataFile} progress={processUpload} />
                {Array.isArray(dataPreview) && dataPreview.length && processUpload == 100 ? (
                    <div className="grid lg:grid-cols-6 gap-5 mx-10 py-8">
                        {dataPreview.map((val, index) => {
                            return (
                                <div key={index} className="border-dash-2 border-slate-300 p-3 rounded-md bg-white text-center">
                                    <div className="mx-auto h-[170px]">
                                        {val?.mine_type.startsWith("image")
                                            ? (
                                                <img
                                                    src={val?.url}
                                                    width={400}
                                                    height={200}
                                                    alt={val?.title}
                                                    className="w-auto max-w-full h-auto max-h-[170px] text-center m-auto "
                                                />
                                            )
                                            : val?.mine_type.startsWith("video")
                                                ? (
                                                    <img
                                                        src="/video-placeholder.png"
                                                        width={400}
                                                        height={200}
                                                        alt={val?.title}
                                                        className="w-auto max-w-full h-auto max-h-[170px] text-center m-auto "
                                                    />
                                                )
                                                : ""
                                        }
                                    </div>
                                    <h5 className="text-center my-2 text-base font-normal">{val?.title}</h5>
                                    <div className="flex items-center justify-center gap-3 text-sm">
                                        <p className="px-4 py-1 bg-blue-400 text-white rounded-md">{val?.type}</p>
                                        <p className="px-4 py-1 bg-blue-400 text-white rounded-md">{formatBytes(val?.size)}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )
                    : ""}
            </Box>
        </div>
    );
};

export default UploadMedia;
