import { useToasterContext } from "@/utils/ToasterContext";
import { TrainerCheckInUser, findOneSessionCheckin } from "@/utils/TrainerSessions";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import NavHeader from "@/components/NavHeaderHome";
import PageLoading from "@/components/PageLoading";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const Index = () => {

    
    const { showMessage } = useToasterContext();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(false);
    const [session, setSession] = useState(false);
    const [user, setUser] = useState(false);
    
    const checkInKey = searchParams.get("token");
    const navigate = useNavigate();

    const handleFindSessionData = async (id) => {
        try {
            setIsLoading(true);
            const response = await findOneSessionCheckin(id);
            if (response && !response.error) {
                setData(response);
                setSession(response.session);
                setUser((response.user));
            } else {
                showMessage("error", t("Your session is not avaiable!"));
                navigate("/");
            }
        } catch (error) {
            showMessage("error", t("Your session is not avaiable!"));
            navigate("/");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckInUser = async (id) => {
        try {
            setIsLoading(true);
            const response = await TrainerCheckInUser(id);
            if (response && !response.error) {
                showMessage("success", t("Successfully checkin the session!"));
                setData(response);
            } else {
                showMessage("error", response?.data?.message || t("An error occurred, please try again later"));
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckOutUser = async (id) => {
        try {
            setIsLoading(true);
            const response = await TrainerCheckInUser(id);
            if (response && !response.error) {
                showMessage("success", t("Successfully checkout the session!"));
                setData(response);
            } else {
                showMessage("error", response?.data?.message || t("An error occurred, please try again later"));
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        handleFindSessionData(checkInKey);
    }, []);


    if (!data) {
        return (  
            <PageLoading />
        );
    }

    const classInfo = session?.class;
    const start = dayjs(session?.start_time);
    const end = dayjs(session?.end_time);
    // Tính khoảng thời gian giữa hai thời điểm trong phút
    const durationInMinutes = end.diff(start, "minute");

    const currentDayString = dayjs().format("MM/DD");
    const currentDay = dayjs(currentDayString, "MM/DD");

    const sessionDateCompare = currentDay.isAfter(start);

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = "#";

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
        };
    }

    return (
        <Box>
            <NavHeader
                title="Session checkin"
                breadcrumb={[
                    {
                        title: "Session checkin"
                    }
                ]}
                backLink={`/trainer/sessions/${session?.id}`}
            />
            {
                user ? (
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        columnGap: 15
                    }}>
                        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                            <Avatar {...stringAvatar(user?.display_name)} />
                            <Box>
                                <Typography>
                                    <strong>{user?.display_name}</strong>
                                </Typography>
                                <Typography sx={{ fontSize: "sm" }}>
                                    <span>{user?.email}</span>
                                </Typography>
                            </Box>
                        </Stack>
                        <Box>
                            {
                                data?.check_in_time ? (
                                    <>
                                        <Typography sx={{ fontWeight: 600 }}>
                                            Checked in
                                        </Typography>
                                        <Typography>
                                            {dayjs(data?.check_in_time).format("HH:mm:ss, DD/MM/YYYY")}
                                        </Typography>
                                        {
                                            data?.check_out_time ?
                                                <>
                                                    <Typography sx={{ fontWeight: 600 }}>
                                                        Checked out
                                                    </Typography>
                                                    <Typography>
                                                        {dayjs(data?.check_out_time).format("HH:mm:ss, DD/MM/YYYY")}
                                                    </Typography>
                                                </> : (
                                                    <LoadingButton
                                                        variant='contained'
                                                        loading={isLoading}
                                                        onClick={() => handleCheckOutUser(data.id)}
                                                        color='secondary'
                                                        sx={{
                                                            padding: "10px 8px",
                                                            borderRadius: 0,
                                                            display: "block",
                                                            mt: 2,
                                                            mb: 2,
                                                            textAlign: "center",
                                                            letterSpacing: 2
                                                        }}>
                                                        Start to checkout
                                                    </LoadingButton>
                                                )
                                        }

                                    </>
                                ) : <LoadingButton
                                    variant='contained'
                                    loading={isLoading}
                                    onClick={() => handleCheckInUser(data.id)}
                                    color='secondary'
                                    sx={{
                                        padding: "10px 8px",
                                        borderRadius: 0,
                                        display: "block",
                                        mt: 2,
                                        mb: 2,
                                        textAlign: "center",
                                        letterSpacing: 2
                                    }}>
                                    {t("Start to checkin")}
                                </LoadingButton>
                            }
                        </Box>
                    </Box>
                ) : ""
            }
            <Box>
                <Divider sx={{ mb: 2 }}>
                    <Chip
                        sx={{ textTransform: "uppercase", borderRadius: 0 }}
                        label={t("Session information")}
                    />
                </Divider>
                <Typography
                    className='font-bold uppercase'
                    sx={{ fontWeight: 600, mb: 1 }}>
                    {session?.title}
                </Typography>
                {
                    sessionDateCompare ? (
                        <Typography sx={{ mb: 2 }}>
                            (<span className="text-red-600">*</span>)
                            {t("This session has expired!")}</Typography>
                    ) : ""
                }
                <Box sx={{ mb: 3 }}>
                    {session?.description}
                </Box>
                <Box sx={{ mb: 3 }}>
                    {
                        classInfo ?
                            <Typography sx={{ mb: 2 }}>
                                {t("Class")}
                                <span className='font-bold uppercase ml-1'> {classInfo?.title}</span>
                            </Typography>
                            : ""
                    }
                    {
                        start ?
                            <Typography sx={{ mb: 2 }}>
                                {t("Schedule")}
                                <span className='font-bold uppercase ml-1'> {start.format("HH:mm")} - {end.format("HH:mm")} ({start.format("DD/MM/YYYY")})</span>
                            </Typography>
                            : ""
                    }
                    {
                        durationInMinutes ?
                            <Typography sx={{ mb: 2 }}>
                                {t("Duration")}
                                <span className='font-bold uppercase ml-1'> {durationInMinutes} minutes</span>
                            </Typography>
                            : ""
                    }
                </Box>
            </Box>
        </Box >
    );
};

export default Index;
