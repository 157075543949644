import { FormControl, FormLabel, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { useDebouncedValue } from "../utils/useDebouncedValue";

import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const FilterTranslation = ({ filter, setFilter }) => {

    const [search, setSearch] = useState(filter?.search);
    const [searchChanged, setSearchChanged] = useState(false);
    const searchDebounce = useDebouncedValue(search, 800);
    const { t } = useTranslation();

    useEffect(() => {
        if (searchChanged) {
            setFilter({
                ...filter,
                paged: 1,
                search: searchDebounce
            });
        }
    }, [searchDebounce]);

    const handleChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        });
    };

    const handleChangeDate = (name, value) => {
        setFilter({
            ...filter,
            [name]: value ? dayjs(value).format("DD/MM/YYYY") : null
        });
    };

    return (
            <form>
                <div className='grid grid-cols-2 lg:grid-cols-5 gap-3 mb-5'>
                    <FormControl sx={{ flex: 1 }}>
                        <FormLabel sx={{mb: 1, fontSize: 14}}>
                            {t("Search keyword")}
                        </FormLabel>
                        <TextField
                            value={search}
                            onChange={(e) => {
                                setSearchChanged(true);
                                setSearch(e.target.value);
                            }}
                            size='small'
                            sx={{
                                width: "100%",
                                background: "#fff",
                                paddingLeft: "0px !important"
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment sx={{ mr: 3 }} position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                        <FormLabel sx={{mb: 1, fontSize: 14}}>
                            {t("From")}
                        </FormLabel>
                        <DatePicker
                            slotProps={{ textField: { size: "small" } }}
                            value={filter?.start_date ? dayjs(filter.start_date, "DD/MM/YYYY") : null}
                            onChange={(value) => handleChangeDate("start_date", value)}
                            size='small'
                            sx={{ width: "100%",  background: "#fff", }}
                        />
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                        <FormLabel sx={{mb: 1, fontSize: 14}}>
                            {t("To")}
                        </FormLabel>
                        <DatePicker
                            slotProps={{ textField: { size: "small" } }}
                            onChange={(value) => handleChangeDate("end_date", value)}
                            renderInput={(params) => <TextField size="small" {...params} />}
                            sx={{ width: "100%",  background: "#fff" }}
                        />
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                        <FormLabel sx={{mb: 1, fontSize: 14}}>
                            {t("Platforms")}
                        </FormLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label=""
                            placeholder='Platform'
                            value={filter?.platform}
                            size='small'
                            defaultValue="all"
                            onChange={(e) => handleChange("platform", e.target.value)}
                            sx={{ width: "100%",  background: "#fff", }}
                        >
                            <MenuItem value={"all"}>{t("All")}</MenuItem>
                            <MenuItem value={"website"}>{t("Website")}</MenuItem>
                            <MenuItem value={"mobile"}>{t("Mobile")}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                        <FormLabel sx={{mb: 1, fontSize: 14}}>
                            {t("Sort")}
                        </FormLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label=""
                            placeholder='Order'
                            value={filter?.order}
                            size='small'
                            onChange={(e) => handleChange("order", e.target.value)}
                            sx={{ width: "100%",  background: "#fff", }}
                        >
                            <MenuItem value={"desc"}>{t("Desc")}</MenuItem>
                            <MenuItem value={"asc"}>{t("Asc")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </form>
    );
};

export default FilterTranslation;
