import { useToasterContext } from "@/utils/ToasterContext";
import { applySessionFromWaitingList, findOneUserWaitingListCheckin } from "@/utils/UserWaitingList";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Chip, Divider, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { validate } from "validate.js";

import PageLoading from "@/components/PageLoading";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ErrorPage from "../ErrorPage";

dayjs.extend(customParseFormat);

const ApplyWaitingList = () => {

    const { apply_token } = useParams();
    const { t } = useTranslation();
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    // Gộp trạng thái thành một object để dễ quản lý hơn
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(false);
    const [pageInit, setPageInit] = useState(false);
    const [selectedClassType, setSelectedClassType] = useState(false);
    const [formError, setFormError] = useState(false);

    const { user, session, subscription, name_shared } = data;
    
    const waiting_list_id = data?.id;

    const handleClose = () => {
        setOpen(false);
    };

    async function handleBookSession() {

        const validateSession = session?.max_sessions == "-1" ? true : session?.class_sessions_count < session?.capacity;

        if(!validateSession){
            return showMessage("error", t("Your current session is invalid!"));
        }

        let formData = {
            user_id: user?.id,
            waiting_list_id: waiting_list_id,
            session_id: session?.id
        };

        if(subscription){
            formData = {
                ...formData,
                subscription_id: subscription?.id,
            };
        }

        if(name_shared) {
            formData = {
                ...formData,
                shared: [name_shared],
                is_only_shared: 1,
            };
        }

        let createSessionConstraint = {
            waiting_list_id: {
                presence: true,
            },
            session_id: {
                presence: true,
            }
        };

        const error = validate(formData, createSessionConstraint);

        if (!error) {
            try {
                setIsLoading(true);
                const response = await applySessionFromWaitingList(formData);
                if (response && !response.error) {
                    const { session_id } = response;
                    if (session_id) {
                        navigate("/apply-waiting-list/confirmed");
                    }
                } else {
                    showMessage("error", t("An error occurred, please try later"));
                }
            } catch (error) {
                showMessage("error", t("An error occurred, please try later"));
            } finally {
                setIsLoading(false);
            }
        }
        setFormError(error);
    }

    const handleFindOneWaitingList = async () => {
        try {
            startProgress();
            const response = await findOneUserWaitingListCheckin(apply_token);
            if (response && !response.error) {
                const { data } = response;
                if(data){
                    setData(data);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            completeProgress();
            setPageInit("complete");
        }
    };

    useEffect(() => {
        handleFindOneWaitingList(apply_token);
    }, []);

    useEffect(() => {
        if(data){
            const { session } = data;
            const classTypes = session.class_type ? JSON.parse(session.class_type) : null;
            const selectedClass = classTypes?.find(val => val?.title === session.class_types);
            setSelectedClassType(selectedClass);
        }
    }, [data]);

    function isMoreThan12Hours(start_time) {
        // Lấy thời gian hiện tại
        const now = dayjs();
        // Tạo đối tượng dayjs từ start_time
        const targetTime = dayjs(start_time);
        // Tính khoảng thời gian giữa hiện tại và start_time tính theo giờ
        const diffInHours = targetTime.diff(now, "hour");
        return diffInHours > 1;
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 540,
        bgcolor: "background.paper",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    if (pageInit !== "complete") {
        return <PageLoading />;
    }

    if(!data){
        return (
            <ErrorPage />
        );
    }

    return (
        <Box sx={{ width: "100%", py: 3 }}>
            <div className="max-w-[520px] mx-auto">
                <Box>
                    <Divider sx={{ mb: 2 }}>
                        <Chip label={t("Session information")} className='uppercase' sx={{ borderRadius: 0 }} />
                    </Divider>
                    <Box sx={{ mb: 3 }}>
                        {selectedClassType || session?.class ? (
                            <Box>
                                <Typography>
                                    <span className='text-2xl font-bold uppercase'>
                                        {t((selectedClassType || session?.class)?.title)}
                                    </span>
                                </Typography>
                                <Typography className="text-sm">
                                    {t((selectedClassType || session?.class)?.description)}
                                </Typography>
                            </Box>
                        ) : null}
                        <Typography>
                            {t("on")} <span className='font-bold uppercase'> {dayjs(session?.start_time).format("ddd, DD/MM/YYYY")}</span>
                        </Typography>
                        <Typography>
                            {t("at")} <span className='font-bold uppercase'>{dayjs(session?.start_time).format("HH:mm")}</span>
                        </Typography>
                        <Typography>
                            {t("Duration")}: <span className="font-bold">{session?.duration_minutes} minutes</span>
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            {t("Instructor")}: <strong>{session?.trainer?.full_name || "-"}</strong> 
                        </Typography>
                    </Box>
                </Box>
                {
                    isMoreThan12Hours(session?.start_time) && (
                        <Button
                            variant='contained'
                            onClick={() => setOpen(true)}
                            sx={{ mb: 3, background: "#000", borderRadius: 0 }}>
                            {t("Book session")}
                        </Button>
                    )
                }
            </div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{ ...style, maxWidth: 540 }}>
                    <Typography sx={{fontWeight: 600}} id="modal-modal-title" variant="h5" component="h2">
                        {t("Do you want to book this session?")}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("We will send the appointment booking information to the email address")} <strong>{user?.email}</strong>. {t("You will receive an email containing the appointment details and an attached event file. Please open the attachment and add it to your calendar.")}
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" mt={4}>
                        <Button
                            onClick={handleClose}
                            variant='outlined'
                            sx={{ 
                                padding: "10px 20px", 
                                textTransform: "uppercase", 
                                borderRadius: 0 
                            }}>
                            {t("close")}
                        </Button>
                        {
                            session ? (
                                <LoadingButton
                                    loading={isLoading}
                                    onClick={() => handleBookSession()}
                                    variant='contained'
                                    sx={{ 
                                        backgroundColor: "#000", 
                                        color: "#fff", 
                                        padding: "10px 20px", 
                                        textTransform: "uppercase", 
                                        borderRadius: 0 
                                    }}>
                                    {t("Confirm")}
                                </LoadingButton>
                            ) : ""
                        }
                    </Stack>
                </Box>
            </Modal>
        </Box>
    );
};
export default ApplyWaitingList;