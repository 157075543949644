import { deleteCart, findManyCarts } from "@/utils/Cart";
import { numberWithCommas } from "@/utils/Hooks";
import { updateCartData } from "@/utils/slice/cartSlice";
import { useToasterContext } from "@/utils/ToasterContext";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Paper, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import NavHeader from "@/components/NavHeader";
import PageLoading from "@/components/PageLoading";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const Index = () => {

    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state.cart);
    
    const [cartTotal, setCartTotal] = useState(false);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [deleteItem, setDeleteItem] = useState(false);
    const [pageInit, setPageInit] = useState(false);

    const { t } = useTranslation();

    async function handleFindCart() {
        try {
            startProgress();
            const response = await findManyCarts();
            if (response && !response.error) {
                const { data } = response;
                dispatch(updateCartData(data));
            } else {
                showMessage("error", t("An error occurred, please try later"));
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try later"));
        } finally {
            completeProgress();
            setPageInit("complete");
        }
    }
    async function handleDeleteFromCart(id) {
        try {
            setDeleteItem(id);
            const response = await deleteCart(id);
            if (response && !response.error) {
                const { id } = response;
                if (id) {
                    await handleFindCart();
                }
            } else {
                showMessage("error", t("An error occurred, please try later"));
            }
        } catch (error) {
            console.error("error: ", error);
            showMessage("error", t("An error occurred, please try later"));
        } finally {
            setDeleteItem(false);
        }
    }

    useEffect(() => {
        if (Array.isArray(cart) && cart?.length) {
            let totalPrice = cart.reduce((acc, value) => {

                const { memberships, quantity } = value; 
                const { regular_price, loyalties } = memberships;

                let LoyaltyDiscount = 0;
                let Subtotal = regular_price * quantity;
                let loyalty = null;

                if(Array.isArray(loyalties) && loyalties?.length){
                    loyalty = loyalties[0];
                }

                if(loyalty) {
                    const discount_percent = loyalty?.pivot?.discount_percent;
                    LoyaltyDiscount = Subtotal * parseInt(discount_percent) / 100;
                    Subtotal -= LoyaltyDiscount;
                }
                return acc + Subtotal;
            }, 0);
            setCartTotal(totalPrice);
        }
    }, [cart]);

    useEffect(() => {
        handleFindCart();
    }, []);

    if(pageInit != "complete"){
        return <PageLoading />;
    }

    return (
        <div>
            {
                Array.isArray(cart) && cart?.length ? (
                    <Fragment>
                        <Box>
                        <NavHeader
                            title={t("Cart")}
                            breadcrumb={[
                                {
                                    title: t("Cart")
                                }
                            ]}
                        />
                        <div className="">
                            {
                                cart.map((val) => {

                                    const { memberships, quantity } = val; 
                                    const { id, title, regular_price, loyalties } = memberships;
                                    
                                    let loyalty = null;

                                    if(Array.isArray(loyalties) && loyalties?.length){
                                        loyalty = loyalties[0];
                                    }

                                    let LoyaltyDiscount = 0;
                                    let Subtotal = regular_price * quantity;

                                    if(loyalty) {
                                        const discount_percent = loyalty?.pivot?.discount_percent;
                                        LoyaltyDiscount = Subtotal * parseInt(discount_percent) / 100;
                                        Subtotal -= LoyaltyDiscount;
                                    }

                                    return (
                                        <Paper key={id} className="p-6 space-y-2 border mb-4">
                                            <div className="flex justify-between items-center">
                                                <div className="flex-1">
                                                    <h2 className="text-lg font-semibold text-gray-900">{t(title)} <span className="text-gray-600">x {quantity}</span></h2>
                                                </div>
                                                <LoadingButton
                                                    loading={deleteItem == id}
                                                    onClick={() => handleDeleteFromCart(id)}>
                                                    <DeleteOutlineIcon />
                                                </LoadingButton>
                                            </div>

                                            <div className="text-xl font-semibold text-gray-800">{regular_price ? numberWithCommas(regular_price) + "₫" : "-"}</div>
                                            {
                                                loyalty ? (
                                                    <Fragment>
                                                        <div className="text-sm text-red-500">
                                                            -{numberWithCommas(LoyaltyDiscount) + "₫"} <span className="text-gray-600">({loyalty?.title})</span>
                                                        </div>
                                                    </Fragment>
                                                ) : ""
                                            }
                                            <hr className="border-gray-300" />
                                            <div className="flex justify-between items-center">
                                                <span className="text-xl font-semibold">{t("Subtotal")}:</span>
                                                <span className="text-xl font-bold">{numberWithCommas(Subtotal)}₫</span>
                                            </div>
                                        </Paper>
                                    );

                                })
                            }
                        </div>
                    </Box>
                    
                    <Box className="flex justify-end text-center py-3">
                        <div>
                            <div className='text-xl mb-5'>
                                <strong className='mr-2'>{t("Total")}:</strong>
                                {numberWithCommas(cartTotal)}₫
                            </div>
                            <Button
                                as={Link}
                                to="/checkout"
                                color='secondary'
                                sx={{
                                    padding: "10px 8px",
                                    borderRadius: 0,
                                    textAlign: "center",
                                    letterSpacing: 2
                                }}
                                variant='contained'>
                                {t("Proceed to checkout")}
                            </Button>
                        </div>
                    </Box>
                    </Fragment>
                ) : (
                    <Box sx={{ textAlign: "center", py: 3 }}>
                        <AddShoppingCartIcon sx={{ width: 80, height: 80, mb: 1 }} />
                        <Typography sx={{ mb: 3 }}>{t("Cart empty")}</Typography>
                        <Link to="/memberships/">
                            <Button variant='contained' color="secondary">
                                {t("Return to memberships")}
                            </Button>
                        </Link>
                    </Box>
                )
            }

        </div>
    );
};

export default Index;
